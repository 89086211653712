import {Component, OnInit} from '@angular/core';
import {UcEnvironmentService} from '../core/common/index';
import {UcHttpService} from '../core/http/http.service';
import {UcAuthService} from '../core/auth';

@Component({
  selector: 'app-auth',
  template: `<router-outlet></router-outlet>`
})
export class UcAccountComponent implements OnInit {

  constructor(
    private environmentService: UcEnvironmentService, 
    private httpService: UcHttpService,
    private authService: UcAuthService
  ) {
    httpService.addBaseUrlInterceptor(environmentService.api.usercenter);
  }

  ngOnInit() {}
}
