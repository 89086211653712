import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ViewModel} from '../../system.model';
import {UcEnvironmentService} from '../../../core/common/environment.service';

@Injectable()
export class HttpService {
  constructor(
    private http: HttpClient,
    private environment: UcEnvironmentService
  ) {}

  getSourceData(url: string, body) {
    return this.http.post(this.environment.api.datasource + url, body);
  }

  /**
   * 获取单个图表信息
   */
  getChart(id: string): Observable<ViewModel<any>> {
    return this.http.post<ViewModel<any>>(this.environment.api.visualization + '/view/get/' + id, {});
  }

  /**
   * 定时获取数据
   */
  intervalGetData(url: string, count?: number) {
    count = count === 0 ? null : count;

    return this.http.post(url, {pageSize: count});
  }
}
