<div class="uc-login-form-wrap">
  <div class="uc-login-container">
    <div class="uc-login-form">
      <div class="uc-login-header">
        <div class="uc-logo">
        </div>
        <div class="uc-login-text">
          <h3>重置密码</h3>
        </div>
      </div>
      <div class="uc-login-body">
        <form nz-form [formGroup]="mobileLoginForm" class="login-form" (ngSubmit)="submit()">
          <nz-form-item>
            <nz-form-control [nzErrorTip]="mobileErrorTpl">
              <nz-input-group [nzPrefix]="prefixMobile">
                  <input type="text" nz-input formControlName="mobile" placeholder="请输入手机号码">
              </nz-input-group>
              <ng-template #mobileErrorTpl let-control>
                <ng-container *ngIf="mobileLoginForm.get('mobile').dirty && mobileLoginForm.get('mobile').errors">
                  {{mobileLoginForm.getError('mobile', ['mobile'])?.info}}
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control [nzErrorTip]="vercodeErrorTpl">
              <div nz-row>
                <div nz-col nzSpan="12">
                  <nz-input-group>
                    <input type="text" nz-input formControlName="verifyCode" placeholder="输入图片验证码" [maxlength]="6">
                  </nz-input-group>
                </div>
                <div nz-col nzSpan="10" nzOffset="2">
                  <div class="mobile-code-btn">
                    <a id='verifyCode-btn' (click)="resetVerifyCode()">
                      <img id="verifyCodeImg" [src]="verifyCodeUrl">
                    </a>
                  </div>
                </div>
              </div>
              <ng-template #vercodeErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  输入图片验证码!
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control [nzErrorTip]="codeErrorTpl">
              <div nz-row>
                <div nz-col nzSpan="12">
                  <nz-input-group>
                    <input type="text" nz-input formControlName="code" placeholder="输入验证码" [maxlength]="6">
                  </nz-input-group>
                </div>
                <div nz-col nzSpan="10" nzOffset="2">
                  <button nz-button [nzSize]="'large'" nzType="primary" (click)="getMobileCode(); false"
                          [disabled]="mobileLoginForm.getError('mobile', ['mobile']) || !mobileLoginForm.get('verifyCode').valid || inInterval">获取验证短信({{showSecond}}s)</button>
                </div>
              </div>
              <ng-template #codeErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  请输入验证码!
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <button nz-button class="uc-btn-login" [nzSize]="'large'" [nzType]="'primary'" [disabled]="!mobileLoginForm.valid">确定</button>
            </nz-form-control>
          </nz-form-item>
        </form>
        <ng-template #prefixMobile><i nz-icon type="mobile"></i></ng-template>
      </div>
    </div>
  </div>
</div>
