import {ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import {MenusListService} from './menusList.service';

export interface BreadcrumbItem {
  title: string;
  url: string;
  params?: Params;
}

@Component({
  selector: 'app-breadcrumb',
  template: `
    <nz-content>
      <h2 class="uc-content-title">{{ contentTitle }}</h2>
      <nz-breadcrumb class="uc-breadcrumb">
        <nz-breadcrumb-item *ngFor="let item of breadcrumbItems">
          <a [routerLink]="[item.url]" [queryParams]="item.params">{{item.title}}</a>
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </nz-content>
  `
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbItems: BreadcrumbItem[];
  contentTitle = '';
  menusItems;

  constructor(private activatedRoute: ActivatedRoute,
              private menusListService: MenusListService,
              private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const route: ActivatedRoute = this.activatedRoute.root;
      if (!this.menusItems) {
        this.menusListService.getParentMenuItem().subscribe(menusList => {
          this.menusItems = menusList;
          this.breadcrumbItems = this.getItems(route, this.menusItems);
          // this.breadcrumbItems = this.breadcrumbItems.reduce((x, y) => x.findIndex(e => e.url === y.url) < 0 ? [...x, y] : x, []);
          this.contentTitle = this.breadcrumbItems && this.breadcrumbItems.length > 0 ?
            this.breadcrumbItems[this.breadcrumbItems.length - 1].title : '';
        });
      } else {
        this.breadcrumbItems = this.getItems(route, this.menusItems);
        // this.breadcrumbItems = this.breadcrumbItems.reduce((x, y) => x.findIndex(e => e.url === y.url) < 0 ? [...x, y] : x, []);
        this.contentTitle = this.breadcrumbItems && this.breadcrumbItems.length > 0 ?
          this.breadcrumbItems[this.breadcrumbItems.length - 1].title : '';
      }


    });
  }

  ngOnInit() {

  }

  /**
   * 真实路由层级必须比common.json中配置更详尽：真实路由a/bc，配置abc可以；真实路由abc，配置a/bc不行
   */
  private getItems(route: ActivatedRoute, configArray: any[], items: BreadcrumbItem[] = [], url: string = ''): BreadcrumbItem[] {
    const children: ActivatedRoute[] = route.children;
    if (!(children && children.length > 0) || !(configArray && configArray.length > 0)) {
      return items;
    }

    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      let config = null;
      const routeUrl: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeUrl) {
        url += `/${routeUrl}`;
        config = configArray.find(x => url.endsWith(x.path));
      }

      if (!config) {
        return this.getItems(child, configArray, items, url);
      }
      const title = config.breadcrumb ? config.breadcrumb : config.title;
      if (title) {
        const item: BreadcrumbItem = {
          title: title,
          params: child.snapshot.params,
          url: url
        };
        if (items.filter((x) => x.url === item.url).length === 0) {
          items.push(item);
        }
      }
      return this.getItems(child, config.children, items, url);
    }
    return items;
  }
}
