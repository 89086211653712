import {UcStorageType} from '../cache/cacheStorage';
import {environment} from '../../../environments/environment';

export class UcClientUrlConfig {
  login?: string;
  logout?: string;
  token?: string;
  user?: string;
}

export class UcOauthConfig {
  login?: string;
  logout?: string;
  token?: string;
  user?: string;
}

export class UcAuthUrlConfig extends UcClientUrlConfig {
  clientId?: string;
  loginToken?: string;
  code?: string;
  logout?: string;
}

export class UcAuthConfig {
  client?: UcClientUrlConfig; // 客户端配置
  auth?: UcAuthUrlConfig; // 验证服务端配置，直接通过前端访问授权服务器
  storageType?: UcStorageType;
  clientId?: string;

  constructor() {
    this.client = environment.client;
    this.auth = environment.auth;
    this.storageType = UcStorageType.LocalStorage;
    this.clientId = environment.clientId;
  }
}
