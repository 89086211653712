import {Component, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-key-value-input',
  template: `
    <nz-input-group>
      <nz-select [(ngModel)]="selectedKey" (ngModelChange)="onSelectChange($event)">
        <nz-option *ngFor="let option of options" [nzLabel]="option.label" [nzValue]="option.key"></nz-option>
      </nz-select>
      <nz-input-number *ngFor="let item of inputtedValues; let i = index"
                       (ngModelChange)="onInputChange(i, $event)"></nz-input-number>
    </nz-input-group>`,
  styles: [``],
})
export class MultiValueInputComponent implements OnInit {

  @Input() valueCount: number;
  @Input() options: Array<{ label: string; key: string }> = [];
  @Input() selectedKey: string;
  @Input() selectedOption: { label: string; key: string };
  @Input() inputtedValues: number[];

  onSelectChange(option: { label: string; key: string }) {
    this.selectedOption = option;
  }

  onInputChange(i: number, value: number) {
    this.inputtedValues[i] = value;
  }

  ngOnInit(): void {
    for (let i = 0; i < this.valueCount; i++) {
      this.inputtedValues.push(0);
    }
    this.options = [{label: 'A', key: 'AA'}, {label: 'B', key: 'BB'}];
    this.valueCount = 3;
  }
}
