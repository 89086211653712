import {Injectable} from '@angular/core';
import {forkJoin} from 'rxjs/index';
import {map} from 'rxjs/operators';
import {BaseService} from './base.service';
import {ChartTemplate, UcDataConfig} from './ucChart.model';
import {HttpService} from './http.service';
import {UcChartComponent} from './ucChart.component';
import {ProcessService} from './process.service';
import {UcEditService} from './ucEdit.service';
import {FilterType, QueryModelPagination} from '../../system.model';
import {cloneDeep} from '../../../core/common';

@Injectable()
export class DataService {

  constructor(
    private _base: BaseService,
    private httpservice: HttpService,
    private processService: ProcessService,
    private ucEdit: UcEditService
  ) {
  }

  handleDataConfig(component: UcChartComponent, TCD: ChartTemplate) {
    this.sourceDataRequest(component, TCD);
  }

  /**
   * sources中的data请求
   * @param data_config
   */
  sourceDataRequest(component: UcChartComponent, TCD: ChartTemplate, reset: boolean = false) {
    if (TCD.data_config.dataset_type === 'dynamic' && TCD.data_config.sources.length > 0) {
      const requests = [];
      // 通过source.url请求最新数据并返回
      TCD.data_config.sources.forEach((source, index) => {
        // 修改传入配置为body，先保留原先传入的count ，判断取值
        if (reset) {
          // 分页
          source.body.pageSize = component.pageSize;
          source.body.pageIndex = component.pageIndex;
          // 排序
          source.body.sort = {
            key: component.sortName,
            ascending: component.sortValue
          };
          // 过滤搜索
          const defaultFilters = {
            resetFilters: (key: string, type: string, value: any) => {
              if (component.filters.length > 0) {
                const include = component.filters.map((f, num) => {
                  if (f.key === key) {
                    if (value !== null && value !== '') {
                      f.value = value;
                    } else {
                      component.filters.splice(num, 1);
                    }
                  }
                  return f.key === key;
                });

                if (!include.includes(true)) {
                  if (value !== null && value !== '') {
                    component.filters.push({key: key, type: type, value: value});
                  }
                }
              } else {
                if (value !== null && value !== '') {
                  component.filters.push({key: key, type: type, value: value});
                }
              }
            }
          };

          if (component.filterKey) {
            defaultFilters.resetFilters(component.filterKey, FilterType[FilterType.Like], component.filterValue);
          }

          if (source.body.filtersType === 'Or') {
            source.body.filters.forEach((f, num) => {
              f.filters = [
                ...component.defaultSourceBodyFilters[num].filters,
                ...component.filters
              ];
            });
          } else {
            source.body.filters = [
              ...component.defaultSourceBodyFilters,
              ...component.filters,
            ];
          }
        } else {
          source.body.pageSize = source.body && source.body.pageSize || 10;
          source.body.pageIndex = source.body && source.body.pageIndex || 1;
          component.pageSize = source.body.pageSize;
          component.pageIndex = source.body.pageIndex;
          component.sortName = source.body && source.body.sort && source.body.sort.key;
          component.sortValue = source.body && source.body.sort && source.body.sort.ascending;
          component.defaultSourceBodyFilters = cloneDeep(source.body && source.body.filters || []);
        }

        requests.push(this.httpservice.getSourceData(source.url, source.body).pipe(
          map(
            (res) => {
              component.total = res['totalItems'];
              const fieldNames = source.fields.map(item => {
                return item.fieldName;
              });
              source.data = res['data'];
              if (source.fields.length > 0) {
                res['fields'].forEach((item) => {
                  if (fieldNames.indexOf(item.fieldName) < 0) {
                    source.fields.push(item);
                  }
                });
              } else {
                source.fields = res['fields'];
              }
            }
          )
        ));
      });
      forkJoin(requests).subscribe(
        () => {
          this.defaultGenerate(component, TCD);
        }
      );
    } else if (TCD.data_config.dataset_type === 'static') {
      this.defaultGenerate(component, TCD);
    }
  }

  /**
   * 初始dataConfig.generate
   * @param component
   * @param TCD
   */
  defaultGenerate(component: UcChartComponent, TCD: ChartTemplate) {
    // sources操作处理(这步操作会使source.data只剩下source.field.checked === true 的key对应的值)
    TCD.data_config.sources.forEach((source, index) => {
        const data_tmp = this.processService.handleSourceData(source, component);
        if (source.process && source.process.length !== 0) {
          this.processService.handleProcess(source.process, data_tmp);
        }
        // 有操作之后生成的新数据重新付给source
        source.fields = data_tmp.data_field;
        source.data = data_tmp.data_row;

        if (index === 0) {
          component.chartDataTracking.emit(data_tmp.data_newest);
        }
    });

    // generate数据处理获取
    this.defaultGenerateSource(TCD.data_config);
    component.ucDefaultData.emit({
      data: TCD.data_config.generate_source.data
    });

    TCD.dataTmp = this.processService.dataToChart(TCD.data_config.generate_source);

    this.ucEdit.createChart(component, TCD);
  }

  /**
   * 这边给到generateSource的数据，都是sources经过process操作过后的数据
   */
  defaultGenerateSource(dataConfig: UcDataConfig) {
    dataConfig.generate_source = {
      id: 'generate', type: 'generate',
      fields: dataConfig.sources[0].fields,
      data: dataConfig.sources[0].data
    };

    // 判断generate是否需要进行计算
    if (dataConfig.processes && dataConfig.processes.length !== 0) {
      const data_tmp = this.processService.handleSourceData(dataConfig.generate_source);
      this.processService.generateProcesses(data_tmp, dataConfig.processes, dataConfig.sources);
      dataConfig.generate_source.data = data_tmp.data_row;
      dataConfig.generate_source.fields = data_tmp.data_field;
    }
  }

  /**
   * source操作计算，field.checked判断
   * @param source
   * @param data_tmp
   */
  handleSource(source, data_tmp) {
    // process操作
    if (source.process && source.process.length !== 0) {
      this.processService.handleProcess(source.process, data_tmp);
    }
    // 有操作之后生成的新数据重新付给source
    source.fields = data_tmp.data_field;
    source.data = data_tmp.data_row;

    // 对field.checked进行操作判断
    const checkField = [];
    const checkFields = [];

    source.fields.forEach((item) => {
      if (item.checked) {
        checkField.push(item.fieldName);
        checkFields.push(item);
      }
    });
    source.fields = checkFields;

    source.data.forEach((col, num) => {
      for (const c in col) {
        if (checkField.indexOf(c) < 0) {
          delete source.data[num][c];
        }
      }
    });
  }

}
