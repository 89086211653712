import { Component, ViewChild, ElementRef, EventEmitter, OnInit, AfterViewInit, OnDestroy, Input, Output } from '@angular/core';
import { UcStateService } from '../../../core/state/state.service';
import { UcCacheService } from '../../../core/cache/cache.service';
import * as Handsontable from 'handsontable';
import {Subscription} from 'rxjs';

@Component({
    selector: 'uc-hot',
    templateUrl: './hotTable.html',
    styleUrls: ['./hotTable.scss']
})
export class UcHotTableComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('example', {static: true}) example: ElementRef;

    public hot: any;
    private _stateSubscription: Subscription;
    protected hotSetting = {
      data: [[]],
      colHeaders: [],
      rowHeaders: [],
      minRows: 50,
      minCols: 6,
      fixedRowsTop: 1,
      colWidths: 100,
      rowHeights: 25,
      minSpareRows: 1,
      minSpareCols: 1,
      copyPaste: false,
      outsideClickDeselects: true,
      autoColumnSize: true,
      className: 'htCenter htMiddle',
      formulas: true,
      stretchH: 'all',
      cells: (row, col, prop) => {
        const cellProperties: any = {};
        cellProperties.className = 'htMiddle htCenter';

        return cellProperties;
      },
      afterChange: (change, source) => {
        if (this.hot) {
            const e = dataChanged(this.hot.getData());
            const data = this.hot.getData(e[0], e[1]);
            // 变化后的data，存入缓存中
            // this._cache.set({pool: 'uc-hot', key: this.keyName}, data);
            // 变化后的data，发送给父组件
            this.changeData.emit(data);
        }
      }
    };

    @Input() defaultData: any[];
    @Input() settings: any; // 组件自定义配置
    @Input() keyName: string; // table所属的name
    @Output() changeData = new EventEmitter<any>(); // 每次变化后的data，传送给父组件

    constructor(
       private stateservice: UcStateService,
       private _cache: UcCacheService
    ) {
    }

    ngOnInit() {
      // 监听keyName存储的变化，将新值加载到table中
      this._stateSubscription = this.stateservice.subscribe(this.keyName, (data) => {
        this.hot.loadData(data);
      });
      if (this.settings) {
         this.hotSetting = Object.assign({}, this.hotSetting, this.settings);
      }
    }

    ngAfterViewInit() {
      // 异步加载，解决初始化加载问题
      setTimeout(() => {
        // 初始化创建table
        this.hot = new Handsontable(this.example.nativeElement, this.hotSetting);
        if (this.defaultData) {
          this.hot.loadData(this.defaultData);
        }
      });
    }

    ngOnDestroy() {
      if (this._stateSubscription) {
        this._stateSubscription.unsubscribe();
        this.stateservice.remove(this.keyName);
      }
    }
}
// 返回getData()有效值的范围
function dataChanged(a) {
  for (var b, c, d = 1; d < a[0].length; d++) if (b = d, '' === a[0][d] || null === a[0][d]) {
    b = d - 1;
    break
  }
  for (var d = 1; d < a.length; d++) if (c = d, '' === a[d][0] || null === a[d][0]) {
    for (var e = !0,
           f = 1; b >= f; f++) if ('' !== a[d][f] && null !== a[d][f]) {
      e = !1;
      break
    }
    if (e) {
      c = d - 1;
      break
    }
  }
  return [c, b]
};
