import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UcThemeModule} from '../../core/theme/theme.module';
import {UcCodeComponent} from './codemirror';
import {UcHotTableComponent} from './hottable';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {ShareComponent} from './share/shareOptions.component';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {UcCommonService} from './common.service';
import {NoImplementComponent} from './noImplement.component';
import {UcMusicComponent} from './music/music.component';
import {UcCommonWsService} from './commonWsService';
import {UcChartModule} from './ucChart/ucChart.module';
import {MultiValueInputComponent} from './multiValueInput';
import {PasswordStrengthComponent} from './passwordStrength/passwordStrength.component';
import {CryptoJSService} from './cryptoJS';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, UcThemeModule, CodemirrorModule, NgZorroAntdModule, UcChartModule],
  // tslint:disable-next-line:max-line-length
  declarations: [UcCodeComponent, UcHotTableComponent, ShareComponent, NoImplementComponent, UcMusicComponent, MultiValueInputComponent, PasswordStrengthComponent],
  // tslint:disable-next-line:max-line-length
  exports: [UcCodeComponent, UcHotTableComponent, ShareComponent, NoImplementComponent, UcMusicComponent, MultiValueInputComponent, PasswordStrengthComponent],
  providers: [UcCommonService, UcCommonWsService, CryptoJSService]
})
export class UcCommonModule {
}
