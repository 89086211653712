import { Injectable } from '@angular/core';
import { observable, Observable } from 'rxjs';
import { UcChartComponent } from './ucChart.component';
import { UcAuthService } from '../../../core/auth';
import { UcDataConfigUpdate } from './ucChart.model';
import { UcEnvironmentService } from '../../../core/common';

export class WebSocketBuilderForService {
  websocket = {
    ws: null,
    wsObservable: null
  };

  constructor(
    private url: string
  ) {
    this.createObservableSocket();
  }
  createObservableSocket(): void {
    this.websocket.ws = new WebSocket(this.url);

    this.websocket.wsObservable = new Observable(
      observer => {
        this.websocket.ws.onopen = () => { };
        this.websocket.ws.onmessage = (event) => observer.next(event.data);
        this.websocket.ws.onerror = (event) => observer.error(event);
        this.websocket.ws.onclose = (event) => observer.complete();
      }
    );
  }

  // 向服务器端发送消息
  sendMessage(message: string) {
    this.websocket.ws.send(message);
  }

  closeSocket() {
    this.websocket.ws.close();
  }

  getWebSocket() {
    return this.websocket;
  }

}

@Injectable()
export class UcWebSocketService {
  websokects = [];
  ws: WebSocket;
  timeout = 540000;
  timeoutObj = null;
  serverTimeoutObj = null;
  tt;
  lockReconnect = false; // 避免重复重启

  constructor(
    private authService: UcAuthService,
    private environmentService: UcEnvironmentService
  ) { }

  // 返回一个可观测的流，包括服务器返回的消息
  createObservableSocket(updateSource: UcDataConfigUpdate, component: UcChartComponent): Observable<any> {
    try {
      const queryModle = updateSource.body ? '&body=' + encodeURI(JSON.stringify(updateSource.body)) : '';
      const url = updateSource.url + '?access_token=' + this.authService.token.access_token + queryModle;
      this.ws = new WebSocket(this.environmentService.ws.datasource + url);
      component.ucWebsocket = this.ws;
      this.websokects.push(this.ws);
      this.ws.onopen = () => {
        // 心跳检测重置
        this.reset();
      };

      this.ws.onclose = (event) => {
        console.log(new Date() + '：' + (component.ChartInfo ? component.ChartInfo.name : '') + '：webSocket流结束');
        // 心跳关闭，传来ping值，重启
        if (event.reason === 'ping') {
          this.webSocketReconnect(updateSource, component);
        }
      };

      return new Observable(
        observer => {
          this.ws.onmessage = (event) => observer.next(event.data);
          this.ws.onerror = (event) => observer.error(event);
        }
      );
    } catch (e) {
      this.webSocketReconnect(updateSource, component);
    }

  }

  /**
   * 重启
   * @param updateSource
   * @param component
   */
  webSocketReconnect(updateSource: UcDataConfigUpdate, component: UcChartComponent) {
    console.log(component.ChartInfo.name + '：socket 连接断开，正在尝试重新建立连接');
    if (this.lockReconnect) { return; }
    this.lockReconnect = true;
    // 没连接上会一直重连，设置延迟，避免请求过多
    if (this.tt) {
      clearTimeout(this.tt);
    }
    this.tt = setTimeout(() => {
      this.createObservableSocket(updateSource, component);
      this.lockReconnect = false;
    }, 3000);
  }

  /**
   * 心态机制
   * @param updateSource
   * @param component
   */
  reset() {
    clearTimeout(this.timeoutObj);
    clearTimeout(this.serverTimeoutObj);
    this.start();
  }

  start() {
    this.timeoutObj = setTimeout(() => {
      // 这里发送一个心跳，后端收到后，返回一个心跳消息，
      // onmessage拿到返回的心跳就说明连接正常
      if (this.ws.readyState === 1) {
        this.ws.send('ping');
      }
      this.serverTimeoutObj = setTimeout(() => {// 如果超过一定时间还没重置，说明后端主动断开了
        this.ws.close(1000, 'ping');     // 如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
      }, this.timeout);
    }, this.timeout);
  }

  /**
   *  服务方法创建ws
   */
  createWebSocket(url: string) {
    const webSocket = new WebSocketBuilderForService(url).getWebSocket();

    this.websokects.push(webSocket.ws);

    return webSocket.wsObservable;
  }

  closeAll() {
    this.websokects.forEach((ws: WebSocket, num) => {
      if (ws.readyState !== 1) {
        ws.close();
      }

      if (num === this.websokects.length - 1) {
        this.websokects = [];
      }
    });
  }
}
