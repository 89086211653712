import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {ChartOptions, ChartSetting, ChartTemplate} from './ucChart.model';
import {NzMessageService} from 'ng-zorro-antd';
import {ChartService} from './chart.service';
import {UcChartComponent} from './ucChart.component';
import {cloneDeep} from '../../../core/common';
import {DynamicService} from './dynamic.service';
import {TEMPLATE} from './ucChart.model';
import {ProcessService} from './process.service';

@Injectable()
export class UcEditService {
  constructor(
    private chartservice: ChartService,
    private dynamicservice: DynamicService,
    private _base: BaseService,
    private message: NzMessageService,
    private processService: ProcessService
  ) {}

  createChart(component: UcChartComponent, TCD: ChartTemplate) {
    this.initChart(component, TCD);
  }

  initChart(component: UcChartComponent, TCD: ChartTemplate) {
    if (TCD.chart_for.type !== 'table') {
      // restChart === true，重置图曲线配置series
      if (TCD.chart_config.chartTmp.restChart) {
        this.restSeries(component, TCD);
      } else {
        const chart = this.chartservice.createChart(component, TCD);
        if (chart) {
          component.chart = chart;
          component.defaultChart.emit(chart);
          component.ucChartLoaded.emit(true);
          this.dynamicservice.dynamicChart(TCD, component);
        }
      }
    } else {
      // table排序功能字段
      if (!TCD.chart_config.tableOptions.sortFields) {
        TCD.chart_config.tableOptions.sortFields = [];
      }
      // table过滤功能字段
      if (!TCD.chart_config.tableOptions.filterFields) {
        TCD.chart_config.tableOptions.filterFields = [];
      }
      // table存储类型

      if (TCD && TCD.dataTmp.data_table && TCD.dataTmp.data_table.length > 0) {
        // 根据不同的时间字段进行数据处理
        if (TCD.chart_config.tableOptions.dateField && TCD.chart_config.tableOptions.dateField !== null &&
          TCD.chart_config.tableOptions.dateField !== '' && TCD.dataTmp.data_table[0][TCD.chart_config.tableOptions.dateField]) {
          this.defaultUpdateTableData(component, TCD, TCD.chart_config.tableOptions.dateField);
        } else {
          if (TCD.dataTmp.data_table[0]['timestamp']) {
            this.defaultUpdateTableData(component, TCD, 'timestamp');
          } else if (TCD.dataTmp.data_table[0]['date']) {
            this.defaultUpdateTableData(component, TCD, 'date');
          } else if (TCD.dataTmp.data_table[0]['时间']) {
            this.defaultUpdateTableData(component, TCD, '时间');
          } else if (TCD.dataTmp.data_table[0]['datetime']) {
            this.defaultUpdateTableData(component, TCD, 'datetime');
          } else if (TCD.dataTmp.data_table[0]['create_time']) {
            this.defaultUpdateTableData(component, TCD, 'create_time');
          } else if (TCD.dataTmp.data_table[0]['trader_time']) {
            this.defaultUpdateTableData(component, TCD, 'trade_time');
          } else if (TCD.dataTmp.data_table[0]['trader_date']) {
            this.defaultUpdateTableData(component, TCD, 'trade_date');
          } else {
            let newData = [];

            if (component.TCD.data_config.sources[0].body && component.TCD.data_config.sources[0].body.sort) {
              // tslint:disable-next-line:max-line-length
              newData = this.unAscending(TCD.dataTmp.data_table, component.TCD.data_config.sources[0].body.sort.key, component.TCD.data_config.sources[0].body.sort.ascending);
            } else {
              newData = TCD.dataTmp.data_table;
            }

            this.defaultData(TCD, newData, component);

            component.tableData = [...newData];
            component.tableDefaultData = [...newData];
          }
        }
      } else {
        component.tableData = [...TCD.dataTmp.data_table];
        component.tableDefaultData = [...TCD.dataTmp.data_table];
      }

      component.isLoading = false;
      component.ucChartLoaded.emit(true);
      this.dynamicservice.dynamicChart(TCD, component);
    }
  }

  /**
   * table更新类型为update时，修改显示的为最新数据
   * @param TCD
   * @param dateString
   */
  defaultData(TCD, newTableData, component: UcChartComponent) {
    //  data_table 转化Date类型的时间
    TCD.chart_config.tableOptions.field.forEach((item) => {
      // tslint:disable-next-line:max-line-length
      if (item.fieldType === 'Date' || item.fieldType === 'date' || item.fieldType === 'datetime' || item.fieldName === 'alertTime' || item.fieldName === 'orderTime' || item.fieldName === 'lastUpdateTime' || item.fieldName === 'dealTime') {
        newTableData.forEach((tableData, num) => {
          newTableData[num][item.fieldName] =
            this._base.Format(new Date(this._base.xyVal(tableData[item.fieldName])), component.datetypeString || 'yyyy-MM-dd hh:mm:ss');
        });
      }
    });

    if (TCD.chart_config.tableOptions.dateField && TCD.chart_config.tableOptions.dateField !== null &&
      TCD.chart_config.tableOptions.dateField !== '') {
      newTableData.forEach((tableData, num) => {
        newTableData[num][TCD.chart_config.tableOptions.dateField] =
          this._base.Format(new Date(this._base.xyVal(tableData[TCD.chart_config.tableOptions.dateField])), component.datetypeString || 'yyyy-MM-dd hh:mm:ss');
      });
    }

    // 限制data_table中Number类型的小数有效位
    TCD.chart_config.tableOptions.field.forEach((item, index) => {
      if (item.fieldType === 'Number') {
        newTableData.forEach((tableData, num) => {
          newTableData[num][item.fieldName] = this._base.isNum_(tableData[item.fieldName], 4);

          // tslint:disable-next-line:max-line-length
          if ((!TCD.chart_config.tableOptions.dateField || TCD.chart_config.tableOptions.dateField !== item.fieldName) && !item.fieldName.startsWith('date') && !item.fieldName.endsWith('date') && !item.fieldName.endsWith('Date') &&
            !item.fieldName.startsWith('time') && !item.fieldName.endsWith('time') && !item.fieldName.endsWith('Time') &&
            !item.fieldName.startsWith('code') && !item.fieldName.endsWith('code') && !item.fieldName.endsWith('Code')) {
            newTableData[num][item.fieldName] = this.processService.formatNumber(tableData[item.fieldName]);
          }
        });
      }
    });
  }

  unAscending(arrData, key, ascend: boolean) {
    const dateFiled = ['timestamp', 'date', 'dateTime', 'time', 'trade_time', 'trade_date', 'create_time', '时间', 'datetime'];
    let newData = [];
    if (dateFiled.includes(key)) {
      newData = arrData.sort((a, b) => {
        return ascend ? +new Date(a[key]) - +new Date(b[key]) : +new Date(b[key]) - +new Date(a[key]);
      });
    } else {
      newData = arrData.sort((a, b) => {
        return ascend ? a[key] - b[key] : b[key] - a[key];
      });
    }

    return newData;
  }

  defaultUpdateTableData(component, TCD: ChartTemplate, dateString: string) {
    let newTableData = [];
    let newData = [];

    if (component.TCD.data_config.sources[0].body && component.TCD.data_config.sources[0].body.sort) {
      newData = this.unAscending(TCD.dataTmp.data_table, component.TCD.data_config.sources[0].body.sort.key, component.TCD.data_config.sources[0].body.sort.ascending);
    } else {
      // 对数据进行时间降序排序
      newData = TCD.dataTmp.data_table.sort((a, b) => {
        return +new Date(b[dateString]) - +new Date(a[dateString]);
      });
    }

    // newData.forEach((item, index) => {
    //   if (
    //     (
    //       item[component.tableField] &&
    //       (item[component.tableField] === 0 || item[component.tableField] === 'null' ||
    //         item[component.tableField] === 'None' || item[component.tableField] === null ||
    //         item[component.tableField] === '空' || item[component.tableField] === '无' ||
    //         item[component.tableField] === undefined)) ||
    //     (
    //       item[component.TCD.chart_config.tableOptions.tableNullField] &&
    //       (item[component.TCD.chart_config.tableOptions.tableNullField] === 0 ||
    //         item[component.TCD.chart_config.tableOptions.tableNullField] === 'null' ||
    //         item[component.TCD.chart_config.tableOptions.tableNullField] === 'None' ||
    //         item[component.TCD.chart_config.tableOptions.tableNullField] === null ||
    //         item[component.TCD.chart_config.tableOptions.tableNullField] === '空' ||
    //         item[component.TCD.chart_config.tableOptions.tableNullField] === '无' ||
    //         item[component.TCD.chart_config.tableOptions.tableNullField] === undefined)
    //     )
    //   ) {
    //     newData.splice(index, 1);
    //   }
    // });
    this.tableNullFieldUpdate(newData, component);

    if (TCD.chart_config.chartTmp.type === 'dynamic-update') {
      // updateTableField是否存在，若存在根据updateTableField字段的值来显示最新数据
      if (TCD.chart_config.tableOptions.updateTableField &&
        TCD.chart_config.tableOptions.updateTableField !== null &&
        TCD.chart_config.tableOptions.updateTableField !== '') {
        component.updateTableField = TCD.chart_config.tableOptions.updateTableField;
      }

      if (component.updateTableField && component.updateTableField !== null && component.updateTableField !== '') {
        const updateField = [];
        newData.forEach(item => {
          if (item[component.updateTableField] && !updateField.includes(item[component.updateTableField])) {
            updateField.push(item[component.updateTableField]);
            newTableData.push(item);
          }
        });
      } else {
        // updateTableField不存在，显示最新10s内的数据
        newTableData = newData.filter(item => {
          const isNewDate = Math.abs(this._base.xyVal(newData[0][dateString]) - this._base.xyVal(item[dateString])) <= 5000;
          return isNewDate;
        });

        if (TCD.chart_config.tableOptions.isNewDateNull || component.isNewDateNull) {
          if (+new Date() - this._base.xyVal(newTableData[0][dateString]) > 10000) {
            newTableData = [];
          }
        }
      }
    } else {
      newTableData = newData;
    }

    this.defaultData(TCD, newTableData, component);

    component.tableData = newTableData;
    component.tableDefaultData = newTableData;
  }

  // 空值过滤
  tableNullFieldUpdate(newData, component) {
    const isNullArr = newData.map((item, index) => {
      const isNull = (item[component.tableField] &&
        (item[component.tableField] === 0 || item[component.tableField] === 'null' ||
          item[component.tableField] === 'None' || item[component.tableField] === null ||
          item[component.tableField] === '空' || item[component.tableField] === '无' ||
          item[component.tableField] === undefined)) ||
        (item[component.TCD.chart_config.tableOptions.tableNullField] &&
          (item[component.TCD.chart_config.tableOptions.tableNullField] === 0 ||
            item[component.TCD.chart_config.tableOptions.tableNullField] === 'null' ||
            item[component.TCD.chart_config.tableOptions.tableNullField] === 'None' ||
            item[component.TCD.chart_config.tableOptions.tableNullField] === null ||
            item[component.TCD.chart_config.tableOptions.tableNullField] === '空' ||
            item[component.TCD.chart_config.tableOptions.tableNullField] === '无' ||
            item[component.TCD.chart_config.tableOptions.tableNullField] === undefined)
        );

      if (isNull) {
        newData.splice(index, 1);
      }

      return isNull;
    });

    if (isNullArr.includes(true)) {
      this.tableNullFieldUpdate(newData, component);
    }
  }


  /**
   * 图数据曲线
   * @param component
   * @param TCD
   */
  restSeries(component, TCD: ChartTemplate) {
    // 根据图表类型名称做配置
    const template = this.chartTemplate(TCD.dataTmp.data_dic, TCD);
    // 散点图设置只显示点
    template.series.forEach(function (serie) {
      if (serie.type === 'scatter') {
        // serie.lineWidth = 0;
        serie.marker.enabled = true;
      }
    });

    TCD.template = template;
    TCD.chart_config.chartOptions.series = template.series;

    const chart = this.chartservice.createChart(component, TCD);
    if (chart) {
      component.chart = chart;
      component.defaultChart.emit(chart);
      component.ucChartLoaded.emit(true);
      this.dynamicservice.dynamicChart(TCD, component);
    }
  }

  // 生成图模板
  chartTemplate(dataDic, TCD: ChartTemplate) {
    const chartTmp: ChartSetting = TCD.chart_config.chartTmp;
    let series: any[] = [];
    const xAxis: any = {id: null, name: null, reversed: false, title: {text: ''}, dataType: null};
    let dataField = {
      fieldName: '',
      fieldCode: '',
      fieldType: 'any'
    };
    let tabLen = dataDic.length;
    const specialChart = this._base.specialChartTypes.indexOf(TCD.chart_for.type) > -1;
    const commonParseSerie = {
      /**
       * 3个判断
       * 1、series中已存在feild相对应的serie，将所有存在的serie重新付给series配置
       * 2、series不存在不存在field相对应的serie，通过getSerie()生成
       * 3、通过xAxisId,设置xAxis配置
       */
      common: (data) => {
        const n = Math.floor(100 / tabLen);
        let l = -1;

        data.map((item, index) => {
          // x轴判断 抛去x轴
          if (item.fieldCode !== chartTmp.tmp.xAxisId) {
            l++;
            // 判断在已有曲线中是否已经存在
            if (this.inSeries(TCD.chart_config.chartOptions, item.fieldCode)) {
              // 曲线已存在
              const tmp = TCD.chart_config.chartOptions.series.filter(function (serie) {
                return serie.id === item.fieldCode;
              })[0];

              if (this._base.lineAreaParser1[tmp.type]) {
                tmp.type = this._base.lineAreaParser1[tmp.type];
                tmp.smooth = true;
              }

              if (TCD.chart_for.type === 'pie') {
                // 删除、新增列的时候更新
                tmp.xAxisId = chartTmp.tmp.xAxisId;
                tmp.inSize = Math.floor(n * (l + 0.8)) - 1;
                tmp.outSize = Math.floor(n * (l + 0.8));
              }
              series.push(tmp);
            } else {
              // 曲线不存在
              // 获取所有serie配置
              series.push(this.getSeries(TCD.chart_for, chartTmp, item, l, tabLen, null));
            }
          } else {
            // 设置x轴
            xAxis.id = dataField.fieldCode;
            xAxis.name = dataField.fieldName;
            xAxis.dataType = dataField.fieldType;
          }
        });
      }
    };

    if (specialChart) {
      // 饼图类型
      if (TCD.chart_for.type === 'pie') {
        // 圆环图、三列
        if (TCD.chart_for.id === 'pie-double') {
          if (dataDic.length === 3) {
            xAxis.id = dataDic[2].fieldCode;
            dataDic.slice(0, 2).map((row, index) => {
              if (this.inSeries(TCD.chart_config.chartOptions, row.fieldCode)) {
                const tmp = TCD.chart_config.chartOptions.series.filter(function (serie) {
                  return serie.id === row.fieldCode;
                })[0];
                series.push(tmp);
              } else {
                series.push(this.getSeries(TCD.chart_for, chartTmp, row, index, 2, null));
              }
            });
          } else {
            this.message.create('warning', '双饼图需要三列数据，第一列为大类型，第二列为小类型，第三列为数据，请在【选择模板】参照样例数据。');
          }
        } else {
          if (chartTmp.tmp.xAxisId !== null) {
            dataField = dataDic.filter(item => item.fieldCode === chartTmp.tmp.xAxisId)[0];
          }
          commonParseSerie.common(dataDic);
        }
      } else if (TCD.chart_for.type === 'bubble') {
        const serieData = (dataDic.length % 2 === 1) ? dataDic.slice(1) : dataDic;
        xAxis.id = (dataDic.length % 2 === 1) ? dataDic[0].fieldCode : null;

        serieData.map((row, index) => {
          if (index % 2 === 0) {
            if (this.inSeries(TCD.chart_config.chartOptions, row.fieldCode)) {
              const tmp = TCD.chart_config.chartOptions.series.filter(function(serie) {
                return serie.id === row.fieldCode;
              })[0];
              series.push(tmp);
            } else {
              series.push(this.getSeries(TCD.chart_for, chartTmp, row, index / 2, serieData.length / 2, {
                zPoint: serieData[index + 1]
              }));
            }
          }
        });
      } else if (TCD.chart_for.type === 'candle') {

      }
    } else {
      // 检测是否含有日期列，获取一个类型为时间的列信息
      dataDic.map((item, index) => {
        // tslint:disable-next-line:max-line-length
        if (this._base.isT(item.fieldName) || this._base.isT(item.fieldCode) || item.fieldType === 'datetime' || item.fieldType === 'date' || item.fieldType === 'Date') {
          dataField = item;
          if (chartTmp.tmp.xAxisId === null) {
            chartTmp.tmp.xAxisId = dataField.fieldCode;
          }
        }
        // tab的个数、抛去x轴
        if (item.fieldCode === dataField.fieldCode) {
          tabLen = dataDic.length - 1;
        }
      });

      if (chartTmp.tmp.xAxisId !== null) {
        dataField = dataDic.filter(item => item.fieldCode === chartTmp.tmp.xAxisId)[0];
      }
      commonParseSerie.common(dataDic);
      // common类型：没有x轴，有时间字段，自动设为x轴
      if (chartTmp.tmp.xAxisId === null && dataField && dataField.fieldCode) {
        xAxis.id = dataField.fieldCode;
        xAxis.name = dataField.fieldName;
        xAxis.dataType = dataField.fieldType;
        chartTmp.tmp.xAxisId = dataField.fieldCode;
        series = series.filter(function (item) {
          return item.id !== dataField.fieldCode;
        });
      }
      if (!this._base.isT(xAxis.id) && xAxis.dataType !== 'date' && xAxis.dataType !== 'Date' && xAxis.dataType !== 'datetime') {
        xAxis['type'] = 'category';
      }
    }

    const template: TEMPLATE = {
      series: series,
      xAxis: xAxis,
      compare: TCD.chart_for.type === 'bubble' ? undefined : chartTmp.tmp.compare, // "percent" or "value"
      yAxis: TCD.chart_config.chartOptions.yAxis && TCD.chart_config.chartOptions.yAxis.length > 0 ?
        TCD.chart_config.chartOptions.yAxis : TCD.chart_for.type === 'pie' ? null : [{
          id: '轴1',
          title: {
            text: '',
            style: {
              color: '#000'
            }
          },
          endOnTick: false,
          lineWidth: 2,
          lineColor: '#000',
          labels: {
            style: {
              color: '#000'
            }
          },
          opposite: false,
          reversed: false,
          unit: '',
          useHTML: true,
          max: null,
          min: null
        }],
      set: {
        // redraw: Math.random(),
        useHighStocks: !(chartTmp.tmp.set.polar || chartTmp.tmp.set.inverted || specialChart || chartTmp.tmp.set.options3d.enabled)
          && (this._base.isT(xAxis['id']) || xAxis.dataType === 'datetime' || xAxis.dataType === 'date' || xAxis.dataType === 'Date'), // 使用stockChart or chart
        options3d: chartTmp.tmp.set.options3d,
        chartFor: TCD.chart_for, // chart_for
        inverted: chartTmp.tmp.set.inverted, // x轴反转
        polar: chartTmp.tmp.set.polar, // 极地图
        lighten: chartTmp.tmp.set.lighten, // 逐渐变亮
        flags: chartTmp.tmp.set.flagTypes.flag.data,
        subline: chartTmp.tmp.set.subline, // 辅助线
        connectNulls: !chartTmp.tmp.set.ignoreNull, // 忽略空值
        stacking: chartTmp.tmp.set.stacking // 堆积方式
      }
    };
    return template;
  }
  // series
  getSeries(chartFor, chartTmp, item, index, num, append) {
    const colors = this._base.setting.i10n.colors;
    const color = colors[index % colors.length];
    const groupingUnits = [
      [
        'week',                         // unit name
        [1]                             // allowed multiples
      ],
      [
        'month',
        [1, 2, 3, 4, 6]
      ]
    ];
    const type = {
      common: () => {
        return {
          'id': item['fieldCode'],
          'name': item['fieldName'],
          'dataName': item['fieldName'],
          'dataLabels': {
            'enabled': false,
            'color': color,
            'style': {
              'fontSize': '12px',
              'textShadow': 'none'
            }
          },
          'type': chartFor.type,
          'color': color,
          'visible': true,
          'yAxis': '轴1',
          'yAxisShow': false,
          'smooth': false,
          'marker': {
            'symbol': 'circle',
            'lineWidth': 2,
            'lineColor': color,
            'enabled': false
          },
          'tooltip': {
            'valuePrefix': '',
            'valueSuffix': ''
          },
          'dashStyle': 'Solid',
          'lineWidth': 2,
          'showInLegend': true
        };
      },
      pie: () => {
        const n = Math.floor(100 / num);
        return {
          'id': item.fieldCode,
          'name': item.fieldName,
          'dataName': item['fieldName'],
          'color': color,
          'marker': {
            'lineWidth': 2,
            'lineColor': color,
            'enabled': false
          },
          'dataLabels': {
            'enabled': true,
            'color': color,
            'style': {
              'fontSize': '12px',
              'textShadow': 'none'
            }
          },
          'tooltip': {
            'valuePrefix': '',
            'valueSuffix': ''
          },
          'type': chartFor.type,
          'topCenter': 50,
          'leftCenter': 50,
          'inSize': Math.floor(n * (index + 0.8)) - 1,
          'outSize': Math.floor(n * (index + 0.8))
        };
      },
      bubble: () => {
        return {
          'id': item.fieldCode,
          'name': item.fieldName,
          'dataName': item['fieldName'],
          'color': color,
          'marker': {
            'fillColor': {
              radialGradient: {cx: 0.4, cy: 0.3, r: 0.7},
              stops: [
                [0, 'rgba(255,255,255,0.5)'],
                [1, color]
              ]
            },
            'lineWidth': 0,
            'lineColor': color,
            'enabled': true
          },
          'minSize': 3,
          'maxSize': 50,
          'gradientColor': 'transparent',
          'gradientColorEnble': false,
          'visible': true,
          'yAxis': '轴1',
          'dataLabels': {
            'enabled': true,
            'color': color,
            'style': {
              'fontSize': '12px',
              'textShadow': 'none'
            }
          },
          'tooltip': {
            'valuePrefix': '',
            'valueSuffix': ''
          },
          'type': chartFor.type
        };
      },
      candle: () => {
        return {
          'id': item.fieldCode,
          'name': index === 0 ? '股价' : '成交量',
          'dataName': item[index === 0 ? '股价' : '成交量'],
          'type': index === 0 ? 'candlestick' : 'column',
          'color': index === 0 ? 'green' : '#9fc5e8',
          'lineColor': index === 0 ? 'green' : '',
          'upColor': index === 0 ? 'red' : '',
          'upLineColor': index === 0 ? 'red' : '',
          'yAxis': index === 0 ? '轴1' : '轴2',
          'marker': {
            'lineWidth': 2,
            'lineColor': color,
            'enabled': false
          },
          'dataGrouping': {
            'units': groupingUnits
          },
          'visible': true,
          'groupPaddings': 20
        };
      }
    };

    const result = type[chartFor.template]();
    if (append) {
      jQuery.extend(result, append);
    }

    return result;
  }

  inSeries(chartOptions: ChartOptions, fieldCode: string) {
    return chartOptions && chartOptions.series && chartOptions.series.some(function (serie) {
      return serie.id === fieldCode;
    });
  }
}
