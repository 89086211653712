import { Injectable } from '@angular/core';

@Injectable()
export class PrintService {

  constructor() { }

  beforePrint() {
    // 隐藏不需要打印的元素
    const elementsToHide = document.querySelectorAll('.no-print');
    elementsToHide.forEach(element => {
      const display = element['style'].display;
      element['style'].display = 'none';
      element.setAttribute('data-print-display', display);
    });
  }

  afterPrint() {
    // 打印后恢复被隐藏的元素
    const elementsToShow = document.querySelectorAll('.no-print');
    elementsToShow.forEach(element => {
      const display = element.getAttribute('data-print-display');
      element['style'].display = display ? display : '';
      element.removeAttribute('data-print-display');
    });
  }


  printPages(id: string) {
    let printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>合同打印</title>');
    printWindow.document.write('<link href="assest/print.scss" rel="stylesheet" type="text/css" />');
    printWindow.document.write('</head><body>');
    printWindow.document.write(document.getElementById(id).innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  }
}
