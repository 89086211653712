import {NgModule} from '@angular/core';
import {UcStateService} from './state.service';
import {UcCacheModule} from '../cache/cache.module';

@NgModule({
  imports: [
    UcCacheModule
  ],
  declarations: [],
  providers: [UcStateService]
})
export class UcStateModule {
}
