<div [formGroup]="parentFormGroup">
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired nzFor="connectionName">选择共享范围</nz-form-label>
    <nz-form-control [nzSpan]="18">
      <input type="hidden" formControlName="shared"/>
       <nz-radio-group formControlName="sharedScope" id="sharedScope" (ngModelChange)="selectSharedScope()">
        <label nz-radio-button [nzValue]="scope[scope.PRIVATE]">私有</label>
        <label nz-radio-button [nzValue]="scope[scope.USER]">共享给用户</label>
        <label nz-radio-button [nzValue]="scope[scope.ORG]">共享给机构</label>
        <label nz-radio-button [nzValue]="scope[scope.ROLE]">共享给角色</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="currentSharedScope==scope[scope.USER]">
    <nz-form-label [nzSpan]="6" nzFor="connectionName">共享给用户</nz-form-label>
    <nz-form-control [nzSpan]="18">
      <nz-select [nzDropdownMatchSelectWidth]="true" [nzMaxMultipleCount]="3" nzMode="multiple" nzPlaceHolder="请输入用户名"
                 formControlName="accessUserSet" nzAllowClear nzShowSearch [nzServerSearch]="true"
                 (nzOnSearch)="onUserSearch($event)">
        <ng-container *ngFor="let option of sharedUserStatus.options">
          <nz-option *ngIf="!sharedUserStatus.isLoading" [nzLabel]="option.title" [nzValue]="option.value"></nz-option>
        </ng-container>
        <nz-option *ngIf="sharedUserStatus.isLoading" nzDisabled nzCustomContent>
          <i nz-icon nzType="loading" nzTheme="outline"></i> 数据加载中...
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="currentSharedScope==scope[scope.ORG]">
    <nz-form-label [nzSpan]="6" nzFor="connectionName">共享给机构</nz-form-label>
    <nz-form-control [nzSpan]="18">
      <nz-tree-select [nzDropdownMatchSelectWidth]="true" [nzMultiple]="true" nzPlaceHolder="请输入机构名"
                      formControlName="accessOrgPathSet"
                      [nzAsyncData]="true" [nzNodes]="sharedOrgStatus.options"
                      (nzExpandChange)="onOrgExpandChange($event)">
      </nz-tree-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="currentSharedScope==scope[scope.ROLE]">
    <nz-form-label [nzSpan]="6" nzFor="connectionName">共享给角色</nz-form-label>
    <nz-form-control [nzSpan]="18">
      <nz-select [nzDropdownMatchSelectWidth]="true" [nzMaxMultipleCount]="3" nzMode="multiple" nzPlaceHolder="请输入角色名"
                 formControlName="accessRoleSet" nzAllowClear nzShowSearch [nzServerSearch]="true"
                 (nzOnSearch)="onRoleSearch($event)">
        <ng-container *ngFor="let option of sharedRoleStatus.options">
          <nz-option *ngIf="!sharedRoleStatus.isLoading" [nzLabel]="option.title" [nzValue]="option.value"></nz-option>
        </ng-container>
        <nz-option *ngIf="sharedRoleStatus.isLoading" nzDisabled nzCustomContent>
          <i nz-icon nzType="loading" nzTheme="outline"></i> 数据加载中...
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</div>
