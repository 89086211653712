import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Route, Router, Routes} from '@angular/router';
import {UcAuthService} from '../core/auth';
import {Observable, Subject} from 'rxjs/index';
import {map, takeUntil} from 'rxjs/operators';
import {TradingDayService} from '../core/common/tradingDay.service';
import {cloneDeep, UcEnvironmentService} from '../core/common';
import {CurrentCompanyService} from '../core/common/currentCompany.service';
import {MenusListService} from './menusList.service';
import * as moment from 'moment';

export interface MenuItem {
  url?: string;
  path: string;
  breadcrumb?: string;
  title?: string;
  icon?: string;
  roles?: string | string[];
  selected?: boolean;
  expanded?: boolean;
  inSider?: boolean; // 是否左侧菜单展示
  inHeader?: boolean; // 是否头部菜单展示
  children?: MenuItem[];
}

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html'
})

export class SystemComponent implements OnInit, AfterViewInit, OnDestroy {
  destory$ = new Subject();

  width = 200;
  collapsedWidth = 50;
  isCollapsed = false;
  menuItems: any[];
  baseUrl: string;
  openid: string;
  logo: string;
  title: string;
  year;

  get isAuthenticated(): Observable<boolean> {
    return this.authService.isAuthenticated();
  }

  get username(): Observable<string> {
    return this.isAuthenticated.pipe(map(value => value ? this.authService.username : ''));
  }

  // get openid(): Observable<string> {
  //   return this.isAuthenticated.pipe(map(value => value ? this.authService.openid : ''));
  // }

  get letter(): Observable<string> {
    return this.username.pipe(map(value => value ? value.slice(0, 1).toUpperCase() : ''));
  }

  get icon(): Observable<string> {
    return this.username.pipe(map(value => value ? '' : 'user'));
  }

  get isTradingDay(): Observable<boolean> {
    return this.tradingDayService.isTradingdaysed();
  }

  constructor(
    private authService: UcAuthService,
    private router: Router,
    private tradingDayService: TradingDayService,
    private environmentService: UcEnvironmentService,
    private currentCompanyService: CurrentCompanyService,
    private menusListService: MenusListService
    ) {
    this.tradingDayService.resetDays();
    this.baseUrl = this.environmentService.environment.host;
    this.logo = this.baseUrl + this.environmentService.environment.baseUrl + 'assets/images/logo-black.png';
    this.title = this.environmentService.environment.footerTitle;
    this.year = moment(new Date).format('yyyy');
  }

  ngOnInit() {
    this.isAuthenticated.pipe(takeUntil(this.destory$)).subscribe((value => {
      if (value) {
        // this.menuItems = this.getItemsFromArray(this.environmentService.environment.routes);
        // const fistItem = this.menuItems && this.menuItems.length > 0 ? this.menuItems[0] : null;
        // const url = fistItem && fistItem.children && fistItem.children.length > 0 ? fistItem.children[0].url : null;
        // if (!this.authService.lastPath || this.authService.lastPath === UcAuthService.SYSTEM_INDEX) {
        //   this.router.navigate([url]).then();
        // }

        this.menusListService.getParentMenuItem().subscribe(menusList => {
          this.menuItems = menusList;
        });
        // this.menuItems = this.menusListService.menuItems;
      }
    }));

    this.isTradingDay.subscribe((value) => {
      if (!value) {
        const year = new Date().getFullYear();
        this.tradingDayService.getIniterTradingdays(year);
      }
    });
    this.currentCompanyService.resetCompany();
  }

  ngAfterViewInit() {
    this.openid = this.authService.openid;
  }


  ngOnDestroy() {
    this.destory$.next();
    this.destory$.complete();
  }

  logout() {
    this.tradingDayService.clearTradingdays();
    this.authService.logout();
  }

  private getItemsFromArray(routes: any[]): MenuItem[] {
    const routeList = cloneDeep(routes);
    const items: MenuItem[] = [];
    routeList.forEach((route) => {
      this.getItems(route, items, `/${route.path}`);
    });
    return items;
  }

  private getItems(route: any, items: MenuItem[], url: string = ''): MenuItem[] {
    const children: any[] = route.children;
    if (!children || children.length === 0) {
      return items;
    }

    for (const child of children) {

      let routeUrl = url;
      if (child.path) {
        routeUrl += `/${child.path}`;
      }

      if (!child.title) {
        continue;
      }

      const item: MenuItem = child as MenuItem;
      item.url = routeUrl;
      if (item.children && item.children.length > 0) {
        const childrenItems = [];
        item.children.forEach((c) => {
          c.url = [item.url, c.path].join('/');
        });

        item.children.filter((x) => this.authService.isAuthorized(x.roles).subscribe(value => {
          if (value) {
            childrenItems.push(x);
          }
        }));
        item.children = childrenItems;
      }

      if (items.filter((x) => x.url === item.url).length === 0) {
        this.authService.isAuthorized(item.roles).subscribe(value => {
          if (value) {
            items.push(item);
          }
        });
      }
    }
    return items;
  }

}

