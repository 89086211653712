import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgZorroAntdModule } from 'ng-zorro-antd';

import { UcChartComponent } from './ucChart.component';



@NgModule({
  imports        : [ CommonModule, NgZorroAntdModule, FormsModule],
  exports        : [ UcChartComponent ],
  declarations   : [ UcChartComponent ],
  entryComponents: [ UcChartComponent ],
  // providers      : [ ...SERVICE ]
})
export class UcChartModule {
}
