import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UcCacheService} from './cache.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [UcCacheService]
})
export class UcCacheModule {
}
