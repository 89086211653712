import {Component, Input, Output, OnInit, OnDestroy, EventEmitter, AfterViewInit} from '@angular/core';
import {UcStateService} from '../../../core/state/state.service';
import {Subscription} from 'rxjs/index';
import {Editor, ScrollInfo} from 'codemirror';

export interface UcCodeOptions {
  lineNumbers?: boolean; // 显示行号
  matchBrackets?: boolean;    // 代码块匹配
  theme?: string;   // 设置主题
  indentUnit?: number; // 缩进格数
  lineWrapping?: boolean;
  foldGutter?: boolean;
  gutters?: string[];
  mode?: string;
  autoRefresh?: boolean; // 自动刷新，避免布局问题
}

@Component({
  selector: 'app-common-code',
  templateUrl: './codemirror.component.html',
  styleUrls: ['./codemirror.component.scss']
})
export class UcCodeComponent implements OnInit, OnDestroy, AfterViewInit {

  private _defaultOptions: UcCodeOptions = {
    lineNumbers: true,
    matchBrackets: true,
    theme: 'xq-light',
    indentUnit: 2,
    lineWrapping: true,
    foldGutter: true,
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
    mode: 'javascript',
    autoRefresh: true
  };

  private _codeValue = '';
  private _options = this._defaultOptions;
  private _stateSubscription: Subscription;

  @Input() keyName: string;
  @Input() autoFocus = true;
  @Input() preserveScrollPosition = true;
  @Output() codeValueChange = new EventEmitter<string>();
  @Output() cursorActivityChange = new EventEmitter<Editor>();
  @Output() focusChange = new EventEmitter<boolean>();
  @Output() scrollInfoChange = new EventEmitter<ScrollInfo>();

  @Input()
  set codeValue(value: string | Object) {
    this._codeValue = this.initDefaultValue(value);
  }

  get codeValue(): string | Object {
    return this._codeValue;
  }

  @Input()
  set options(value: UcCodeOptions) {
    if (value) {
      this._options = Object.assign({}, this._defaultOptions, value);
    }
  }

  get options(): UcCodeOptions {
    return this._options;
  }

  constructor(private stateService: UcStateService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // 设置一个状态订阅，方便外部组件改变值
    this._stateSubscription = this.stateService.subscribe(this.keyName, (value) => {
      this.codeValue = value;
    });
  }

  ngOnDestroy() {
    if (this._stateSubscription) {
      this._stateSubscription.unsubscribe();
      this.stateService.remove(this.keyName);
    }
  }

  /**
   * 初始化数据
   */
  initDefaultValue(value) {
    if (value) {
      if (value instanceof Object) {
        return JSON.stringify(value, null, 4);
      }
      return value;
    }
    return '';
  }

  /**
   * 值变化
   */
  OnCodeValueChange(value) {
    this.codeValueChange.emit(value);
  }

  /**
   * 焦点变化
   * */
  OnFocusChange(focused) {
    this.focusChange.emit(focused);
  }

  /**
   * 光标变化
   */
  OnCursorActivityChange(editor) {
    this.cursorActivityChange.emit(editor);
  }

  /**
   * 滚动
   */
  OnScroll(info) {
    this.scrollInfoChange.emit(info);
  }

  getCodeValue() {
    let value;
    if (typeof (this.codeValue) === 'string') {
      value = JSON.parse(this.codeValue);
    } else {
      value = this.codeValue;
    }
    return value;
  }


}
