import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {UcAuthService} from './auth.service';
import {UcAuthConfig} from './authConfig';
import {map} from 'rxjs/operators';
import {NzMessageService} from 'ng-zorro-antd';

@Injectable()
export class UcAuthentication implements CanActivate {

  constructor(private authService: UcAuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.authService.isAuthenticated();
  }
}
