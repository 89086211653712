import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

type UcDoRequest = (request: HttpRequest<any>) => HttpRequest<any> | void;
type UcDoResponse = (response: HttpEvent<any>, request?: HttpRequest<any>) => HttpEvent<any> | void;
type UcDoResponseError = (HttpErrorResponse, request?: HttpRequest<any>) => HttpErrorResponse | void;

export interface UcInterceptor {
  doRequest?: UcDoRequest;
  doResponse?: UcDoResponse;
  doResponseError?: UcDoResponseError;
  id?: string;
}

/**
 * 自定义拦截器服务类，主要实现自定义拦截器配置和调用
 */
@Injectable()
export class UcHttpService {

  private interceptors: UcInterceptor[];

  constructor() {
    this.interceptors = [];
  }

  clearInterceptors() {
    this.interceptors = [];
  }

  getInterceptors() {
    return this.interceptors;
  }

  addInterceptor(interceptor: UcInterceptor): UcHttpService {
    this.interceptors.push(interceptor);
    return this;
  }

  addRequestInterceptor(doRequest: UcDoRequest): UcHttpService {
    return this.addInterceptor({
      doRequest: (request: HttpRequest<any>): HttpRequest<any> | void => {
        return doRequest(request) || request;
      }
    });
  }

  addResponseInterceptor(doResponse: UcDoResponse): UcHttpService {
    return this.addInterceptor({
      doResponse: (response: HttpEvent<any>, request?: HttpRequest<any>): HttpEvent<any> | void => {
        return doResponse(response, request) || response;
      }
    });
  }

  addResponseErrorInterceptor(doResponseError: UcDoResponseError): UcHttpService {
    return this.addInterceptor({
      doResponseError: (response: HttpErrorResponse, request?: HttpRequest<any>): HttpErrorResponse | void => {
        return doResponseError(response, request) || response;
      }
    });
  }

  addBaseUrlInterceptor(baseUrl: string, excludes: RegExp[] = []): UcHttpService {
    if (baseUrl) {
      const id = '__baseUrl__';

      this.interceptors = this.interceptors.filter(x => !(x.id && x.id === id));
      this.interceptors.push({
        id: id,
        doRequest: (request: HttpRequest<any>): HttpRequest<any> => {
          if (/^https?:/.test(request.url)) {
            return request;
          }

          const excludeUrl = excludes.some(t => t.test(request.url));
          if (excludeUrl) {
            return request;
          }

          baseUrl = baseUrl.replace(/\/$/, '');
          const url = request.url.replace(/^\//, '');
          return request.clone({url: `${baseUrl}/${url}`});
        }
      });

    }
    return this;
  }

  addHeaderInterceptor(headers: { [name: string]: string | string[]; } = {}, override = false): UcHttpService {
    return this.addInterceptor({
      doRequest: (request: HttpRequest<any>): HttpRequest<any> => {
        let result = headers;
        if (request.headers) {
          result = Object.keys(headers).reduce((obj, key) => {
            if (override || !request.headers.has(key)) {
              obj[key] = headers[key];
            }
            return obj;
          }, {});
        }
        return request.clone({setHeaders: result});
      }
    });
  }

  addWithCredentialsInterceptor(withCredentials: boolean = true): UcHttpService {
    return this.addInterceptor({
      doRequest: (request: HttpRequest<any>): HttpRequest<any> => {
        return request.clone({withCredentials: withCredentials});
      }
    });
  }

  handleRequest(request: HttpRequest<any>): HttpRequest<any> {
    return this.interceptors
      .filter(interceptor => !!interceptor.doRequest)
      .reduce((req, interceptor) => {
        return (interceptor.doRequest(req) || req);
      }, request);
  }

  handleResponse(response: HttpEvent<any>, request?: HttpRequest<any>): HttpEvent<any> {
    return this.interceptors
      .filter(interceptor => !!interceptor.doResponse)
      .reverse()
      .reduce((res, interceptor) => {
        return interceptor.doResponse(res, request) || res;
      }, response);
  }

  handleResponseError(response: HttpErrorResponse, request?: HttpRequest<any>): HttpErrorResponse {
    return this.interceptors
      .filter(interceptor => !!interceptor.doResponseError)
      .reverse()
      .reduce((res, interceptor) => {
        return interceptor.doResponseError(res, request) || res;
      }, response);
  }
}

/**
 * 实现http拦截器，通过module配置一个名为HTTP_INTERCEPTORS的provider注入框架
 */
@Injectable()
export class UcProxyHttpInterceptor implements HttpInterceptor {
  constructor(private httpService: UcHttpService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const httpRequest = this.httpService.handleRequest(request);
    return next.handle(httpRequest).pipe(
      map(response => {
        // 请求返回后调用自定义拦截器处理response
        if ([HttpEventType.Response, HttpEventType.ResponseHeader].indexOf(response.type) !== -1) {
          return (this.httpService.handleResponse(response, httpRequest) || response);
        }
        return response;
      }),
      catchError(response => {
        if (response instanceof HttpErrorResponse) {
          return throwError(this.httpService.handleResponseError(response as HttpErrorResponse, httpRequest) || response);
        }
        return throwError(response);
      })
    );
  }
}
