import {Routes, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {UcLoginComponent} from './login';
import {UcDoLoginComponent} from './doLogin/';
import {UcAccountComponent} from './account.component';
import {UcClientValidComponent} from './clientValid/cientValid.component';
import {ForgetPasswordComponent} from './forgetPassword/forgetPassword.component';

const routes: Routes = [
  {
    path: 'account',
    component: UcAccountComponent,
    children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: 'login', component: UcLoginComponent},
      // {path: 'm_login', component: UcMLoginComponent},
      {path: 'do_login', component: UcDoLoginComponent},
      {path: 'client_valid', component: UcClientValidComponent},
      {path: 'forget_password', component: ForgetPasswordComponent}
    ],
    data: {
      preload: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UcAccountRoutingModule {
}

