import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {cloneDeep} from '../../../core/common';
import {ChartTemplate, TEMPLATE, ChartDataTmp, UcChartConfig, ChartOptions} from './ucChart.model';
import {UcChartComponent} from './ucChart.component';

declare const require: any;
const Highcharts = require('highcharts/highstock');
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/boost')(Highcharts);
require('highcharts/modules/boost-canvas')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
Highcharts.setOptions({
  lang: {
    contextButtonTitle: '图表导出菜单',
    noData: '暂无数据',
    decimalPoint: '.',
    downloadJPEG: '下载JPG图片',
    downloadPDF: '下载PDF文件',
    downloadPNG: '下载PNG图片',
    downloadSVG: '下载SVG文件',
    drillUpText: '返回 {series.name}',
    loading: '加载中',
    months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    numericSymbols: ['K', 'M', 'G', 'T', 'P', 'E'],
    printChart: '打印图表',
    resetZoom: '恢复缩放',
    resetZoomTitle: '恢复图表',
    shortMonths: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    thousandsSep: ',',
    weekdays: ['星期一', '星期二', '星期三', '星期三', '星期四', '星期五', '星期六', '星期天'],
    rangeSelectorFrom: '从',
    rangeSelectorZoom: '缩放',
    rangeSelectorTo: '到'
  },
  time: {useUTC: false}
});

@Injectable()
export class ChartService {
  public chart: any;
  public defaultConfig = {
    chart: {
      renderTo: null,
      className: 'hzCharts', // 图表容器 div 的CSS类名称
      panKey: 'shift', // 平移键
      reflow: true // 图表自适应图表容器
    },
    boost: {
      useGPUTranslations: true
    },
    credits: { // 版权信息
      enabled: false,
      href: null,
      style: { 'cursor': 'pointer', 'color': '#999999', 'fontSize': '10px' },
      text: '该图表由汇哲大数据提供(图表数据仅供参考)'
    },
    exporting: { // 导出功能模块
      enabled: false,
      filename: 'chart', // 导出文件的名字
    },
    legend: {
      backgroundColor: 'transparent',
      useHTML: true,
      labelFormatter: function () { // 每个图例项标签的格式化的回调函数 this == series
        return this.name;
      }
    },
    plotOptions: {
      series : {
        className: 'hz_series',
        dataGrouping: {  // 数据分组的开启影响动态图在选择器为all的时候，能否正常update数据
          dateTimeLabelFormats: {
            millisecond: ['%Y-%m-%d %H:%M:%S', '%Y年%m月%d日 %H:%M:%S.%L'],
            second: ['%Y-%m-%d %H:%M:%S', '%Y年%m月%d日 %H:%M:%S'],
            minute: ['%Y-%m-%d %H:%M:%S', '%Y年%m月%d日 %H:%M'],
            hour: ['%Y-%m-%d %H:%M', '%Y年%m月%d日 %H:%M'],
            day: ['%Y-%m-%d', '%Y年%m月%d日 %H:%M', '-%H:%M'],
            week: ['%Y-%m-%d', '%A, %b %e', '-%A, %b %e, %Y'],
            month: ['%Y-%m', '%B', '-%B %Y'],
            year: ['%Y', '%Y', '-%Y']
          }
        }
      }
    },
    title: {
      text: null
    },
    tooltip: {
      xDateFormat: '%Y年%m月%d日 %H:%M:%S'
    },
    // highstock配置
    rangeSelector: { // 范围选择器
      inputDateFormat: '%Y-%m-%d'
    },
    navigator: { // 导航器
      margin: 2,
      handles: {
        backgroundColor: 'rgba(255,255,255,0.5)',
        borderColor: '#ccc'
      },
      yAxis: {
        lineWidth: 0
      },
      xAxis: {
        labels: {
          style: {
            color: '#ccc'
          }
        },
        crosshair: false
      },
      outlineWidth: 1
    }
  };

  constructor(
    private _base: BaseService,
  ) {}

  createChart(component: UcChartComponent, TCD: ChartTemplate) {
    TCD = cloneDeep(TCD);

    if (TCD.createType === 'highcharts') {
      this.createHighcharts(component, TCD);
    } else if (TCD.createType === 'echarts') {
      this.createEcharts(component, TCD);
    }

    return this.chart;
  }

  createEcharts(component: UcChartComponent, TCD: ChartTemplate) {
    const element = $('#' + component.id)[0];
    return false;
  }

  createHighcharts(component: UcChartComponent, TCD: ChartTemplate) {
    const element = $('#' + component.id)[0];
    const chartOptions = TCD.chart_config.chartOptions;
    const template = TCD.template;
    // 合并配置
    const allchartOptions = this.deepObjectMerge(cloneDeep(this.defaultConfig), chartOptions);

    // Y轴处理
    if (template.yAxis) {
      this.chartYAxis(allchartOptions, template);
    }
    // x轴数据处理
    this.chartXAxis(allchartOptions, template.set, TCD.dataTmp.data_init, TCD.dataTmp.data_row);
    // 标准化数据
    this.xAxisSet(allchartOptions, template, TCD.dataTmp);

    try {
      component.isLoading = false;
      // hightChart 传入dom
      allchartOptions['chart']['renderTo'] = element;
      // 判断是highstock启动还是highchart启动
      if (template.set.useHighStocks) {
        this.chart = Highcharts.stockChart(allchartOptions);
      } else {
        this.chart = Highcharts.chart(allchartOptions);
      }
    } catch (e) {
      console.log('配置文件错误', e);
    }
  }

  // 标准化数据
  xAxisSet(options: ChartOptions, template: TEMPLATE, dataTmp: ChartDataTmp) {
    const _base = this._base;
    const colors = this._base.setting.i10n.colors;
    const cols = dataTmp.data_init;
    // 色彩明亮度
    const colorsBright = function (index) {
      if (index === 0) {
        return colors;
      }
      return _base.setting.i10n.colors.map((color) => {
        return Highcharts.Color(color).brighten(0.1 * index).get();
      });
    };
    // 饼图分析
    const commonPieParser = function (item) {
      item.size = Math.max(item.outSize, 1) + '%';
      item.innerSize = Math.min(item.inSize, 99) + '%';
      item.center = [item.leftCenter + '%', item.topCenter + '%'];
      item.dataLabels.formatter = function () {
        const p = this.percentage.toFixed(2);
        return this.percentage > 1 ? '<span style="color:' + this.point.color + '">' + p + '%</span>' : null;
      };
    };
    // x轴是是否为时间轴判断
    const isTimeX = this._base.isT(options.xAxis.id) || options.xAxis.dataType === 'date' || options.xAxis.dataType === 'Date';
    const xid = options.xAxis.id;
    const xValue = xid ? cols[xid] : options.xAxis.categories;
    const useX = template.set.chartFor && template.set.chartFor.type === 'scatter';
    const parser = {
      commonFun: () => {
        options.series.map((item) => {
          if (this._base.lineAreaParser[item.type]) {
            item.marker.fillColor = item.marker.lineColor;
            if (item.smooth) { // 曲线是否光滑
              item.type = this._base.lineAreaParser[item.type];
            }
          }

          item.data = cols[this._base.QID(item.dataName)].map((y, index) => {
            // y = parseFloat(this._base.isNum_(y)); // 有效小数位处理
            y = parseFloat(y);
            y = isNaN(y) ? null : y;
            // x轴为时间轴 数据返回
            if (isTimeX && !(template.set.inverted || template.set.polar)) {
              return [this._base.xyVal(xValue[index]), y];
            }
            // 散点图数据返回
            if (useX) {
              // let x = parseFloat(this._base.isNum_(xValue[index]));
              let x = parseFloat(xValue[index]);
              x = isNaN(x) ? null : x;
              return [x, y];
            }
            return y;
          });
        });
      },
      pieFun: () => {
        options.series.forEach((item, key) => {
          let C = colors;
          if (template.set.lighten) {
            C = colorsBright(key);
          }
          commonPieParser(item);
          item.data = cols[this._base.QID(item.dataName)].map((y, index) => {
            // y = parseFloat(this._base.isNum_(y));
            y = parseFloat(y);
            y = isNaN(y) ? null : y;
            return {
              name: item.xAxisId ?
                isTimeX ?
                  this._base.Format(new Date(this._base.xyVal(cols[item.xAxisId][index])), 'yyyy-MM-dd') : cols[item.xAxisId][index] :
                options.xAxis.id ? cols[options.xAxis.id][index] : options.xAxis.categories[index],
              // name: item.dataName,
              y: y,
              color: C[index % C.length]
            };
          });
        });
      },
      pieDoubleFun: () => {
        // const xValueDouble = cols[options.xAxis.id].map(item => parseFloat(this._base.isNum_(item)));
        const xValueDouble = cols[options.xAxis.id].map(item => parseFloat(item));
        const bigType = {}; // 存放饼图data
        let bigName;
        options.series.forEach((item, key) => {
          commonPieParser(item);
          if (key === 0) {
            bigName = this._base.QID(item.dataName);
            cols[bigName].forEach((y, index) => {
              if (!bigType[y]) {
                bigType[y] = {
                  y: 0,
                  color: colors[index % colors.length],
                  name: y,
                  shade: 0
                };
              }
              bigType[y].y += xValueDouble[index]; // y轴赋值
            });
            item.data = Object.keys(bigType).map((row, index) => {
              return bigType[row];
            });
          } else {
            item.data = cols[this._base.QID(item.dataName)].map((y, index) => {
              bigType[cols[bigName][index]].shade++;
              return {
                y: xValueDouble[index],
                name: y,
                color: Highcharts.Color(bigType[cols[bigName][index]].color).brighten(bigType[cols[bigName][index]].shade * 0.02).get()
              };
            });
          }
        });
      },
      bubbleFun: () => {
        options.series.map((item, index) => {
          if (item.gradientColorEnble) {
            item.marker.fillColor = {
              radialGradient: {cx: 0.4, cy: 0.3, r: 0.7},
              stops: [
                [0, item.gradientColor],
                [1, item.color]
              ]
            };
          } else {
            item.marker.fillColor = item.color;
            item.marker.lineColor = item.color;
          }
          item.dataLabels.formatter = function() {
            return this.point.z;
          };
          item.data = cols[this._base.QID(item.dataName)].map((y, num) => {
            let z = cols[item.zPoint.fieldCode][num];
            // y = parseFloat(this._base.isNum_(y));
            y = parseFloat(y);
            y = isNaN(y) ? null : y;
            // z = parseFloat(this._base.isNum_(z));
            z = parseFloat(z);
            z = isNaN(z) ? null : z;
            return [y, z];
          });
        });
      }
    };
    parser[template.set.chartFor ? template.set.chartFor.parseDataFun : 'commonFun']();
  }
  // X轴处理
  chartXAxis(chartOptions: ChartOptions, T_set, cols, rows) {
    if (chartOptions.xAxis.id === null || chartOptions.xAxis.id === '') {
      chartOptions.xAxis.categories = this._base.range(rows.length + 1, 'INC', 1);
      // tslint:disable-next-line:max-line-length
    } else if (T_set.inverted || T_set.polar || (!this._base.isT(chartOptions.xAxis.id) && chartOptions.xAxis.dataType !== 'date' && chartOptions.xAxis.dataType !== 'Date')) {
      let categories = cols[chartOptions.xAxis.id];
      // tslint:disable-next-line:max-line-length
      if ((this._base.isT(chartOptions.xAxis.id) || chartOptions.xAxis.dataType === 'date' || chartOptions.xAxis.dataType === 'Date') && typeof(categories[0]) !== 'object' && categories[0].toString().indexOf('-') === -1) {
        categories = categories.map(row => {
          return this._base.Format(new Date(this._base.xyVal(row)), 'yyyy-MM-dd');
        });
      }
      chartOptions.xAxis.categories = categories;
    }
  }
  // Y轴处理
  chartYAxis(options, template) {
    return options.yAxis.map(function (item) {
      if (!item.labels) {
        item.labels = {};
      }
      if (typeof(item.unit) === 'undefined') {
        item.unit = '';
      }
      if (typeof(item.max) === 'undefined' || item.max === '') {
        item.max = null;
      }
      if (typeof(item.min) === 'undefined' || item.min === '') {
        item.min = null;
      }

      item.labels.formatter = function () {
        this.axis.options.lineColor = this.axis.options.title.style.color;
        this.axis.options.tickColor = this.axis.options.title.style.color;
        this.axis.options.labels.style.color = this.axis.options.title.style.color;
        this.axis.options.tickWidth = 2;
        this.axis.options.lineWidth = 2;
        if (template.ismobile) {
          this.axis.options.tickWidth = 0;
          this.axis.options.lineWidth = 0;
          this.axis.options.labels.align = this.axis.options.opposite ? 'right' : 'left';
          this.axis.options.labels.x = 0;
        }
        if (!template.compare) {
          return smallY(this.value) + this.axis.options.unit;
        }
        return this.value + this.axis.options.unit;
      };
      return item;
    });
  }

  // 深度合并对象
  deepObjectMerge(FirstOBJ, SecondOBJ) {
    // tslint:disable-next-line:forin
    for (const key in SecondOBJ) {
      FirstOBJ[key] = FirstOBJ[key] && FirstOBJ[key].toString() === '[object Object]' ?
        this.deepObjectMerge(FirstOBJ[key], SecondOBJ[key]) : FirstOBJ[key] = SecondOBJ[key];
    }
    return FirstOBJ;
  }
}

// Y轴数据有效位数
function smallY(value: any) {
  const u = {5: '万', 9: '亿', 13: '万亿'};
  let ok = false;
  // tslint:disable-next-line:radix
  const v = parseInt(value);
  const l = Math.abs(v).toString().length;

  if (l > 4) {
    Object.keys(u).reverse().forEach(function (len) {
      // tslint:disable-next-line:radix
      if (!ok && l - parseInt(len) >= 0) {
        ok = true;
        // tslint:disable-next-line:radix
        value = (value / Math.pow(10, parseInt(len) - 1)).toFixed(1) + u[len];
      }
    });
  } else {   value = value.toFixed(4 - l); }
  return value;
}
