import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UcSpinnerService, UcPreloaderService} from './core/theme';
import {UcHttpService} from './core/http';
import {NzMessageService} from 'ng-zorro-antd';
import {orderFrom, UcEnvironmentService} from './core/common';
import {Title} from '@angular/platform-browser';
import {UcAuthService} from './core/auth';
import {NavigationEnd, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import * as $ from 'jquery';
import { MenusListService } from './system/menusList.service';
import {Subject} from 'rxjs';
import {TradingDayService} from './core/common/tradingDay.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  destory$ = new Subject();
  constructor(private environmentService: UcEnvironmentService,
              private authService: UcAuthService,
              private spinnerService: UcSpinnerService,
              private httpService: UcHttpService,
              private messageService: NzMessageService,
              private router: Router,
              private menusListService: MenusListService,
              private titleService: Title) {
    // this.authService.reset();
    if (!this.environmentService.host || this.environmentService.host === '') {
      this.environmentService.setOrigin();
    }
  }

  public ngOnInit() {
    // 保存路由路径
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      if (this.authService.currentPath.startsWith(UcAuthService.SYSTEM_ROOT)) {
        this.authService.lastPath = this.authService.currentPath;
      }
    });

    // 根据登录状态控制页面跳转
    this.authService.isAuthenticated().pipe(
      takeUntil(this.destory$)
    ).subscribe((value) => {
      const accountRoot = UcAuthService.ACCOUNT_ROOT;
      const systemRoot = UcAuthService.SYSTEM_ROOT;
      const currentPath = this.authService.currentPath;
      const order = orderFrom();

      if (value) {
        this.httpService.addHeaderInterceptor({
          'Authorization': this.authService.token.token_type + ' ' + this.authService.token.access_token,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        });
        // 全局http请求跨域带cookie
        this.httpService.addWithCredentialsInterceptor();
        // 全局http请求错误处理
        this.httpService
          .addInterceptor({
            // 全局错误处理
            doResponseError: (httpErrorResponse) => {

              if (httpErrorResponse.status === 401) {
                // 遇到任何api报401错误，重置前端登录状态，返回登录页面
                this.messageService.remove();
                this.httpService.clearInterceptors();
                this.authService.logout();
              } else {
                let message = '请求失败';
                if (httpErrorResponse.error && httpErrorResponse.error.message) {
                  message = httpErrorResponse.error.message;
                  if (this.environmentService.production && httpErrorResponse.error.description) {
                    message = message + '<br />' + httpErrorResponse.error.description;
                  }
                } else {
                  message = httpErrorResponse.message;
                }
                this.messageService.error(message);
              }
            }
          });

        this.menusListService.getParentMenuItem().subscribe(menusList => {
          // 前端验证成功后，跳转到上一次未验证页面
          if (order !== 'pc') {
            const pcPath = this.environmentService.host + '/m/';
            window.location.href = pcPath;
          } else {
            // 前端验证成功后，跳转到上一次未验证页面
            let firstPath = systemRoot;
            if (menusList && menusList.length > 0) {
              if (menusList[0].children && menusList[0].children.length > 0) {
                firstPath = '/system/' + menusList[0].path + '/' + menusList[0].children[0].path;
              } else {
                firstPath = '/system/' + menusList[0].path;
              }
            }
            const lastPath = this.authService.lastPath ? this.authService.lastPath : firstPath;
            const path = lastPath.startsWith(accountRoot) ? firstPath : lastPath;
            if (currentPath !== path) {
              this.router.navigate([path]).then();
            }
          }
        });
      } else {
        // 前端未验证成功，跳转登录页面
        if (!currentPath.startsWith(accountRoot)) {
          this.httpService.clearInterceptors();
          this.router.navigate([accountRoot]).then();
        }
      }
    });
  }

  ngOnDestroy() {
    this.destory$.next();
    this.destory$.complete();
  }

  /**
   * 页面加载后隐藏动画
   */
  public ngAfterViewInit() {
    this.titleService.setTitle(this.environmentService.environment.title);
    $('#appIconId').attr('href', this.environmentService.environment.icon);
    UcPreloaderService.load().then(() => {
      this.spinnerService.hide();
    });
  }
}
