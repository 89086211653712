import {Subject} from 'rxjs/index';

export interface SharedStatus {
  sharedScope: string;
  isLoading?: boolean;
  options?: any[];
  optionsChange$?: Subject<string>;
  isInit?: boolean;
}

export enum SharedScope {
  PRIVATE,
  USER,
  ROLE,
  ORG,
  PUBLIC,
  CUSTOMER
}

export interface SharedDropdownOption {
  title: string;
  value: string;
  id: string;
}


export interface SharedTreeOption {
  title: string;
  key: string;
  children?: any[];
  isLeaf?: boolean;
  disabled?: boolean;
}
