import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {UcAuthService} from '../../core/auth';
import {QueryModelList} from '../system.model';
import {UcEnvironmentService} from '../../core/common';
import {QueryModelPagination} from '../../system';

@Injectable()
export class UcCommonWsService {
  websokects = [];
  ws: WebSocket;
  websocket = {
    ws: null,
    wsObservable: null
  };
  timeout = 540000;
  timeoutObj = null;
  serverTimeoutObj = null;
  tt;
  lockReconnect = false; // 避免重复重启

  constructor(
    private authService: UcAuthService,
    private environmentService: UcEnvironmentService
  ) {
  }

  createWebSocket(url: string, body: QueryModelList) {
    const queryModle = body ? '&body=' + escape(JSON.stringify(body)) : '';
    url = url + '?access_token=' + this.authService.token.access_token + queryModle;

    const webSocket = this.createObservableSocket(url);
    this.websokects.push(webSocket.ws);

    return webSocket;
  }

  closeAllWs() {
    this.websokects.forEach((ws: WebSocket, num) => {
      ws.close();

      if (num === this.websokects.length - 1) {
        this.websokects = [];
      }
    });
  }

  closeWs() {
    this.websocket.ws.close();
  }

  // 返回一个可观测的流，包括服务器返回的消息
  createObservableSocket(url: string) {
    this.websocket.ws = new WebSocket(url);
    this.websocket.wsObservable = new Observable(
      observer => {
        this.websocket.ws.onopen = () => {};
        this.websocket.ws.onmessage = (event) => observer.next(event.data);
        this.websocket.ws.onerror = (event) => observer.error(event);
        this.websocket.ws.onclose = (event) => observer.complete();
      }
    );

    return this.websocket;
  }

  createMakeObservableSocket(url: string, body: QueryModelPagination = {}): WebSocket {
    try {
      const queryModle = body ? '&body=' + encodeURI(JSON.stringify(body)) : '';
      const  wsUrl = url + '?access_token=' + this.authService.token.access_token + queryModle;
      this.ws = new WebSocket(this.environmentService.ws.datasource + wsUrl);
      this.websokects.push(this.ws);
      this.ws.onopen = () => {
        // 心跳检测重置
        // this.reset();
      };

      this.ws.onclose = (event) => {
        console.log(new Date() + '：webSocket流结束');
        // 心态关闭，传来ping值，重启
        if (event.reason === 'ping') {
          this.webSocketReconnect(url, body);
        }
      };

      return this.ws;
    } catch (e) {
      this.webSocketReconnect(url, body);
    }
  }

  /**
   * 心态机制
   * @param updateSource
   * @param component
   */
  reset() {
    clearTimeout(this.timeoutObj);
    clearTimeout(this.serverTimeoutObj);
    this.start();
  }

  start() {
    this.timeoutObj = setTimeout(() => {
      // 这里发送一个心跳，后端收到后，返回一个心跳消息，
      // onmessage拿到返回的心跳就说明连接正常
      if (this.ws.readyState === 1) {
        this.ws.send('ping');
      }
      this.serverTimeoutObj = setTimeout(() => {// 如果超过一定时间还没重置，说明后端主动断开了
        this.ws.close(1000, 'ping');     // 如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
      }, this.timeout);
    }, this.timeout);
  }

  /**
   * 重启
   * @param updateSource
   * @param component
   */
  webSocketReconnect(url: string, body: QueryModelPagination) {
    console.log('socket 连接断开，正在尝试重新建立连接');
    if (this.lockReconnect) { return; }
    this.lockReconnect = true;
    // 没连接上会一直重连，设置延迟，避免请求过多
    if (this.tt) {
      clearTimeout(this.tt);
    }
    this.tt = setTimeout(() => {
      this.createMakeObservableSocket(url, body);
      this.lockReconnect = false;
    }, 3000);
  }
}
