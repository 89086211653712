import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd';
import {AccountService} from '../account.service';
import {Router} from '@angular/router';
import {orderFrom, UcEnvironmentService} from '../../core/common';
import {UcCacheService} from '../../core/cache';
import {UcAuthService} from '../../core/auth';
import {CryptoJSService} from '../../system/common/cryptoJS';
import * as moment from 'moment';

@Component({
  selector: 'app-account-login',
  // template: ``
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class UcLoginComponent implements OnInit {
  showContent: string;
  showSecond: number;
  inInterval: boolean;
  interval;

  userLoginForm: FormGroup;
  mobileLoginForm: FormGroup;
  order: string;
  logoUrl: string;
  codeImgUrl: string;
  wechatUrl: string;
  footerTitle: string;
  verifyCode: string;
  isSpinning: boolean;
  year;

  mobileValidator(control: FormControl): any {
    const mobileReg =  /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/ ;
    const result = mobileReg.test(control.value);
    return result ? null : { mobile: { info: '请输入正确的手机号' } };
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private accountservice: AccountService,
    private envService: UcEnvironmentService,
    private messageService: NzMessageService,
    private cacheService: UcCacheService,
    private authService: UcAuthService,
    private crypto: CryptoJSService
  ) {
    this.envService.setOrigin();
  }

  ngOnInit() {
    const order = orderFrom();
    if (order !== 'pc') {
      const pcPath = this.envService.host + '/m/';
      window.location.href = pcPath;
    }

    this.year = moment(new Date).format('yyyy');
    this.logoUrl = this.envService.host + this.envService.environment.baseUrl + 'assets/images/' + this.envService.environment.logo;
    this.codeImgUrl = this.envService.host + '/usercenter/account/image_code?' + Math.random();
    this.wechatUrl = this.envService.host + '/usercenter/account/wechat';
    this.footerTitle = this.envService.environment.footerTitle;
    this.showContent = 'user';
    this.showSecond = 60;
    this.inInterval = false;
    this.isSpinning = false;
    const un = this.getCookies('un');
    const rm = this.getCookies('rm');

    this.userLoginForm = this.formBuilder.group({
      username: [ un === undefined ? null : un, [ Validators.required ] ],
      password: [ null, [ Validators.required ] ],
      authType: [''],
      rememberMe: [rm === undefined ? false : rm]
    });

    this.mobileLoginForm = this.formBuilder.group({
      mobile: [ null, [ Validators.required, this.mobileValidator]],
      code: [ null, [ Validators.required ]],
      authType: ['CODE', [ Validators.required ]]
    });
  }

  getCookies(objName: string) {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
      const temp = cookies[i].split('=');

      if (temp[0] === objName) {
        return decodeURI(temp[1]);
      }
    }
  }

  setCookies(objName: string, value: string) {
    document.cookie = objName + '=' + value;
  }

  gotToContent(value: string) {
    this.showContent = value;
    if (value === 'user') {
      this.mobileLoginForm.reset();

      clearInterval(this.interval);
      this.showSecond = 60;
      this.inInterval = false;
    } else if (value === 'mobile') {
      this.userLoginForm.reset();
    }
  }

  /**
   * 提交
   * @param type
   */
  submit(type: string) {
    this.isSpinning = true;
    const body = {
      username: type === 'user' ? this.userLoginForm.value.username : this.mobileLoginForm.value.mobile,
      password: type === 'user' ? this.crypto.encryptByEnAESn(this.userLoginForm.value.password) : this.crypto.encryptByEnAESn(this.mobileLoginForm.value.code)
    };
    const authType = type === 'user' ? this.userLoginForm.value.authType : this.mobileLoginForm.value.authType;

    // usercenter认证
    this.accountservice.doLogin(body, authType).subscribe(
      res => {
        if (res.status === 200) {
          // 登录成功
          if (res.message === 'true') {
            this.isSpinning = false;
            this.authService.authLogin();
            // client认证
            // this.accountservice.getClientUrl({}).subscribe(clientRes => {
            //   // 登录成功
            //   if (clientRes.status === 200) {
            //     this.isSpinning = false;
            //     this.authService.login();
            //   } else {
            //     this.isSpinning = false;
            //     console.log(clientRes);
            //     this.messageService.error('登录失败');
            //   }
            // }, error => {
            //   this.isSpinning = false;
            //   console.log(error);
            //   let message = '请求失败';
            //   if (error.error && error.error.message) {
            //     message = error.error.message;
            //     if (this.envService.production && error.error.description) {
            //       message = message + '<br />' + error.error.description;
            //     }
            //   } else {
            //     message = error.message;
            //   }
            //   this.messageService.error('登录失败：' + message);
            // });
          } else {
            this.isSpinning = false;
            this.messageService.error('登录失败');
          }
        } else {
          this.isSpinning = false;
          this.messageService.error('登录失败：' + res.message);
        }
      },
      error => {
        this.isSpinning = false;
        let message = '请求失败';
        if (error.error && error.error.message) {
          message = error.error.message;
          if (this.envService.production && error.error.description) {
            message = message + '<br />' + error.error.description;
          }
        } else {
          message = error.message;
        }
        this.messageService.error('登录失败：' + message);
      }
    );
  }

  /**
   * 获取手机验证码
   */
  getMobileCode() {
    this.interval = setInterval(() => {
      this.inInterval = true;
      this.showSecond --;
      if (this.showSecond === 0) {
        clearInterval(this.interval);
        this.showSecond = 60;
        this.inInterval = false;
      }
    }, 1000);

    if (!this.mobileLoginForm.getError('mobile', ['mobile'])) {
      this.accountservice.getMobileCode({mobile: this.mobileLoginForm.get('mobile').value, verifyCode: this.verifyCode}).subscribe(res => {
        return false;
      });
    } else {
      this.messageService.error(this.mobileLoginForm.getError('mobile', ['mobile']).info);
    }
  }

  /**
   * 获取图片验证码
   */
  getImgCode() {
    this.codeImgUrl = this.envService.host + '/usercenter/account/image_code?' + Math.random();
  }
}
