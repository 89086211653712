import {coerceBooleanProperty, coerceNumberProperty} from '@angular/cdk/coercion';

export function isBlank(value) {
  return value === null || value === undefined;
}

export function isObject(value) {
  return value !== null && typeof value === 'object';
}

export function isUndefined(value) {
  return typeof value === 'undefined';
}

export function isEmpty(value) {
  return typeof value === 'undefined' || value === null;
}

export function cloneDeep(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function copyDeep(obj) {
  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = typeof obj[key] === 'object' ? copyDeep(obj[key]) : obj[key];
    }
  }
  return result;
}

export function toBoolean(value: boolean | string): boolean {
  return coerceBooleanProperty(value);
}

export function toNumber<D>(value: number | string, fallback: D): number | D {
  return coerceNumberProperty(value, fallback);
}

/**
 * JSON字符串判断
 */
export function isJSON(str) {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
}


function padLeft(num, width, padChar) {
  padChar = padChar || ' ';
  let value = num.toString();
  while (value.length < width) {
    value = padChar + value;
  }
  return value;
}

export function formatDate(date: Date, formatString: string) {
  const month = date.getMonth() + 1,
      day = date.getDate(),
      year = date.getFullYear(),
      hours24 = date.getHours(),
      hours = (hours24 === 0 ? 12 : hours24 > 12 ? hours24 - 12 : hours24),
      meridiem = hours24 >= 12 ? 'PM' : 'AM',
      minutes = date.getMinutes(),
      seconds = date.getSeconds();

  return formatString.replace(/(MM)/g, padLeft(month, 2, '0'))
      .replace(/(M)/g, month.toString())
      .replace(/(dd)/g, padLeft(day, 2, '0'))
      .replace(/(d)/g, day.toString())
      .replace(/(yyyy)/ig, year.toString())
      .replace(/(yy)/ig, year.toString().substring(2, 4))
      .replace(/(hh)/g, padLeft(hours, 2, '0'))
      .replace(/(h)/g, hours.toString())
      .replace(/(HH)/g, padLeft(hours24, 2, '0'))
      .replace(/(H)/g, hours24.toString())
      .replace(/(mm)/g, padLeft(minutes, 2, '0'))
      .replace(/(m)/g, minutes.toString())
      .replace(/(ss)/g, padLeft(seconds, 2, '0'))
      .replace(/(s)/g, seconds.toString())
      .replace(/(tt)/g, meridiem.toLowerCase())
      .replace(/(TT)/g, meridiem);
}

export function formatTimestamp(timestamp: number, formatString: string) {
  return formatDate(new Date(timestamp), formatString);
}

export function toDateStr(timestamp: number) {
  return formatTimestamp(timestamp, 'yyyy-MM-dd');
}

export function toDateTimeStr(timestamp: number) {
  return formatTimestamp(timestamp, 'yyyy-MM-dd HH:mm');
}

export function getCookies(objName: string) {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const temp = cookies[i].split('=');

    if (temp[0] === objName) {
      return decodeURI(temp[1]);
    }
  }
}

export function setCookies(objName: string, value: string) {
  document.cookie = objName + '=' + value;
}

export function orderFrom() {
  const browser = {
    versions: function () {
      const u = navigator.userAgent, app = navigator.appVersion;
      return {         // 移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1, // IE内核
        presto: u.indexOf('Presto') > -1, // opera内核
        webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或uc浏览器
        iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, // 是否iPad
        webApp: u.indexOf('Safari') === -1 // 是否web应该程序，没有头部与底部
      };
    }(),
    // language: (navigator.browserLanguage || navigator.language).toLowerCase()
    language: (navigator.language).toLowerCase()
  };

  const ua = navigator.userAgent.toLowerCase(); // 获取判断用的对象
  if (ua.match(/MicroMessenger/i) && ua.match(/MicroMessenger/i)[0] === 'micromessenger') {
    // 在微信中打开
    return 'micromessenger';
  } else if (browser.versions.mobile) { // 判断是否是移动设备打开。browser代码在下面
    if (ua.match(/WeiBo/i) && ua.match(/WeiBo/i)[0] === 'weibo') {
      // 在新浪微博客户端打开
      return 'weibo';
    }
    if (ua.match(/QQ/i) && ua.match(/QQ/i)[0] === 'qq') {
      // 在QQ空间打开
      return 'qq';
    }
    if (browser.versions.ios) {
      // 是否在IOS浏览器打开
      return 'ios';
    }
    if (browser.versions.android) {
      // 是否在安卓浏览器打开
      return 'android';
    }
  } else {
    // 否则就是PC浏览器打开
    return 'pc';
  }
}



/**
 * 数据操作：合并对象数组，并根据主键键值（默认取主键键名键名为 'id'）去重
 * @param arr1 优先保留的数组
 * @param arr2 第二个数组，出现重复元素时,不保留其元素
 * @param keyName 主键键名。默认为 'id'
 * @return 合并、去重后的新数组
 */
export function concatArray(arr1: any[], arr2: any[], keyName: string = 'id'): Array<any> {
  const ids: any[] = [];
  arr1.forEach(item => ids.push(item[keyName]));
  const pureArr2 = arr2.filter(value => !ids.includes(value[keyName]));
  return [...arr1, ...pureArr2];
}

/**
 * 生成uuid
 */
export function generateUUID() {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }
  const uuid = 'xxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) { // xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
    // tslint:disable-next-line:no-bitwise
    const r = (d + Math.random() * 16 ) % 16 | 0;
    d = Math.floor(d / 16);
    // tslint:disable-next-line:no-bitwise
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

/**
 * 生成code
 */
export function getRandom(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

export function getCode() {
  let code = '';
  for (let i = 0; i < 6; i++) {
    const type = getRandom(2, 3);
    switch (type) {
      case 1: // 数字
        code += String.fromCharCode(getRandom(48, 57));
        break;
      case 2: // 大写字母
        code += String.fromCharCode(getRandom(65, 90));
        break;
      case 3: // 小写字母
        code += String.fromCharCode(getRandom(97, 122));
        break;
    }

  }
  return code;
}



// 复制粘贴板
export function copy(data) {
  // 提油数量
  const oilAmount = getInnerString(data, '数量=', '').toString() ||
    getInnerString(data, '数量:', '').toString() ||
    getInnerString(data, '数量：', '').toString() ||
    getInnerString(data, '数量-', '').toString() ||
    getInnerString(data, '数量，', '').toString() ||
    getInnerString(data, '数量,', '').toString() ||
    getInnerString(data, '提货数量=', '').toString() ||
    getInnerString(data, '提货数量:', '').toString() ||
    getInnerString(data, '提货数量：', '').toString() ||
    getInnerString(data, '提货数量-', '').toString() ||
    getInnerString(data, '提货数量，', '').toString() ||
    getInnerString(data, '提货数量,', '').toString() ||
    getInnerString(data, '提货量', '').toString() ||
    getInnerString(data, '提货量=', '').toString() ||
    getInnerString(data, '提货量:', '').toString() ||
    getInnerString(data, '提货量：', '').toString() ||
    getInnerString(data, '提货量-', '').toString() ||
    getInnerString(data, '提货量，', '').toString() ||
    getInnerString(data, '提货量,', '').toString() ||
    getInnerString(data, '吨数=', '').toString() ||
    getInnerString(data, '吨数:', '').toString() ||
    getInnerString(data, '吨数：', '').toString() ||
    getInnerString(data, '吨数-', '').toString() ||
    getInnerString(data, '吨数，', '').toString() ||
    getInnerString(data, '吨数,', '').toString() ||
    getInnerString(data, '提油吨数=', '').toString() ||
    getInnerString(data, '提油吨数:', '').toString() ||
    getInnerString(data, '提油吨数：', '').toString() ||
    getInnerString(data, '提油吨数-', '').toString() ||
    getInnerString(data, '提油吨数，', '').toString() ||
    getInnerString(data, '提油吨数,', '').toString() ||
    getInnerString(data, '吨位=', '').toString() ||
    getInnerString(data, '吨位:', '').toString() ||
    getInnerString(data, '吨位：', '').toString() ||
    getInnerString(data, '吨位-', '').toString() ||
    getInnerString(data, '吨位，', '').toString() ||
    getInnerString(data, '吨位,', '').toString() ||
    getInnerString(data, '装载=', '').toString() ||
    getInnerString(data, '装载:', '').toString() ||
    getInnerString(data, '装载：', '').toString() ||
    getInnerString(data, '装载，', '').toString() ||
    getInnerString(data, '装载,', '').toString() ||
    getInnerString(data, '装载-', '').toString() ||
    getInnerString(data, '数量=', '\n').toString() ||
    getInnerString(data, '数量:', '\n').toString() ||
    getInnerString(data, '数量：', '\n').toString() ||
    getInnerString(data, '数量-', '\n').toString() ||
    getInnerString(data, '数量，', '\n').toString() ||
    getInnerString(data, '数量,', '\n').toString() ||
    getInnerString(data, '提货数量=', '\n').toString() ||
    getInnerString(data, '提货数量:', '\n').toString() ||
    getInnerString(data, '提货数量：', '\n').toString() ||
    getInnerString(data, '提货数量-', '\n').toString() ||
    getInnerString(data, '提货数量，', '\n').toString() ||
    getInnerString(data, '提货数量,', '\n').toString() ||
    getInnerString(data, '提货量', '\n').toString() ||
    getInnerString(data, '提货量=', '\n').toString() ||
    getInnerString(data, '提货量:', '\n').toString() ||
    getInnerString(data, '提货量：', '\n').toString() ||
    getInnerString(data, '提货量-', '\n').toString() ||
    getInnerString(data, '提货量，', '\n').toString() ||
    getInnerString(data, '提货量,', '\n').toString() ||
    getInnerString(data, '吨数=', '\n').toString() ||
    getInnerString(data, '吨数:', '\n').toString() ||
    getInnerString(data, '吨数：', '\n').toString() ||
    getInnerString(data, '吨数-', '\n').toString() ||
    getInnerString(data, '吨数，', '\n').toString() ||
    getInnerString(data, '吨数,', '\n').toString() ||
    getInnerString(data, '提油吨数=', '\n').toString() ||
    getInnerString(data, '提油吨数:', '\n').toString() ||
    getInnerString(data, '提油吨数：', '\n').toString() ||
    getInnerString(data, '提油吨数-', '\n').toString() ||
    getInnerString(data, '提油吨数，', '\n').toString() ||
    getInnerString(data, '提油吨数,', '\n').toString() ||
    getInnerString(data, '吨位=', '\n').toString() ||
    getInnerString(data, '吨位:', '\n').toString() ||
    getInnerString(data, '吨位：', '\n').toString() ||
    getInnerString(data, '吨位-', '\n').toString() ||
    getInnerString(data, '吨位，', '\n').toString() ||
    getInnerString(data, '吨位,', '\n').toString() ||
    getInnerString(data, '装载=', '\n').toString() ||
    getInnerString(data, '装载:', '\n').toString() ||
    getInnerString(data, '装载：', '\n').toString() ||
    getInnerString(data, '装载，', '\n').toString() ||
    getInnerString(data, '装载,', '\n').toString() ||
    getInnerString(data, '装载-', '\n').toString();
  // 司机姓名
  const oilDriver = getInnerString(data, '名称=', '').toString() ||
    getInnerString(data, '名称:', '').toString() ||
    getInnerString(data, '名称：', '').toString() ||
    getInnerString(data, '名称-', '').toString() ||
    getInnerString(data, '名称，', '').toString() ||
    getInnerString(data, '名称,', '').toString() ||
    getInnerString(data, '司机=', '').toString() ||
    getInnerString(data, '司机:', '').toString() ||
    getInnerString(data, '司机：', '').toString() ||
    getInnerString(data, '司机-', '').toString() ||
    getInnerString(data, '司机，', '').toString() ||
    getInnerString(data, '司机,', '').toString() ||
    getInnerString(data, '司机姓名=', '').toString() ||
    getInnerString(data, '司机姓名:', '').toString() ||
    getInnerString(data, '司机姓名：', '').toString() ||
    getInnerString(data, '司机姓名-', '').toString() ||
    getInnerString(data, '司机姓名，', '').toString() ||
    getInnerString(data, '司机姓名,', '').toString() ||
    getInnerString(data, '司机名字', '').toString() ||
    getInnerString(data, '司机名字:', '').toString() ||
    getInnerString(data, '司机名字-', '').toString() ||
    getInnerString(data, '司机名字，', '').toString() ||
    getInnerString(data, '司机名字,', '').toString() ||
    getInnerString(data, '司机名称=', '').toString() ||
    getInnerString(data, '司机名称:', '').toString() ||
    getInnerString(data, '司机名称：', '').toString() ||
    getInnerString(data, '司机名称-', '').toString() ||
    getInnerString(data, '司机名称，', '').toString() ||
    getInnerString(data, '司机名称,', '').toString() ||
    getInnerString(data, '驾驶员=', '').toString() ||
    getInnerString(data, '驾驶员:', '').toString() ||
    getInnerString(data, '驾驶员：', '').toString() ||
    getInnerString(data, '驾驶员-', '').toString() ||
    getInnerString(data, '驾驶员，', '').toString() ||
    getInnerString(data, '驾驶员,', '').toString() ||
    getInnerString(data, '姓名=', '').toString() ||
    getInnerString(data, '姓名:', '').toString() ||
    getInnerString(data, '姓名：', '').toString() ||
    getInnerString(data, '姓名，', '').toString() ||
    getInnerString(data, '姓名-', '').toString() ||
    getInnerString(data, '姓名,', '').toString() ||
    getInnerString(data, '行使员=', '').toString() ||
    getInnerString(data, '行使员:', '').toString() ||
    getInnerString(data, '行使员：', '').toString() ||
    getInnerString(data, '行使员，', '').toString() ||
    getInnerString(data, '行使员,', '').toString() ||
    getInnerString(data, '行使员-', '').toString() ||
    getInnerString(data, '名称=', '\n').toString() ||
    getInnerString(data, '名称:', '\n').toString() ||
    getInnerString(data, '名称：', '\n').toString() ||
    getInnerString(data, '名称-', '\n').toString() ||
    getInnerString(data, '名称，', '\n').toString() ||
    getInnerString(data, '名称,', '\n').toString() ||
    getInnerString(data, '司机=', '\n').toString() ||
    getInnerString(data, '司机:', '\n').toString() ||
    getInnerString(data, '司机：', '\n').toString() ||
    getInnerString(data, '司机-', '\n').toString() ||
    getInnerString(data, '司机，', '\n').toString() ||
    getInnerString(data, '司机,', '\n').toString() ||
    getInnerString(data, '司机姓名=', '\n').toString() ||
    getInnerString(data, '司机姓名:', '\n').toString() ||
    getInnerString(data, '司机姓名：', '\n').toString() ||
    getInnerString(data, '司机姓名-', '\n').toString() ||
    getInnerString(data, '司机姓名，', '\n').toString() ||
    getInnerString(data, '司机姓名,', '\n').toString() ||
    getInnerString(data, '司机名字', '\n').toString() ||
    getInnerString(data, '司机名字:', '\n').toString() ||
    getInnerString(data, '司机名字-', '\n').toString() ||
    getInnerString(data, '司机名字，', '\n').toString() ||
    getInnerString(data, '司机名字,', '\n').toString() ||
    getInnerString(data, '司机名称=', '\n').toString() ||
    getInnerString(data, '司机名称:', '\n').toString() ||
    getInnerString(data, '司机名称：', '\n').toString() ||
    getInnerString(data, '司机名称-', '\n').toString() ||
    getInnerString(data, '司机名称，', '\n').toString() ||
    getInnerString(data, '司机名称,', '\n').toString() ||
    getInnerString(data, '驾驶员=', '\n').toString() ||
    getInnerString(data, '驾驶员:', '\n').toString() ||
    getInnerString(data, '驾驶员：', '\n').toString() ||
    getInnerString(data, '驾驶员-', '\n').toString() ||
    getInnerString(data, '驾驶员，', '\n').toString() ||
    getInnerString(data, '驾驶员,', '\n').toString() ||
    getInnerString(data, '姓名=', '\n').toString() ||
    getInnerString(data, '姓名:', '\n').toString() ||
    getInnerString(data, '姓名：', '\n').toString() ||
    getInnerString(data, '姓名，', '\n').toString() ||
    getInnerString(data, '姓名-', '\n').toString() ||
    getInnerString(data, '姓名,', '\n').toString() ||
    getInnerString(data, '行使员=', '\n').toString() ||
    getInnerString(data, '行使员:', '\n').toString() ||
    getInnerString(data, '行使员：', '\n').toString() ||
    getInnerString(data, '行使员，', '\n').toString() ||
    getInnerString(data, '行使员,', '\n').toString() ||
    getInnerString(data, '行使员-', '\n').toString();
  // 手机号码
  const oilPhone = getInnerString(data, '手机=', '').toString() ||
    getInnerString(data, '手机:', '').toString() ||
    getInnerString(data, '手机：', '').toString() ||
    getInnerString(data, '手机-', '').toString() ||
    getInnerString(data, '手机，', '').toString() ||
    getInnerString(data, '手机,', '').toString() ||
    getInnerString(data, '手机号=', '').toString() ||
    getInnerString(data, '手机号:', '').toString() ||
    getInnerString(data, '手机号：', '').toString() ||
    getInnerString(data, '手机号-', '').toString() ||
    getInnerString(data, '手机号，', '').toString() ||
    getInnerString(data, '手机号,', '').toString() ||
    getInnerString(data, '手机号码=', '').toString() ||
    getInnerString(data, '手机号码:', '').toString() ||
    getInnerString(data, '手机号码：', '').toString() ||
    getInnerString(data, '手机号码-', '').toString() ||
    getInnerString(data, '手机号码，', '').toString() ||
    getInnerString(data, '手机号码,', '').toString() ||
    getInnerString(data, '电话号=', '').toString() ||
    getInnerString(data, '电话号:', '').toString() ||
    getInnerString(data, '电话号：', '').toString() ||
    getInnerString(data, '电话号-', '').toString() ||
    getInnerString(data, '电话号，', '').toString() ||
    getInnerString(data, '电话号,', '').toString() ||
    getInnerString(data, '电话号码=', '').toString() ||
    getInnerString(data, '电话号码:', '').toString() ||
    getInnerString(data, '电话号码：', '').toString() ||
    getInnerString(data, '电话号码-', '').toString() ||
    getInnerString(data, '电话号码，', '').toString() ||
    getInnerString(data, '电话号码,', '').toString() ||
    getInnerString(data, '电话=', '').toString() ||
    getInnerString(data, '电话:', '').toString() ||
    getInnerString(data, '电话：', '').toString() ||
    getInnerString(data, '电话-', '').toString() ||
    getInnerString(data, '电话，', '').toString() ||
    getInnerString(data, '电话,', '').toString() ||
    getInnerString(data, '联系方式=', '').toString() ||
    getInnerString(data, '联系方式:', '').toString() ||
    getInnerString(data, '联系方式：', '').toString() ||
    getInnerString(data, '联系方式-', '').toString() ||
    getInnerString(data, '联系方式，', '').toString() ||
    getInnerString(data, '联系方式,', '').toString() ||
    getInnerString(data, '联系号码=', '').toString() ||
    getInnerString(data, '联系号码:', '').toString() ||
    getInnerString(data, '联系号码：', '').toString() ||
    getInnerString(data, '联系号码-', '').toString() ||
    getInnerString(data, '联系号码,', '').toString() ||
    getInnerString(data, '联系号码，', '').toString() ||
    getInnerString(data, '手机=', '\n').toString() ||
    getInnerString(data, '手机:', '\n').toString() ||
    getInnerString(data, '手机：', '\n').toString() ||
    getInnerString(data, '手机-', '\n').toString() ||
    getInnerString(data, '手机，', '\n').toString() ||
    getInnerString(data, '手机,', '\n').toString() ||
    getInnerString(data, '手机号=', '\n').toString() ||
    getInnerString(data, '手机号:', '\n').toString() ||
    getInnerString(data, '手机号：', '\n').toString() ||
    getInnerString(data, '手机号-', '\n').toString() ||
    getInnerString(data, '手机号，', '\n').toString() ||
    getInnerString(data, '手机号,', '\n').toString() ||
    getInnerString(data, '手机号码=', '\n').toString() ||
    getInnerString(data, '手机号码:', '\n').toString() ||
    getInnerString(data, '手机号码：', '\n').toString() ||
    getInnerString(data, '手机号码-', '\n').toString() ||
    getInnerString(data, '手机号码，', '\n').toString() ||
    getInnerString(data, '手机号码,', '\n').toString() ||
    getInnerString(data, '电话号=', '\n').toString() ||
    getInnerString(data, '电话号:', '\n').toString() ||
    getInnerString(data, '电话号：', '\n').toString() ||
    getInnerString(data, '电话号-', '\n').toString() ||
    getInnerString(data, '电话号，', '\n').toString() ||
    getInnerString(data, '电话号,', '\n').toString() ||
    getInnerString(data, '电话号码=', '\n').toString() ||
    getInnerString(data, '电话号码:', '\n').toString() ||
    getInnerString(data, '电话号码：', '\n').toString() ||
    getInnerString(data, '电话号码-', '\n').toString() ||
    getInnerString(data, '电话号码，', '\n').toString() ||
    getInnerString(data, '电话号码,', '\n').toString() ||
    getInnerString(data, '电话=', '\n').toString() ||
    getInnerString(data, '电话:', '\n').toString() ||
    getInnerString(data, '电话：', '\n').toString() ||
    getInnerString(data, '电话-', '\n').toString() ||
    getInnerString(data, '电话，', '\n').toString() ||
    getInnerString(data, '电话,', '\n').toString() ||
    getInnerString(data, '联系方式=', '\n').toString() ||
    getInnerString(data, '联系方式:', '\n').toString() ||
    getInnerString(data, '联系方式：', '\n').toString() ||
    getInnerString(data, '联系方式-', '\n').toString() ||
    getInnerString(data, '联系方式，', '\n').toString() ||
    getInnerString(data, '联系方式,', '\n').toString() ||
    getInnerString(data, '联系号码=', '\n').toString() ||
    getInnerString(data, '联系号码:', '\n').toString() ||
    getInnerString(data, '联系号码：', '\n').toString() ||
    getInnerString(data, '联系号码-', '\n').toString() ||
    getInnerString(data, '联系号码,', '\n').toString() ||
    getInnerString(data, '联系号码，', '\n').toString();
  // 身份证号
  const oilIDcard = getInnerString(data, '身体证=', '').toString() ||
    getInnerString(data, '身份证:', '').toString() ||
    getInnerString(data, '身份证：', '').toString() ||
    getInnerString(data, '身份证-', '').toString() ||
    getInnerString(data, '身份证，', '').toString() ||
    getInnerString(data, '身份证,', '').toString() ||
    getInnerString(data, '身份证号=', '').toString() ||
    getInnerString(data, '身份证号:', '').toString() ||
    getInnerString(data, '身份证号：', '').toString() ||
    getInnerString(data, '身份证号-', '').toString() ||
    getInnerString(data, '身份证号，', '').toString() ||
    getInnerString(data, '身份证号,', '').toString() ||
    getInnerString(data, '身份证号码=', '').toString() ||
    getInnerString(data, '身份证号码:', '').toString() ||
    getInnerString(data, '身份证号码：', '').toString() ||
    getInnerString(data, '身份证号码-', '').toString() ||
    getInnerString(data, '身份证号码，', '').toString() ||
    getInnerString(data, '身份证号码,', '').toString() ||
    getInnerString(data, '证号=', '').toString() ||
    getInnerString(data, '证号:', '').toString() ||
    getInnerString(data, '证号：', '').toString() ||
    getInnerString(data, '证号-', '').toString() ||
    getInnerString(data, '证号，', '').toString() ||
    getInnerString(data, '证号,', '').toString() ||
    getInnerString(data, '证件=', '').toString() ||
    getInnerString(data, '证件:', '').toString() ||
    getInnerString(data, '证件：', '').toString() ||
    getInnerString(data, '证件-', '').toString() ||
    getInnerString(data, '证件，', '').toString() ||
    getInnerString(data, '证件,', '').toString() ||
    getInnerString(data, '身份证件=', '').toString() ||
    getInnerString(data, '身份证件:', '').toString() ||
    getInnerString(data, '身份证件：', '').toString() ||
    getInnerString(data, '身份证件-', '').toString() ||
    getInnerString(data, '身份证件，', '').toString() ||
    getInnerString(data, '身份证件,', '').toString() ||
    getInnerString(data, '身体证=', '').toString() ||
    getInnerString(data, '身体证:', '').toString() ||
    getInnerString(data, '身体证：', '').toString() ||
    getInnerString(data, '身体证-', '').toString() ||
    getInnerString(data, '身体证,', '').toString() ||
    getInnerString(data, '身份证，', '').toString() ||
    getInnerString(data, '身体证=', '\n').toString() ||
    getInnerString(data, '身份证:', '\n').toString() ||
    getInnerString(data, '身份证：', '\n').toString() ||
    getInnerString(data, '身份证-', '\n').toString() ||
    getInnerString(data, '身份证，', '\n').toString() ||
    getInnerString(data, '身份证,', '\n').toString() ||
    getInnerString(data, '身份证号=', '\n').toString() ||
    getInnerString(data, '身份证号:', '\n').toString() ||
    getInnerString(data, '身份证号：', '\n').toString() ||
    getInnerString(data, '身份证号-', '\n').toString() ||
    getInnerString(data, '身份证号，', '\n').toString() ||
    getInnerString(data, '身份证号,', '\n').toString() ||
    getInnerString(data, '身份证号码=', '\n').toString() ||
    getInnerString(data, '身份证号码:', '\n').toString() ||
    getInnerString(data, '身份证号码：', '\n').toString() ||
    getInnerString(data, '身份证号码-', '\n').toString() ||
    getInnerString(data, '身份证号码，', '\n').toString() ||
    getInnerString(data, '身份证号码,', '\n').toString() ||
    getInnerString(data, '证号=', '\n').toString() ||
    getInnerString(data, '证号:', '\n').toString() ||
    getInnerString(data, '证号：', '\n').toString() ||
    getInnerString(data, '证号-', '\n').toString() ||
    getInnerString(data, '证号，', '\n').toString() ||
    getInnerString(data, '证号,', '\n').toString() ||
    getInnerString(data, '证件=', '\n').toString() ||
    getInnerString(data, '证件:', '\n').toString() ||
    getInnerString(data, '证件：', '\n').toString() ||
    getInnerString(data, '证件-', '\n').toString() ||
    getInnerString(data, '证件，', '\n').toString() ||
    getInnerString(data, '证件,', '\n').toString() ||
    getInnerString(data, '身份证件=', '\n').toString() ||
    getInnerString(data, '身份证件:', '\n').toString() ||
    getInnerString(data, '身份证件：', '\n').toString() ||
    getInnerString(data, '身份证件-', '\n').toString() ||
    getInnerString(data, '身份证件，', '\n').toString() ||
    getInnerString(data, '身份证件,', '\n').toString() ||
    getInnerString(data, '身体证=', '\n').toString() ||
    getInnerString(data, '身体证:', '\n').toString() ||
    getInnerString(data, '身体证：', '\n').toString() ||
    getInnerString(data, '身体证-', '\n').toString() ||
    getInnerString(data, '身体证,', '\n').toString() ||
    getInnerString(data, '身份证，', '\n').toString();
  // 车牌号
  const oilLicen = getInnerString(data, '车牌=', '').toString() ||
    getInnerString(data, '车牌:', '').toString() ||
    getInnerString(data, '车牌：', '').toString() ||
    getInnerString(data, '车牌-', '').toString() ||
    getInnerString(data, '车牌，', '').toString() ||
    getInnerString(data, '车牌,', '').toString() ||
    getInnerString(data, '车号=', '').toString() ||
    getInnerString(data, '车号:', '').toString() ||
    getInnerString(data, '车号：', '').toString() ||
    getInnerString(data, '车号-', '').toString() ||
    getInnerString(data, '车号，', '').toString() ||
    getInnerString(data, '车号,', '').toString() ||
    getInnerString(data, '牌号=', '').toString() ||
    getInnerString(data, '牌号:', '').toString() ||
    getInnerString(data, '牌号：', '').toString() ||
    getInnerString(data, '牌号-', '').toString() ||
    getInnerString(data, '牌号，', '').toString() ||
    getInnerString(data, '牌号,', '').toString() ||
    getInnerString(data, '车牌号=', '').toString() ||
    getInnerString(data, '车牌号:', '').toString() ||
    getInnerString(data, '车牌号：', '').toString() ||
    getInnerString(data, '车牌号-', '').toString() ||
    getInnerString(data, '车牌号，', '').toString() ||
    getInnerString(data, '车牌号,', '').toString() ||
    getInnerString(data, '车牌号码=', '').toString() ||
    getInnerString(data, '车牌号码:', '').toString() ||
    getInnerString(data, '车牌号码：', '').toString() ||
    getInnerString(data, '车牌号码-', '').toString() ||
    getInnerString(data, '车牌号码,', '').toString() ||
    getInnerString(data, '车牌号码，', '').toString() ||
    getInnerString(data, '车牌=', '\n').toString() ||
    getInnerString(data, '车牌:', '\n').toString() ||
    getInnerString(data, '车牌：', '\n').toString() ||
    getInnerString(data, '车牌-', '\n').toString() ||
    getInnerString(data, '车牌，', '\n').toString() ||
    getInnerString(data, '车牌,', '\n').toString() ||
    getInnerString(data, '车号=', '\n').toString() ||
    getInnerString(data, '车号:', '\n').toString() ||
    getInnerString(data, '车号：', '\n').toString() ||
    getInnerString(data, '车号-', '\n').toString() ||
    getInnerString(data, '车号，', '\n').toString() ||
    getInnerString(data, '车号,', '\n').toString() ||
    getInnerString(data, '牌号=', '\n').toString() ||
    getInnerString(data, '牌号:', '\n').toString() ||
    getInnerString(data, '牌号：', '\n').toString() ||
    getInnerString(data, '牌号-', '\n').toString() ||
    getInnerString(data, '牌号，', '\n').toString() ||
    getInnerString(data, '牌号,', '\n').toString() ||
    getInnerString(data, '车牌号=', '\n').toString() ||
    getInnerString(data, '车牌号:', '\n').toString() ||
    getInnerString(data, '车牌号：', '\n').toString() ||
    getInnerString(data, '车牌号-', '\n').toString() ||
    getInnerString(data, '车牌号，', '\n').toString() ||
    getInnerString(data, '车牌号,', '\n').toString() ||
    getInnerString(data, '车牌号码=', '\n').toString() ||
    getInnerString(data, '车牌号码:', '\n').toString() ||
    getInnerString(data, '车牌号码：', '\n').toString() ||
    getInnerString(data, '车牌号码-', '\n').toString() ||
    getInnerString(data, '车牌号码,', '\n').toString() ||
    getInnerString(data, '车牌号码，', '\n').toString();

  const oilCode = getInnerString(data, '指令编码=', '').toString() ||
    getInnerString(data, '指令编码:', '').toString() ||
    getInnerString(data, '指令编码：', '').toString() ||
    getInnerString(data, '指令编码-', '').toString() ||
    getInnerString(data, '指令编码，', '').toString() ||
    getInnerString(data, '指令编码,', '').toString() ||
    getInnerString(data, '指令号=', '').toString() ||
    getInnerString(data, '指令号:', '').toString() ||
    getInnerString(data, '指令号：', '').toString() ||
    getInnerString(data, '指令号-', '').toString() ||
    getInnerString(data, '指令号，', '').toString() ||
    getInnerString(data, '指令号,', '').toString() ||
    getInnerString(data, '提油单号=', '').toString() ||
    getInnerString(data, '提油单号:', '').toString() ||
    getInnerString(data, '提油单号：', '').toString() ||
    getInnerString(data, '提油单号-', '').toString() ||
    getInnerString(data, '提油单号，', '').toString() ||
    getInnerString(data, '提油单号,', '').toString() ||
    getInnerString(data, '指令=', '').toString() ||
    getInnerString(data, '指令:', '').toString() ||
    getInnerString(data, '指令：', '').toString() ||
    getInnerString(data, '指令-', '').toString() ||
    getInnerString(data, '指令，', '').toString() ||
    getInnerString(data, '指令,', '').toString() ||
    getInnerString(data, '编码=', '').toString() ||
    getInnerString(data, '编码:', '').toString() ||
    getInnerString(data, '编码：', '').toString() ||
    getInnerString(data, '编码-', '').toString() ||
    getInnerString(data, '编码，', '').toString() ||
    getInnerString(data, '编码,', '').toString() ||
    getInnerString(data, '编号=', '').toString() ||
    getInnerString(data, '编号:', '').toString() ||
    getInnerString(data, '编号：', '').toString() ||
    getInnerString(data, '编号-', '').toString() ||
    getInnerString(data, '编号，', '').toString() ||
    getInnerString(data, '编号,', '').toString() ||
    getInnerString(data, '指令编码=', '\n').toString() ||
    getInnerString(data, '指令编码:', '\n').toString() ||
    getInnerString(data, '指令编码：', '\n').toString() ||
    getInnerString(data, '指令编码-', '\n').toString() ||
    getInnerString(data, '指令编码，', '\n').toString() ||
    getInnerString(data, '指令编码,', '\n').toString() ||
    getInnerString(data, '指令号=', '\n').toString() ||
    getInnerString(data, '指令号:', '\n').toString() ||
    getInnerString(data, '指令号：', '\n').toString() ||
    getInnerString(data, '指令号-', '\n').toString() ||
    getInnerString(data, '指令号，', '\n').toString() ||
    getInnerString(data, '指令号,', '\n').toString() ||
    getInnerString(data, '提油单号=', '\n').toString() ||
    getInnerString(data, '提油单号:', '\n').toString() ||
    getInnerString(data, '提油单号：', '\n').toString() ||
    getInnerString(data, '提油单号-', '\n').toString() ||
    getInnerString(data, '提油单号，', '\n').toString() ||
    getInnerString(data, '提油单号,', '\n').toString() ||
    getInnerString(data, '指令=', '\n').toString() ||
    getInnerString(data, '指令:', '\n').toString() ||
    getInnerString(data, '指令：', '\n').toString() ||
    getInnerString(data, '指令-', '\n').toString() ||
    getInnerString(data, '指令，', '\n').toString() ||
    getInnerString(data, '指令,', '\n').toString() ||
    getInnerString(data, '编码=', '\n').toString() ||
    getInnerString(data, '编码:', '\n').toString() ||
    getInnerString(data, '编码：', '\n').toString() ||
    getInnerString(data, '编码-', '\n').toString() ||
    getInnerString(data, '编码，', '\n').toString() ||
    getInnerString(data, '编码,', '\n').toString() ||
    getInnerString(data, '编号=', '\n').toString() ||
    getInnerString(data, '编号:', '\n').toString() ||
    getInnerString(data, '编号：', '\n').toString() ||
    getInnerString(data, '编号-', '\n').toString() ||
    getInnerString(data, '编号，', '\n').toString() ||
    getInnerString(data, '编号,', '\n').toString();
  // 油品
  const oiltype = getInnerString(data, '油品=', '').toString() ||
    getInnerString(data, '油品:', '').toString() ||
    getInnerString(data, '油品：', '').toString() ||
    getInnerString(data, '油品-', '').toString() ||
    getInnerString(data, '油品，', '').toString() ||
    getInnerString(data, '油品,', '').toString() ||
    getInnerString(data, '品名=', '').toString() ||
    getInnerString(data, '品名:', '').toString() ||
    getInnerString(data, '品名：', '').toString() ||
    getInnerString(data, '品名-', '').toString() ||
    getInnerString(data, '品名，', '').toString() ||
    getInnerString(data, '品名,', '').toString() ||
    getInnerString(data, '品质=', '').toString() ||
    getInnerString(data, '品质:', '').toString() ||
    getInnerString(data, '品质：', '').toString() ||
    getInnerString(data, '品质-', '').toString() ||
    getInnerString(data, '品质，', '').toString() ||
    getInnerString(data, '品质,', '').toString() ||
    getInnerString(data, '油品名称,', '').toString() ||
    getInnerString(data, '油品名称=', '').toString() ||
    getInnerString(data, '油品名称:', '').toString() ||
    getInnerString(data, '油品名称：', '').toString() ||
    getInnerString(data, '油品名称-', '').toString() ||
    getInnerString(data, '油品名称，', '').toString() ||
    getInnerString(data, '油品名称,', '').toString() ||
    getInnerString(data, '油品名=', '').toString() ||
    getInnerString(data, '油品名:', '').toString() ||
    getInnerString(data, '油品名：', '').toString() ||
    getInnerString(data, '油品名-', '').toString() ||
    getInnerString(data, '油品名,', '').toString() ||
    getInnerString(data, '油品名，', '').toString() ||
    getInnerString(data, '油品=', '\n').toString() ||
    getInnerString(data, '油品:', '\n').toString() ||
    getInnerString(data, '油品：', '\n').toString() ||
    getInnerString(data, '油品-', '\n').toString() ||
    getInnerString(data, '油品，', '\n').toString() ||
    getInnerString(data, '油品,', '\n').toString() ||
    getInnerString(data, '品名=', '\n').toString() ||
    getInnerString(data, '品名:', '\n').toString() ||
    getInnerString(data, '品名：', '\n').toString() ||
    getInnerString(data, '品名-', '\n').toString() ||
    getInnerString(data, '品名，', '\n').toString() ||
    getInnerString(data, '品名,', '\n').toString() ||
    getInnerString(data, '品质=', '\n').toString() ||
    getInnerString(data, '品质:', '\n').toString() ||
    getInnerString(data, '品质：', '\n').toString() ||
    getInnerString(data, '品质-', '\n').toString() ||
    getInnerString(data, '品质，', '\n').toString() ||
    getInnerString(data, '品质,', '\n').toString() ||
    getInnerString(data, '油品名称,', '\n').toString() ||
    getInnerString(data, '油品名称=', '\n').toString() ||
    getInnerString(data, '油品名称:', '\n').toString() ||
    getInnerString(data, '油品名称：', '\n').toString() ||
    getInnerString(data, '油品名称-', '\n').toString() ||
    getInnerString(data, '油品名称，', '\n').toString() ||
    getInnerString(data, '油品名称,', '\n').toString() ||
    getInnerString(data, '油品名=', '\n').toString() ||
    getInnerString(data, '油品名:', '\n').toString() ||
    getInnerString(data, '油品名：', '\n').toString() ||
    getInnerString(data, '油品名-', '\n').toString() ||
    getInnerString(data, '油品名,', '\n').toString() ||
    getInnerString(data, '油品标号,', '\n').toString() ||
    getInnerString(data, '油品名，', '\n').toString();
  // 提油时间
  const appointmentDate = getInnerString(data, '日期=', '').toString() ||
    getInnerString(data, '日期:', '').toString() ||
    getInnerString(data, '日期：', '').toString() ||
    getInnerString(data, '日期-', '').toString() ||
    getInnerString(data, '日期，', '').toString() ||
    getInnerString(data, '日期,', '').toString() ||
    getInnerString(data, '时间', '').toString() ||
    getInnerString(data, '时间:', '').toString() ||
    getInnerString(data, '时间：', '').toString() ||
    getInnerString(data, '时间-', '').toString() ||
    getInnerString(data, '时间，', '').toString() ||
    getInnerString(data, '时间,', '').toString() ||
    getInnerString(data, '提油日期=', '').toString() ||
    getInnerString(data, '提油日期:', '').toString() ||
    getInnerString(data, '提油日期：', '').toString() ||
    getInnerString(data, '提油日期-', '').toString() ||
    getInnerString(data, '提油日期，', '').toString() ||
    getInnerString(data, '提油日期,', '').toString() ||
    getInnerString(data, '提油时间', '').toString() ||
    getInnerString(data, '提油时间:', '').toString() ||
    getInnerString(data, '提油时间：', '').toString() ||
    getInnerString(data, '提油时间-', '').toString() ||
    getInnerString(data, '提油时间，', '').toString() ||
    getInnerString(data, '提油时间,', '').toString() ||
    getInnerString(data, '日期=', '\n').toString() ||
    getInnerString(data, '日期:', '\n').toString() ||
    getInnerString(data, '日期：', '\n').toString() ||
    getInnerString(data, '日期-', '\n').toString() ||
    getInnerString(data, '日期，', '\n').toString() ||
    getInnerString(data, '日期,', '\n').toString() ||
    getInnerString(data, '时间', '\n').toString() ||
    getInnerString(data, '时间:', '\n').toString() ||
    getInnerString(data, '时间：', '\n').toString() ||
    getInnerString(data, '时间-', '\n').toString() ||
    getInnerString(data, '时间，', '\n').toString() ||
    getInnerString(data, '时间,', '\n').toString() ||
    getInnerString(data, '提油日期=', '\n').toString() ||
    getInnerString(data, '提油日期:', '\n').toString() ||
    getInnerString(data, '提油日期：', '\n').toString() ||
    getInnerString(data, '提油日期-', '\n').toString() ||
    getInnerString(data, '提油日期，', '\n').toString() ||
    getInnerString(data, '提油日期,', '\n').toString() ||
    getInnerString(data, '提油时间', '\n').toString() ||
    getInnerString(data, '提油时间:', '\n').toString() ||
    getInnerString(data, '提油时间：', '\n').toString() ||
    getInnerString(data, '提油时间-', '\n').toString() ||
    getInnerString(data, '提油时间，', '\n').toString() ||
    getInnerString(data, '提油时间,', '\n').toString();
  // 油库
  const storageName = getInnerString(data, '油库=', '').toString() ||
    getInnerString(data, '油库:', '').toString() ||
    getInnerString(data, '油库：', '').toString() ||
    getInnerString(data, '油库-', '').toString() ||
    getInnerString(data, '油库，', '').toString() ||
    getInnerString(data, '油库,', '').toString() ||
    getInnerString(data, '库=', '').toString() ||
    getInnerString(data, '库:', '').toString() ||
    getInnerString(data, '库：', '').toString() ||
    getInnerString(data, '库-', '').toString() ||
    getInnerString(data, '库，', '').toString() ||
    getInnerString(data, '库,', '').toString() ||
    getInnerString(data, '库号=', '').toString() ||
    getInnerString(data, '库号:', '').toString() ||
    getInnerString(data, '库号：', '').toString() ||
    getInnerString(data, '库号-', '').toString() ||
    getInnerString(data, '库号，', '').toString() ||
    getInnerString(data, '库号,', '').toString() ||
    getInnerString(data, '罐号=', '').toString() ||
    getInnerString(data, '罐号:', '').toString() ||
    getInnerString(data, '罐号：', '').toString() ||
    getInnerString(data, '罐号-', '').toString() ||
    getInnerString(data, '罐号，', '').toString() ||
    getInnerString(data, '罐号,', '').toString() ||
    getInnerString(data, '油罐=', '').toString() ||
    getInnerString(data, '油罐:', '').toString() ||
    getInnerString(data, '油罐：', '').toString() ||
    getInnerString(data, '油罐-', '').toString() ||
    getInnerString(data, '油罐，', '').toString() ||
    getInnerString(data, '油罐,', '').toString() ||
    getInnerString(data, '油库=', '\n').toString() ||
    getInnerString(data, '油库:', '\n').toString() ||
    getInnerString(data, '油库：', '\n').toString() ||
    getInnerString(data, '油库-', '\n').toString() ||
    getInnerString(data, '油库，', '\n').toString() ||
    getInnerString(data, '油库,', '\n').toString() ||
    getInnerString(data, '库=', '\n').toString() ||
    getInnerString(data, '库:', '\n').toString() ||
    getInnerString(data, '库：', '\n').toString() ||
    getInnerString(data, '库-', '\n').toString() ||
    getInnerString(data, '库，', '\n').toString() ||
    getInnerString(data, '库,', '\n').toString() ||
    getInnerString(data, '库号=', '\n').toString() ||
    getInnerString(data, '库号:', '\n').toString() ||
    getInnerString(data, '库号：', '\n').toString() ||
    getInnerString(data, '库号-', '\n').toString() ||
    getInnerString(data, '库号，', '\n').toString() ||
    getInnerString(data, '库号,', '\n').toString() ||
    getInnerString(data, '罐号=', '\n').toString() ||
    getInnerString(data, '罐号:', '\n').toString() ||
    getInnerString(data, '罐号：', '\n').toString() ||
    getInnerString(data, '罐号-', '\n').toString() ||
    getInnerString(data, '罐号，', '\n').toString() ||
    getInnerString(data, '罐号,', '\n').toString() ||
    getInnerString(data, '油罐=', '\n').toString() ||
    getInnerString(data, '油罐:', '\n').toString() ||
    getInnerString(data, '油罐：', '\n').toString() ||
    getInnerString(data, '油罐-', '\n').toString() ||
    getInnerString(data, '油罐，', '\n').toString() ||
    getInnerString(data, '油罐,', '\n').toString();
  // console.log(oilDriver, oilPhone, oilIDcard, oilLicen, oilCode, qNumber(oilDate))
  const beizhu = getInnerString(data, '备注:', '\n').toString() ||
    getInnerString(data, '备注：', '').toString() ||
    getInnerString(data, '备注-', '').toString() ||
    getInnerString(data, '备注，', '').toString() ||
    getInnerString(data, '备注,', '').toString() ||
    getInnerString(data, '注释:', '').toString() ||
    getInnerString(data, '注释：', '').toString() ||
    getInnerString(data, '注释-', '').toString() ||
    getInnerString(data, '注释，', '').toString() ||
    getInnerString(data, '注释,', '').toString() ||
    getInnerString(data, '备注：', '\n').toString() ||
    getInnerString(data, '备注-', '\n').toString() ||
    getInnerString(data, '备注，', '\n').toString() ||
    getInnerString(data, '备注,', '\n').toString() ||
    getInnerString(data, '注释:', '\n').toString() ||
    getInnerString(data, '注释：', '\n').toString() ||
    getInnerString(data, '注释-', '\n').toString() ||
    getInnerString(data, '注释，', '\n').toString() ||
    getInnerString(data, '注释,', '\n').toString();
  const index = getInnerString(data, '', '').toString().lastIndexOf(',');
  const note = beizhu.substring(index + 1, beizhu.lenght);
  const copyData = {
    instructionCode: oilCode || '',
    storageName: storageName || '',
    appointmentDate: appointmentDate || '',
    oilType: qNum(oiltype) || '',
    amount: qNumber(oilAmount) || '',
    licensePlate: fuhao(oilLicen) || '',
    driverName: qNum(fuhao(oilDriver)) || '',
    IDCard: qNum(zifu(oilIDcard)) || '',
    mobile: fuhao(qNumber(oilPhone)) || '',
    remark: fuhao(note) || '',
  };

  return copyData;
}


function qNumber(str) { // 全部数字
  return str.replace(/[^\d\.]/g, '').replace(/\s+/g, '');
}

function qNum(str) { // 去掉空格
  return str.replace(/\s+/g, '');
}

function fuhao(str) {
  return str.replace(/["',，.。/、\]\[【】\\n\s！!?？——_<>%;‘’；:：)《（）》(&+=`“”·*#@@]/g, '');
}

function zifu(str) {
  return str.replace(/[^a-zA-Z0-9]/g, '');
}

function getInnerString (source, prefix, postfix) {
  const regexp = new RegExp(encodeReg(prefix ? prefix : '') + '.+' + encodeReg(postfix), 'gi');
  const matches = String(source).match(regexp);
  let formatedMatches;
  if (matches) {
    formatedMatches = matches.map(value => {
      return value
        .replace(prefix, '')
        .replace(postfix, '');
    });
  }
  return formatedMatches || '';
}

// 转义影响正则的字符
function encodeReg (source) {
  return String(source).replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
}
