import {Observable, Subscription} from 'rxjs';
import {of} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

import {UcStorage} from './cacheStorage';


/**
 * 缓存策略接口，在设置和获取缓存值时进一步解析Observable和Promise的值
 */
export interface UcCacheStrategy {
  name(): string;

  match(value: any): boolean;

  set(options: { pool?: string, key: string }, value: any, storage: UcStorage): any;

  get(result: any): Object;
}

export interface UcCacheResult {
  type: string;
  data: any;
  value: any;
}

/**
 * 缓存策略实现，Observable版本
 */
export class UcRxCacheStrategy implements UcCacheStrategy {
  name() {
    return 'RxCacheStrategy';
  }

  // value是Observable对象
  match(value: any): boolean {
    return value && value.subscribe;
  }

  set(options: { pool?: string, key: string }, value: any, storage: UcStorage): Observable<any> {
    const result: UcCacheResult = {type: this.name(), data: null, value: null};
    storage.set(options, result);
    const cache$ = value.pipe(
        map(data => result.data = data),
        shareReplay(1)
    );
    result.value = cache$;
    return cache$;
  }

  get(result: any): Observable<any> {
    if (result.data) {
      return of(result.data);
    }
    return result.value;
  }
}

/**
 * 缓存策略实现，Promise版本
 */
export class UcPromiseCacheStrategy implements UcCacheStrategy {
  name() {
    return 'PromiseCacheStrategy';
  }

  // value是Promise对象
  match(value: any): boolean {
    return value && value.then;
  }

  set(options: { pool?: string, key: string }, value: any, storage: UcStorage): Promise<any> {
    const result: UcCacheResult = {type: this.name(), data: null, value: value};
    storage.set(options, result);
    return value.then(data => {
      result.data = data;
      return data;
    });
  }

  get(result: UcCacheResult): Promise<any> {
    if (result.data) {
      return Promise.resolve(result.data);
    }
    return result.value;
  }
}

/**
 * 缓存策略工厂，实现了对Observable和Promise值的缓存支持
 */
export class UcCacheStrategyFactory {
  private static factory: UcCacheStrategyFactory = new UcCacheStrategyFactory();
  private cacheStrategies: UcCacheStrategy[];

  static getInstance(): UcCacheStrategyFactory {
    return UcCacheStrategyFactory.factory;
  }

  constructor() {
    this.cacheStrategies = [new UcRxCacheStrategy(), new UcPromiseCacheStrategy()];
  }

  set(options: { pool?: string, key: string }, value: any, storage: UcStorage): Object {
    const strategy = this.cacheStrategies.find(s => s.match(value));
    if (strategy) {
      return strategy.set(options, value, storage);
    }
    storage.set(options, value);
    return value;
  }

  get(options: { pool?: string, key: string }, storage: UcStorage): Object {
    const result = storage.get(options) as any;
    if (result && result.type) {
      const strategy = this.cacheStrategies.find(s => s.name() === result.type);
      if (strategy) {
        return strategy.get(result);
      }
    }
    return result;
  }
}
