import {Injectable} from '@angular/core';

@Injectable()
export class UcSpinnerService {

  private _selector = 'uc-spinner';
  private _element: HTMLElement;

  constructor() {
    this._element = document.getElementById(this._selector);
  }

  show(): void {
    if (this._element) {
      this._element.style['display'] = 'block';
    }
  }

  hide(delay: number = 0): void {
    setTimeout(() => {
      if (this._element) {
        this._element.style['display'] = 'none';
      }
    }, delay);
  }
}
