import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UcAccountComponent} from './account.component';
import {UcLoginComponent} from './login';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {UcAccountRoutingModule} from './account.routing';
import {UcDoLoginComponent} from './doLogin';
import {AccountService} from './account.service';
import {UcClientValidComponent} from './clientValid/cientValid.component';
import {ForgetPasswordComponent, ResetPasswordComponent} from './forgetPassword/forgetPassword.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    UcAccountRoutingModule
  ],
  declarations: [
    UcAccountComponent,
    UcLoginComponent,
    UcDoLoginComponent,
    UcClientValidComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent
  ],
  providers: [AccountService],
  entryComponents: [ResetPasswordComponent]
})
export class UcAccountModule {
}
