<ngx-codemirror
  #codeEditor
  [name]="keyName"
  [(ngModel)]="codeValue"
  (ngModelChange)="OnCodeValueChange($event)"
  [options]="options"
  [autoFocus]="autoFocus"
  [preserveScrollPosition]="preserveScrollPosition"
  (focusChange)="OnFocusChange($event)"
  (cursorActivity)="OnCursorActivityChange($event)"
  (scroll)="OnScroll($event)"
></ngx-codemirror>
