// codeMirror 语言种类
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/css/css';
import 'codemirror/mode/python/python';
import 'codemirror/mode/shell/shell';
import 'codemirror/mode/r/r';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/dialog/dialog.js';
// 绑定vim
import 'codemirror/addon/dialog/dialog.js';
import 'codemirror/keymap/vim.js';
import 'codemirror/addon/search/searchcursor.js';
// 代码折叠
import 'codemirror/addon/fold/foldcode.js';
import 'codemirror/addon/fold/foldgutter.js';
import 'codemirror/addon/fold/brace-fold.js';
import 'codemirror/addon/fold/comment-fold.js';
// 括号匹配
import 'codemirror/addon/edit/matchbrackets.js';
// 行注释
import 'codemirror/addon/comment/comment.js';
// 代码检查错误
import 'codemirror/addon/lint/lint.js';
import 'codemirror/addon/lint/json-lint.js';
import 'codemirror/addon/display/autorefresh.js';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
