import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-music',
  template: `
    <audio preload="auto" id="AudioMusic" #AudioMusic>
      <source src="assets/mp3/coins.mp3" type="audio/mpeg">
      您的浏览器不支持音频播放。
    </audio>
  `
})
export class UcMusicComponent implements OnInit {
  // audio
  @ViewChild('AudioMusic', {static: true}) AudioMusic: ElementRef;
  constructor() {}

  ngOnInit() {}

  play() {
    this.AudioMusic.nativeElement.play();
  }

  pause() {
    this.AudioMusic.nativeElement.pause();
  }
}
