import {Injectable} from '@angular/core';
import {UcDataFormula, UcSourceProcess, filter, UcDataConfigProcess, UcDataConfigSource} from './ucChart.model';
import {BaseService} from './base.service';
import {UcChartComponent} from './ucChart.component';

@Injectable()
export class ProcessService {
  private aloneSymbol = ['abs', 'ceil', 'floor', 'round', 'sin', 'cos', 'tan', 'acos', 'asin', 'atan'];
  private multipleSymbol = ['add', 'subtract', 'multiply', 'divide', 'remainder', 'raise', 'max', 'min', 'pow'];
  constructor(
    private _base: BaseService
  ) {}

  /**
   * 数据操作
   */
  handleProcess(process: UcSourceProcess[], source_data_tmp) {
    process.map((proce, num) => {
      let data_row = source_data_tmp.data_row;
      // scalar 计算过程
      switch (proce.type) {
        case 'scalar':
          // 将要进行计算列的数据单独取出
          const colValue = source_data_tmp.data_col.filter(row => row.name === proce.scalarfunc['col']['value'])[0];
          let dependentType;
          let dependentValue;
          if (proce.scalarfunc.dependent) {
            dependentType = proce.scalarfunc.dependent.type;
            if (proce.scalarfunc.dependent.type === 'column') {
              dependentValue = source_data_tmp.data_col.filter(row => row.name === proce.scalarfunc.dependent.value)[0];
            } else {
              dependentValue = proce.scalarfunc.dependent.value;
            }
          }
          const symbol = proce.scalarfunc.symbol;

          const data = this.ucScalar(symbol, colValue, dependentValue, dependentType);

          source_data_tmp.data_col.push({
            name: proce.scalarfunc.colname,
            data: data
          });
          source_data_tmp.data_field.push({
            fieldName: proce.scalarfunc.colname,
            type: 'any',
            checked: true
          });

          // 列数据数组转化为JSON数据数组并赋值
          source_data_tmp.data_row = this._base.col2row(source_data_tmp.data_col, 'name', 'data');
          break;
        case 'aggregate':
          this.ucAggregate(proce, source_data_tmp);
          break;
        case 'group':
          let strFunc = proce.ortherFunc.slice(5);
          if (strFunc.indexOf('(') > -1 ) {
            strFunc = strFunc.slice(1);
          }
          if (strFunc.indexOf(')') > -1) {
            strFunc = strFunc.slice(0, -1);
          }
          const groupCol = strFunc.split(', ');

          source_data_tmp = this.ucGroup(groupCol, source_data_tmp);
          break;
        case 'filter':
          const filterFuncs = this.strToarr(proce.ortherFunc);
          filterFuncs.forEach((func) => {
            source_data_tmp.data_row = this.ucFilter(func, source_data_tmp.data_row);
            data_row = source_data_tmp.data_row;

            // JSON数据数组转化为列数据
            source_data_tmp.data_col = this._base.row2col(source_data_tmp.data_row, 'name', 'data');
          });
          break;
        case 'order':
          source_data_tmp.data_row = source_data_tmp.data_row = this.ucOrder(proce, data_row);
          break;
        case 'enum':
          // 将要进行计算列的数据单独取出
          const col_value = source_data_tmp.data_col.filter(row => row.name === proce.enumfunc.colValue)[0];
          const enumData = this.ucEnum(proce, col_value);

          source_data_tmp.data_col.push({
            name: proce.enumfunc.colname,
            data: enumData
          });
          source_data_tmp.data_field.push({
            fieldName: proce.enumfunc.colname,
            type: 'any',
            checked: true
          });

          // 列数据数组转化为JSON数据数组并赋值
          source_data_tmp.data_row = this._base.col2row(source_data_tmp.data_col, 'name', 'data');
          break;
      }
    });
  }

  generateProcesses(source_data_tmp, processes: UcDataConfigProcess[], sources: UcDataConfigSource[]) {
    processes.map((proce) => {
      let data_row = source_data_tmp.data_row;
      // scalar 计算过程
      switch (proce.type) {
        case 'join':
          const generateDataTmp = this.join(proce, sources);
          source_data_tmp.data_row = generateDataTmp.data_row;
          source_data_tmp.data_field = generateDataTmp.fields;
          source_data_tmp.data_col = generateDataTmp.data_col;
          break;
        case 'scalar':
          // 将要进行计算列的数据单独取出
          const colValue = source_data_tmp.data_col.filter(row => row.name === proce.scalarfunc['col']['value'])[0];
          let dependentType;
          let dependentValue;
          if (proce.scalarfunc.dependent) {
            dependentType = proce.scalarfunc.dependent.type;
            if (proce.scalarfunc.dependent.type === 'column') {
              dependentValue = source_data_tmp.data_col.filter(row => row.name === proce.scalarfunc.dependent.value)[0];
            } else {
              dependentValue = proce.scalarfunc.dependent.value;
            }
          }
          const symbol = proce.scalarfunc.symbol;

          const data = this.ucScalar(symbol, colValue, dependentValue, dependentType);

          source_data_tmp.data_col.push({
            name: proce.scalarfunc.colname,
            data: data
          });
          source_data_tmp.data_field.push({
            fieldName: proce.scalarfunc.colname,
            type: 'any',
            checked: true
          });

          // 列数据数组转化为JSON数据数组并赋值
          source_data_tmp.data_row = this._base.col2row(source_data_tmp.data_col, 'name', 'data');
          break;
        case 'aggregate':
          this.ucAggregate(proce, source_data_tmp);
          break;
        case 'group':
          let strFunc = proce.ortherFunc.slice(5);
          if (strFunc.indexOf('(') > -1 ) {
            strFunc = strFunc.slice(1);
          }
          if (strFunc.indexOf(')') > -1) {
            strFunc = strFunc.slice(0, -1);
          }
          const groupCol = strFunc.split(', ');

          source_data_tmp = this.ucGroup(groupCol, source_data_tmp);
          break;
        case 'filter':
          const filterFuncs = this.strToarr(proce.ortherFunc);
          filterFuncs.forEach((func) => {
            source_data_tmp.data_row = this.ucFilter(func, source_data_tmp.data_row);
            data_row = source_data_tmp.data_row;

            // JSON数据数组转化为列数据
            source_data_tmp.data_col = this._base.row2col(source_data_tmp.data_row, 'name', 'data');
          });
          break;
        case 'order':
          source_data_tmp.data_row = source_data_tmp.data_row = this.ucOrder(proce, data_row);
          break;
        case 'enum':
          // 将要进行计算列的数据单独取出
          const col_value = source_data_tmp.data_col.filter(row => row.name === proce.enumfunc.colValue)[0];
          const enumData = this.ucEnum(proce, col_value);

          source_data_tmp.data_col.push({
            name: proce.enumfunc.colname,
            data: enumData
          });
          source_data_tmp.data_field.push({
            fieldName: proce.enumfunc.colname,
            type: 'any',
            checked: true
          });

          // 列数据数组转化为JSON数据数组并赋值
          source_data_tmp.data_row = this._base.col2row(source_data_tmp.data_col, 'name', 'data');
          break;
      }
    });
  }
  /**
   * 计算colValue dependentValue symbol
   */
  ucScalar(symbol: string, colValue, dependentValue?, dependentType?: string) {
    const data = [];
    // 判断计算符号
    if (this.aloneSymbol.indexOf(symbol) > -1) {
      colValue.data.map((value, num) => {
        // 将每个数值传入计算函数并返回结果，重新赋值
        data.push(this._base.process(symbol, value));
      });
    } else if (this.multipleSymbol.indexOf(symbol) > -1) {
      // 判断第二个参数的类型
      if (dependentType === 'value' || dependentType === 'string') {
        colValue.data.map((value, num) => {
          // 将每个数值传入计算函数并返回结果，重新赋值
          data.push(this._base.process(symbol, value, dependentValue));
        });
      } else {
        colValue.data.map((value, num) => {
          // 将每个数值传入计算函数并返回结果，重新赋值
          data.push(this._base.process(symbol, value, dependentValue[num]));
        });
      }
    }

    return data;
  }

  /**
   * 聚合
   */
  ucAggregate(proce, source_data_tmp) {}

  join(proce: UcDataConfigProcess, sources: UcDataConfigSource[]) {
    const newDataTmp = {
      data_col: [],
      data_row: [],
      fields: []
    };
    // source 数据处理
    let firstSource;
    let secondeSource;
    sources.map(source => {
      if (source.id === proce.actions.firstSource.id) {
        firstSource = source;
      }

      if (source.id === proce.actions.secondSource.id) {
        secondeSource = source;
      }
    });
    const firstSource_data_tmp = this.handleSourceData(firstSource);
    const secondSource_data_tmp = this.handleSourceData(secondeSource);

    // actions 选中的field数据处理
    const firstSource_data = {
      data_col: [],
      data_row: []
    };

    const secondeSource_data = {
      data_col: [],
      data_row: [],
      fields: []
    };
    firstSource_data_tmp.data_col.map(col => {
      if (proce.actions.firstSource.fieldsName.includes(col.name)) {
        firstSource_data.data_col.push(col);
      }
    });
    secondSource_data_tmp.data_col.map(col => {
      if (proce.actions.secondSource.fieldsName.includes(col.name)) {
        secondeSource_data.data_col.push(col);
      }
    });

    firstSource_data.data_row = this._base.col2row(firstSource_data.data_col, 'name', 'data');
    secondeSource_data.data_row = this._base.col2row(secondeSource_data.data_col, 'name', 'data');

    // 操作处理
    if (proce.actions.type === 'row')  {
      if (proce.actions.mainField === '') {
        newDataTmp.data_col = [
          ...firstSource_data.data_col,
          ...secondeSource_data.data_col
        ];
        newDataTmp.fields = [
          ...proce.actions.firstSource.fields,
          ...proce.actions.secondSource.fields
        ];
        newDataTmp.data_row = this._base.col2row(newDataTmp.data_col, 'name', 'data');
      } else {

      }
    } else if (proce.actions.type === 'column') {
      newDataTmp.data_row = [
        ...firstSource_data.data_row,
        ...secondeSource_data.data_row
      ];
      newDataTmp.fields = [
        ...proce.actions.firstSource.fields
      ];
      proce.actions.secondSource.fields.forEach(field => {
        if (!proce.actions.firstSource.fieldsName.includes(field.fieldName)) {
          newDataTmp.fields = [
            ...newDataTmp.fields,
            field
          ];
        }
      });
      newDataTmp.data_col = this._base.row2col(newDataTmp.data_row, 'name', 'data');
    }

    return newDataTmp;
  }

  /**
   * 过滤
   */
  ucFilter(filterFunc: UcDataFormula, data_row) {
    const filterSymbol = filter[filter[filterFunc.symbol]];
    const f_value = filterFunc.f_value;
    const s_value = filterFunc.s_value;
    const new_row = [];
    switch (filterSymbol) {
      case 'Eq':
        data_row.forEach((row, index) => {
          if (row[f_value] === s_value) {
            new_row.push(row);
          }
        });
        break;
      case 'Gt':
        data_row.forEach((row, index) => {
          if (row[f_value] > s_value) {
            new_row.push(row);
          }
        });
        break;
      case 'Ge':
        data_row.forEach((row, index) => {
          if (row[f_value] >= s_value) {
            new_row.push(row);
          }
        });
        break;
      case 'Lt':
        data_row.forEach((row, index) => {
          if (row[f_value] < s_value) {
            new_row.push(row);
          }
        });
        break;
      case 'Le':
        data_row.forEach((row, index) => {
          if (row[f_value] <= s_value) {
            new_row.push(row);
          }
        });
        break;
      case 'Ne':
        data_row.forEach((row, index) => {
          if (row[f_value] !== s_value) {
            new_row.push(row);
          }
        });
        break;
      case 'Like':
        data_row.forEach((row, index) => {
          if (row[f_value].indexOf(s_value) > -1) {
            new_row.push(row);
          }
        });
        break;
    }

    return new_row;
  }

  /**
   * 排序
   */
  ucOrder(proce, data_row) {
    const func = proce.ortherFunc.split(' ');
    const colname = func[0];
    const order = func[1];
    switch (order) {
      case 'asc':
        data_row.sort((a, b) => {
          return a[colname] - b[colname];
        });
        break;
      case 'desc':
        data_row.sort((a, b) => {
          return b[colname] - a[colname];
        });
        break;
    }

    return data_row;
  }


  /**
   * 分组
   */
  ucGroup(group, source_data_tmp) {
    const col = group[0];
    const row = group[1];
    const defaultRow = group[2] || null;
    // 对列进行分组
    let dataCol = [];
    source_data_tmp.data_col.map(item => {
      if (item.name === col) {
        dataCol = item.data;
      }
    });
    const groupList = [];
    dataCol.map((item, number) => {
      if (groupList.indexOf(item) < 0) {
        groupList.push(item);
      }
    });
    // 取值
    const rowData = source_data_tmp.data_row;
    const newCol = groupList.map (colName => {
      const colData = [];
      rowData.map( item => {
        if (item[col] === colName) {
          const newobj = {};
          if (defaultRow) {
            newobj[defaultRow] = item[defaultRow];
          }

          newobj[colName] = item[row];
          colData.push(newobj);
        }
      });

      return  {
        name: colName,
        data: colData
      };
    });

    const newFields = newCol.map(item => {
      return {fieldName: item['name'], fieldType: 'string', checked: true};
    });

    if (defaultRow) {
      newFields.unshift({fieldName: defaultRow, fieldType: 'any', checked: true});
    }

    let newArr = [];
    for (let i = 0; i < newCol.length; i++ ) {
      newArr = this.extendObj(newArr, newCol[i]['data'], defaultRow);
    }

    source_data_tmp['data_row'] = newArr;
    source_data_tmp['data_field'] = newFields;
    return source_data_tmp;
  }


  /**
   * 枚举 {'1': '11', '2': '22'}
   *
   */
  ucEnum(proce, col_value) {
    const oldVal = col_value.data;
    // tslint:disable-next-line:no-eval
    const new_proce = eval('(' + proce.enumfunc.enumData + ')');
    const newVal = oldVal.map(item => new_proce[item] || item);
    return newVal;
  }

  private extendObj(def: Object[], opt: Object[], defaultRow: string) {
    opt.map((item, num) => {
      const i = def.find(function(_item) {
        if (defaultRow) {
          return  _item[defaultRow] === item[defaultRow];
        } else {
          return false;
        }
      });
      if (i) {
        const n = def.indexOf(i);
        Object.assign(def[n], item);
      } else {
        def.push(item);
      }
    });

    return def;
  }
  /**
   * 字符串公式转化为相应对象
   * @param {string} ortherFunc
   * @returns {Array}
   */
  strToarr(ortherFunc: string) {
    const result = [];
    const func = ortherFunc.split(' ');
    const arr_1 = [];
    func.map(function(f, index) {
      if (f.indexOf('(') > -1 ) {
        f = f.slice(1);
      } else if (f.indexOf(')') > -1) {
        f = f.slice(0, -1);
      }
      arr_1.push(f);
    });

    if (arr_1.indexOf('and') > -1) {
      const str_1 = arr_1.join();
      const arr_2 = str_1.split(',and,');
      arr_2.forEach((arr, num) => {
        const arr_3 = arr.split(',');
        result.push({
          f_value: arr_3[0],
          s_value: arr_3[2],
          symbol: arr_3[1]
        });
      });
    } else {
      result.push({
        f_value: arr_1[0],
        s_value: arr_1[2],
        symbol: arr_1[1]
      });
    }

    return result;
  }

  // source数据转化为列数据
  handleSourceData(source, component?) {
    const data_tmp = {
      data_field: [],
      data_col: [],
      data_row: [],
      data_newest: []
    };
    data_tmp.data_field = source.fields;
    let newsetDate;
    let dateKey = component && component.chartDateKey || null;
    if (!dateKey) {
      dateKey = 'timestamp';
    }

    const data = [[]];

    if (component && component.chartForType === 'line') {
      if (source.data.length > 0 && source.data[0][dateKey]) {
        const sortData = source.data.sort((a, b) => {
          const a1: any = new Date(a[dateKey]);
          const b1: any = new Date(b[dateKey]);
          return a1 - b1;
        });

        newsetDate = sortData[sortData.length - 1][dateKey];
      }
    }


    source.fields.map((col, index) => {
      data[0].push(col['fieldName']);
    });

    source.data.map((row, key) => {
      data[(key + 1)] = [];
      data[0].map((col, colNum) => {
        data[(key + 1)].push(row[col]);
      });

      if (row[dateKey] && row[dateKey] === newsetDate) {
        data_tmp.data_newest.push(row);
      }
    });


    data_tmp.data_col = data[0].map((name, index) => {
      const colName = name;
      const colData = data.slice(1).map((row, rowNum) => {
        if (row[index] != null) {
          return row[index];
        }
        return null;
      });

      return {
        name: colName,
        data: colData
      };
    });
    data_tmp.data_row = this._base.col2row(data_tmp.data_col, 'name', 'data');

    return data_tmp;
  }


  // 数据转化为画图数据格式
  dataToChart(generateData) {
    const data_tmp = {
      data_init: {},
      data_dic: [],
      data_col: [],
      data_row: [],
      data_fields: [],
      data_table_fields: [],
      data_table: []
    };
    let data = [[]];
    const fields = [];
    // data_table_fields
    generateData['fields'].map((col, index) => {
      if (col.checked) {
        fields.push(col);
        data[0].push(col['fieldName']);
        data_tmp.data_fields.push(col['fieldName']);
        data_tmp.data_table_fields.push({
          name: col['fieldName'],
          fieldName: col['fieldName'],
          fieldType: col['fieldType']
        });
      }
    });
    // data_table
    generateData.data.map((row, key) => {
      row['key'] = key;
      data_tmp.data_table.push(row);

      data[(key + 1)] = [];
      data[0].map((col, colNum) => {
        data[(key + 1)].push(row[col]);
      });
    });

    let dataIndex = -1;
    const isDate = data[0].some((col, index) => {
      const isT: boolean = this._base.isT(col) || data_tmp.data_table_fields[index]['fieldType'] === 'datetime' || data_tmp.data_table_fields[index]['fieldType'] === 'Date' || data_tmp.data_table_fields[index]['fieldType'] === 'date'; // 时间数组判断
      if (isT) {
        dataIndex = index;
      } // 获取时间数组的索引
      return isT;
    });
    if (isDate) {
      // 对时间进行升序排序
      data = [data[0]].concat(data.slice(1).sort((a, b) => {
        const a1: any = new Date(a[dataIndex]);
        const b1: any = new Date(b[dataIndex]);
        return a1 - b1;
      }));
    }
    // 获取列数据
    data_tmp.data_col = data[0].map((col, index) => {
      const colcode = this._base.QID(col || '');
      const colData: any = data.slice(1).map((row, rowNum) => {
        if (row[index] != null) {
          return row[index];
        }
        return null;
      });

      data_tmp['data_init'][colcode] = colData;

      fields.forEach((field, key) => {
        if (field.fieldName === col) {
          data_tmp['data_dic'].push({
            fieldName: col,
            fieldCode: colcode,
            fieldType: field.fieldType
          });
        }
      });

      return {
        fieldCode: colcode,
        data: colData
      };
    });
    // 获取行数据
    data_tmp.data_row = this._base.col2row(data_tmp.data_col, 'fieldCode', 'data');

    return data_tmp;
  }

  formatNumber(num) {
    if (num && num !== null && num !== undefined) {
      let decimalPart = '';
      let sign = '';
      if (num < 0) {
        sign = '-';
      }

      num = num.toString();
      if (num.indexOf('.') !== -1) {
        decimalPart = '.' + num.split('.')[1];
        // tslint:disable-next-line:radix
        num = parseInt(num.split('.')[0]);
      }

      const array = num.toString().split('');
      if (array[0] === '-') {
        sign = '-';
        array.splice(0, 1);
      }

      let index = -3;
      while (array.length + index > 0) {
        array.splice(index, 0, ',');
        index -= 4;
      }
      return sign + array.join('') + decimalPart;
    } else {
      return num;
    }
  }

}
