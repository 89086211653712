import {Injectable, Query} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {QueryModelList, ViewModel} from '../system';
import {Observable} from 'rxjs';
import {UcEnvironmentService} from '../core/common';

@Injectable()
export class AccountService {
  constructor(
    private http: HttpClient,
    private enservice: UcEnvironmentService
  ) {}

  doLogin(body, code): Observable<ViewModel<any>> {
    return this.http.post<ViewModel<any>>(this.enservice.host + '/usercenter/account/do_login', body, {headers: {authType: code}});
  }

  getMobileCode(body): Observable<ViewModel<any>> {
    return this.http.post<ViewModel<any>>(this.enservice.host + '/usercenter/account/sms', body);
  }

  resetPassword(body): Observable<ViewModel<any>> {
    return this.http.post<ViewModel<any>>(this.enservice.api.usercenter + '/account/change_password', body);
  }

  getClientUrl(body): Observable<any> {
    return this.http.post<any>(this.enservice.environment.client.login, body);
  }
}
