import {Component, OnInit} from '@angular/core';
import {UcAuthService} from '../../core/auth';

@Component({
  selector: 'app-account-do-login',
  template: `<div></div>`
})
export class UcDoLoginComponent implements OnInit {

  constructor(private authService: UcAuthService) {
  }

  ngOnInit() {
    // 尝试初次登录验证
    this.authService.login();
  }
}
