<div class="uc-login-form-wrap" style="min-height: 700px; background-color: #001529">
  <div class="uc-login-container">
<!--    <nz-spin [nzSpinning]="isSpinning" nzTip="登录中...">-->
      <div class="uc-login-form">
        <div class="carousel-header">
          <div class="uc-form-login-logo">
            <img [src]="logoUrl">
<!--            <img src="assets/images/xx_logo.png">-->
            <div class="uc-form-login-title">
              <!--              style="writing-mode: vertical-rl;"-->
              <span >厦星管理</span>
            </div>
          </div>
          <ul>
            <li [ngClass]="{active: showContent === 'user'}" (click)="gotToContent('user')"><a>账号登录</a></li>
            <li [ngClass]="{active: showContent === 'mobile'}" (click)="gotToContent('mobile')"><a>手机登录</a></li>
          </ul>
        </div>
        <div class="carousel-body">
          <div class="carousel-content" *ngIf="showContent === 'user'">
            <form nz-form [formGroup]="userLoginForm" class="login-form" (ngSubmit)="submit('user')">
              <nz-form-item>
                <nz-form-control nzErrorTip="请输入用户名!">
                  <nz-input-group nzPrefixIcon="user">
                    <input type="text" nz-input formControlName="username" placeholder="用户名/手机/邮箱"  [readOnly]="isSpinning"/>
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control nzErrorTip="请输入密码!">
                  <nz-input-group nzPrefixIcon="lock">
                    <input type="password" nz-input formControlName="password" placeholder="密码"  [readOnly]="isSpinning"/>
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control>
                  <label nz-checkbox formControlName="rememberMe" style="margin-bottom: 36px;">
                    <span>记住账号</span>
                  </label>

                  <button nz-button class="uc-btn-login" [nzType]="'primary'" [nzSize]="'large'"
                          [disabled]="!userLoginForm.valid || isSpinning">
                    <span *ngIf="isSpinning">登录中 <i nz-icon nzType="loading"></i></span>
                    <span *ngIf="!isSpinning">登录</span>
                  </button>
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>
          <div class="carousel-content"  *ngIf="showContent === 'mobile'">
            <form nz-form [formGroup]="mobileLoginForm" class="login-form" (ngSubmit)="submit('mobile')">
              <nz-form-item>
                <nz-form-control nzErrorTip="{{mobileLoginForm.getError('mobile', ['mobile'])?.info}}">
                  <nz-input-group nzPrefixIcon="mobile">
                    <input type="text" nz-input formControlName="mobile" placeholder="手机号码" [readOnly]="isSpinning">
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control nzErrorTip="请输入验证码">
                  <div nz-row>
                    <div nz-col nzSpan="12">
                      <nz-input-group>
                        <input type="text" nz-input [(ngModel)]="verifyCode" [ngModelOptions]="{standalone: true}" placeholder="输入图片验证码" [maxlength]="6" [readOnly]="isSpinning">
                      </nz-input-group>
                    </div>
                    <div nz-col nzSpan="10" nzOffset="2">
                      <div class="mobile-code-btn">
                        <a id='verifyCode-btn' type="button" (click)="getImgCode()">
                          <img id="verifyCodeImg" [src]="codeImgUrl">
                        </a>
                      </div>
                    </div>
                  </div>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control nzErrorTip="请输入验证码">
                  <div nz-row>
                    <div nz-col nzSpan="12">
                      <nz-input-group>
                        <input type="text" nz-input formControlName="code" placeholder="输入验证码" [maxlength]="6" [readOnly]="isSpinning">
                      </nz-input-group>
                    </div>
                    <div nz-col nzSpan="10" nzOffset="2">
                      <button nz-button [nzSize]="'large'" nzType="primary" (click)="getMobileCode(); false"
                              [disabled]="mobileLoginForm.getError('mobile', ['mobile']) || inInterval || !verifyCode">获取验证码（{{showSecond}}s）</button>
                    </div>
                  </div>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control>
                  <button nz-button class="uc-btn-login" [nzType]="'primary'" [nzSize]="'large'"
                          [disabled]="!mobileLoginForm.valid || isSpinning">
                    <span *ngIf="isSpinning">登录中 <i nz-icon nzType="loading"></i></span>
                    <span *ngIf="!isSpinning">登录</span>
                  </button>
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>
        </div>
        <div class="uc-login-another">
          <div class="another">
            <div class="another-line-text">
              <span class="another-line right"></span>
              <span class="another-text">其他登录方式</span>
              <span class="another-line left"></span>
            </div>
            <div class="another-login">
          <span class="wechat-logo">
              <a [href]="wechatUrl"></a>
          </span>
            </div>
          </div>
        </div>
      </div>
<!--    </nz-spin>-->
    <div class="uc-login-footer">
      <span>{{footerTitle}} ©{{year}}</span>
    </div>
  </div>
</div>
