import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {Injectable} from '@angular/core';
import {QueryModelList, QueryModelPagination, ViewModelList, ViewModelPagination} from '../system.model';
import {SharedDropdownOption, SharedScope, SharedTreeOption} from './common.model';
import {UcEnvironmentService} from '../../core/common';
import {OrganizationViewModel} from '../usercenter/organization/organization.model';

@Injectable()
export class UcCommonService {

  constructor(private http: HttpClient, private environmentService: UcEnvironmentService) {
  }

  getUserList(body): Observable<ViewModelList<any>> {
    return this.http.post<ViewModelList<any>>(this.environmentService.api.usercenter + '/user/list', body);
  }

  getUserListAll(body): Observable<ViewModelList<any>> {
    return this.http.post<ViewModelList<any>>(this.environmentService.api.usercenter + '/user/list_all', body);
  }

  getOptions(scope: string, body: {size: number, value: string}): Observable<ViewModelList<SharedDropdownOption>> {
    switch (scope) {
      case SharedScope[SharedScope.USER]:
        return this.http.post<ViewModelList<SharedDropdownOption>>(this.environmentService.api.usercenter + '/user/option', body);
      case SharedScope[SharedScope.ROLE]:
        return this.http.post<ViewModelList<SharedDropdownOption>>(this.environmentService.api.usercenter + '/role/option', body);
      case SharedScope[SharedScope.CUSTOMER]:
        return this.http.post<ViewModelList<SharedDropdownOption>>(this.environmentService.api.finance + '/trader/customer/option', body);
    }
  }

  getTreeOptions(body: {parentId: string}): Observable<ViewModelList<SharedTreeOption>> {
    return this.http.post<ViewModelList<SharedTreeOption>>(this.environmentService.api.usercenter + '/org/option', body);
  }

  getDefaultOptions(scope: string, values: string[]): Observable<ViewModelList<SharedDropdownOption>> {
    switch (scope) {
      case SharedScope[SharedScope.USER]:
        return this.http.post<ViewModelList<SharedDropdownOption>>(this.environmentService.api.usercenter + '/user/options', values);
      case SharedScope[SharedScope.ROLE]:
        return this.http.post<ViewModelList<SharedDropdownOption>>(this.environmentService.api.usercenter + '/role/options', values);
    }
  }

  getDefaultTreeOptions(keys: string[]): Observable<ViewModelList<SharedTreeOption>> {
    return this.http.post<ViewModelList<SharedTreeOption>>(this.environmentService.api.usercenter + '/org/options', keys);
  }

  /**
   * 获取组织机构列表
   */
  getOrganizationList(): Observable<ViewModelList<OrganizationViewModel>> {
    return this.http.post<ViewModelList<OrganizationViewModel>>(`${this.environmentService.api.usercenter}/org/list_all`, {});
  }

  getOrganizationListByBody(body: QueryModelPagination): Observable<ViewModelList<OrganizationViewModel>> {
    return this.http.post<ViewModelList<OrganizationViewModel>>(`${this.environmentService.api.usercenter}/org/list_find`, body);
  }

  /**
   * 获取组织机构列表
   */
  getOrganizationListFromName(orgName: string): Observable<ViewModelList<OrganizationViewModel>> {
    const body = {
      filters: [
        {key: 'orgName', type: 'Like', value: orgName},
        {key: 'deep', type: 'Eq', value: 1}
      ]
    };
    return this.http.post<ViewModelList<OrganizationViewModel>>(`${this.environmentService.api.usercenter}/org/list_find`, body);
  }

  /**
   * 获取交易日期列表
   */
  getTradingDay(body: QueryModelList) {
    return this.http.post<ViewModelList<any>>(this.environmentService.api.datasource + '/data/search/343f9b2657764bf1a22df17dc24f8010', body);
  }

  /**
   * 获取股票信息
   */
  getStockInfo(body: QueryModelList) {
    return this.http.post<ViewModelList<any>>(this.environmentService.api.datasource + '/data/search/063a5f72cba649d08f83deffbfe7fb84', body);
  }

  /**
   * 数据集数据获取
   */
  getDatasetData(datesetId: string, body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.environmentService.api.datasource + '/data/search/' + datesetId, body);
  }

  getPlaceholderSearchData(datesetId: string, body: QueryModelPagination): Observable<ViewModelPagination<any>> {
    return this.http.post<ViewModelPagination<any>>(this.environmentService.api.datasource + '/data/placeholder_search/' + datesetId, body);
  }

  /**
   * 微信解除绑定
   */
  unbindChat(body: QueryModelList) {
    return this.http.post<ViewModelList<any>>(this.environmentService.api.usercenter + '/user/unbind_wechat', body);
  }


  /**
   * 千分符
   * @param num
   */
  formatNumber(num) {
    if (num && num !== null && num !== undefined) {
      let decimalPart = '';
      let sign = '';
      if (num < 0) {
        sign = '-';
      }
      num = num.toString();
      if (num.indexOf('.') !== -1) {
        decimalPart = '.' + num.split('.')[1];
        // tslint:disable-next-line:radix
        num = parseInt(num.split('.')[0]);
      }
      const array = num.toString().split('');
      if (array[0] === '-') {
        sign = '-';
        array.splice(0, 1);
      }

      let index = -3;
      while (array.length + index > 0) {
        array.splice(index, 0, ',');
        index -= 4;
      }
      return sign + array.join('') + decimalPart;
    }
  }
}
