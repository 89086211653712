import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AccountService} from '../account.service';
import {NzMessageService, NzModalRef, NzModalService} from 'ng-zorro-antd';
import {UcEnvironmentService} from '../../core/common';

@Component({
  selector: 'app-forget-psd',
  templateUrl: './forgetPassword.html',
  styleUrls: ['./forgetPassword.scss']
})
export class ForgetPasswordComponent implements OnInit {
  mobileLoginForm: FormGroup;
  showSecond: number;
  inInterval: boolean;
  interval;
  verifyCodeUrl: string;

  mobileValidator(control: FormControl): any {
    const mobileReg =  /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/ ;
    const result = mobileReg.test(control.value);
    return result ? null : { mobile: { info: '请输入正确的手机号' } };
  }

  constructor(
    private formBuilder: FormBuilder,
    private accountservice: AccountService,
    private messageService: NzMessageService,
    private modalService: NzModalService,
    private environmentService: UcEnvironmentService
    ) {}

  ngOnInit() {
    this.verifyCodeUrl = this.environmentService.host + '/usercenter/account/image_code?' + Math.random();
    this.showSecond = 60;
    this.inInterval = false;
    this.mobileLoginForm = this.formBuilder.group({
      mobile: [ null, [ Validators.required, this.mobileValidator]],
      verifyCode: [ null, [ Validators.required ]],
      code: [ null, [ Validators.required ]]
    });
  }

  submit() {
    const modal = this.modalService.create({
      nzTitle: `<div class="uc-modal-title-wrapper">
                      <span class="uc-form-header"><i nz-icon nzType="table" nzTheme="outline"></i> 重置密码</span>
                    </div>`,
      nzContent: ResetPasswordComponent,
      nzComponentParams: {
        info: this.mobileLoginForm.value
      },
      nzMaskClosable: false,
      nzWrapClassName: 'uc-modal',
      nzWidth: 500,
      nzFooter: null
    });
    modal.afterClose.subscribe(passwordBody => {
      this.accountservice.resetPassword(passwordBody).subscribe(
        res => {
          if (res.data) {
            this.messageService.success('重置密码成功！');
          } else {
            this.messageService.error('重置密码失败！');
          }
        }
      );
    });
  }

  resetVerifyCode() {
    this.verifyCodeUrl = this.environmentService.host + '/usercenter/account/image_code?' + Math.random();
  }

  getMobileCode() {
    this.interval = setInterval(() => {
      this.inInterval = true;
      this.showSecond --;
      if (this.showSecond === 0) {
        clearInterval(this.interval);
        this.showSecond = 60;
        this.inInterval = false;
      }
    }, 1000);

    if (!this.mobileLoginForm.getError('mobile', ['mobile'])) {
      const body = {
        mobile: this.mobileLoginForm.value.mobile,
        verifyCode: this.mobileLoginForm.value.verifyCode
      };
      this.accountservice.getMobileCode(body).subscribe(res => {
          return false;
        },
        error => {
          if (error.status === 500) {
            this.messageService.error(error.message);
          }
        });
    } else {
      this.messageService.error(this.mobileLoginForm.getError('mobile', ['mobile']).info);
    }
  }
}



@Component({
  selector: 'app-count-modal',
  template: `
    <form nz-form [formGroup]="passwordForm" class="login-form" >
      <nz-form-item>
        <nz-form-control [nzErrorTip]="passwordErrorTpl">
          <nz-input-group>
            <input type="text" nz-input formControlName="password" placeholder="请输入新密码"
                   (ngModelChange)="updateConfirmValidator()">
          </nz-input-group>
          <ng-template #passwordErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              请输入新密码!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzErrorTip]="confirmErrorTpl">
          <nz-input-group>
            <input type="text" nz-input formControlName="confirmPassword" placeholder="请输再次入新密码">
          </nz-input-group>
          <ng-template #confirmErrorTpl let-control>
            <ng-container *ngIf="passwordForm.get('confirmPassword').hasError('required')">
              请再次输入密码
            </ng-container>
            <ng-container *ngIf="passwordForm.get('confirmPassword').hasError('confirm')">
              两次输入的密码不一致
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>
    <div nz-row nzType="flex" [nzJustify]="'end'">
      <div nz-col>
        <div class="uc-btn-group">
          <button nz-button nzType="default" type="button" (click)="cancel()" class="uc-btn uc-btn-sub-create">取消</button>
          <button nz-button nzType="primary" type="submit" class="uc-btn uc-btn-main-create" (click)="ok()">确定
          </button>
        </div>
      </div>
    </div>
  `
})
export class ResetPasswordComponent implements OnInit {
  @Input() info;
  passwordForm: FormGroup;

  /**
   * 校验器，校验密码和确认密码的输入是否一致
   * @param control FormControl 对象
   */
  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (control.value !== this.passwordForm.controls.password.value) {
      return {confirm: true, error: true};
    }
  }

  constructor(
    private modal: NzModalRef,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required, this.confirmationValidator]],
      mobile: [this.info.mobile],
      valiCode: [this.info.code]
    });
  }

  /**
   * 密码框输入值被修改时，同步更新确认密码框的校验状态
   */
  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.passwordForm.controls.confirmPassword.updateValueAndValidity());
  }

  ok() {
    this.modal.destroy(this.passwordForm.value);
  }

  cancel() {
    this.modal.destroy();
  }
}
