import {Injectable} from '@angular/core';
import {UcAuthService, UcUser} from '../auth';
import {UcCacheService, UcStorageType} from '../cache';
import {UcEnvironmentService} from './environment.service';
import {UcHttpService} from '../http';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class CurrentCompanyService {
  private static COMPANY_POOL_KEY = '__companyCachePool__';
  private static COMPANY_KEY = 'currentCompany';

  private storageType: UcStorageType;
  private _currentCompany: TraderCompanyViewModel;

  currentUser: UcUser;
  orgId: string;


  constructor(private environmentService: UcEnvironmentService,
              private httpService: UcHttpService,
              private authService: UcAuthService,
              private cacheService: UcCacheService,
              private http: HttpClient) {
    this.storageType = UcStorageType.LocalStorage;
  }

  private getCache<T>(key: string): T {
    return this.cacheService.get<T>({
      pool: CurrentCompanyService.COMPANY_POOL_KEY,
      key: key,
      storageType: this.storageType
    });
  }

  private setCache(key: string, obj: Object) {
    this.cacheService.set({
      pool: CurrentCompanyService.COMPANY_POOL_KEY,
      key: key,
      storageType: this.storageType
    }, obj);
  }

  set currentCompany(company: TraderCompanyViewModel) {
    this._currentCompany = company;
    this.setCache(CurrentCompanyService.COMPANY_KEY, company);
  }

  get currentCompany(): TraderCompanyViewModel {
    if (this._currentCompany) {
      return this._currentCompany;
    }
    return this.getCache(CurrentCompanyService.COMPANY_KEY);
  }

  resetCompany() {
    this.currentUser = this.authService.currentUser;
    this.orgId = this.currentUser.mainOrgPath ? this.currentUser.mainOrgPath.split('/')[1] : '';
    this.getOrgCompany(this.orgId);
  }

  getOrgCompany(orgId: string) {
    if (orgId && orgId !== '') {
      this.companyGet(orgId).subscribe(res => {
        if (res.status === 200) {
          this.currentCompany = res.data;
        }
      });
    }
  }


  /**
   * 查询单个交易商的详细信息
   * @param id 被查询的交易商的 id
   */
  companyGet(id: string): Observable<ViewModel<TraderCompanyViewModel>> {
    return this.http.post<ViewModel<TraderCompanyViewModel>>(this.environmentService.api.finance + `/trader/company/get/${id}`, null);
  }
}

export interface MessageModel {
  status: number;
  code?: number;
  message?: string;
  description?: string;
}

export interface ViewModel<T> extends MessageModel {
  data: T;
}

interface TraderCompanyModelBase {
  id?: string;
  // 关联用户中心的组织机构 id
  orgId?: string;
  name?: string;
  description?: string;
  createTime?: any;
  updateTime?: any;
}

export interface TraderCompanyModel extends TraderCompanyModelBase {
  stockBusiness?: boolean; // 股票公司
  futuresBusiness?: boolean; // 期货公司
  brokerBusiness?: boolean; // 券商渠道
  marginBusiness?: boolean; // 融券管理
  bondBusiness: boolean; // 券源管理
  brokerIds?: string[]; // 股票-关联证券公司
  costRates?: number[]; // 证券-关联券息（已弃用）
  teamIds?: string[];
  traderIds?: string[];
  marginSystem?: boolean; // 是否为融券流程配置
  // 通道券商配置
  brokerBasicFee?: number; // 通道基本费率
  brokerFees?: {id: string, name: string, value: number}[]; // 对不同借入机构的费率
  // 借入机构配置
  traderSpecialCompmany?: boolean; // 是否为特殊T0机构
  traderBasicFee?: number; // 融券基本费率
  traderOutFees?: {id: string, name: string, value: number[]}[]; // 对不同通道券商的费率
  traderInFees?: {id: string, name: string, value: number[]}[]; // 对不同券源机构的费率
  // 券源机构配置
  fundBasicFee?: number; // 券源基本费率
  fundStrictAssetProcess?: boolean; // 是否严格管理券源流程
  fundFees?: {id: string, name: string, value: number}[]; // 对不同借入机构的费率
}

export interface TraderCompanyViewModel extends TraderCompanyModel {
  ids?: string[];
  teams?: any[];
  traders?: any[];
  brokers?: any[]; // borkerIds
}
