import {Routes, RouterModule} from '@angular/router';
import {SystemComponent} from './system.component';
import {NgModule} from '@angular/core';
import {UcAuthentication} from '../core/auth';

const routes: Routes = [
  {
    path: 'system',
    component: SystemComponent,
    children: [
      {path: '', redirectTo: 'index', pathMatch: 'full'},
      {
        path: 'index',
        loadChildren: () => import('./index/index.module').then(m => m.IndexModule)
      },
      {
        path: 'personnel',
        loadChildren: () => import('./personnel/personnel.module').then(m => m.PersonnelModule)
      },
      {
        path: 'finance',
        loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule)
      },
      {
        path: 'accounts',
        loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule)
      },
      {
        path: 'bill',
        loadChildren: () => import('./bill/bill.module').then(m => m.BillModule)
      },
      {
        path: 'oiltrade',
        loadChildren: () => import('./oiltrade/oiltrade.module').then(m => m.OiltradeModule)
      },
      {
        path: 'collaboration',
        loadChildren: () => import('./collaboration/collaboration.module').then(m => m.CollaborationModule)
      },
      {
        path: 'datasource',
        loadChildren: () => import('./datasource/datasource.module').then(m => m.DataSourceModule)
      },
      {
        path: 'analysis',
        loadChildren: () => import('./analysis/analysis.module').then(m => m.AnalysisModule)
      },
      {
        path: 'taskcenter',
        loadChildren: () => import('./taskcenter/taskcenter.module').then(m => m.TaskCenterModule)
      },
      {
        path: 'visualization',
        loadChildren: () => import('./visualization/visualization.module').then(m => m.VisualizationModule)
      },
      {
        path: 'usercenter',
        loadChildren: () => import('./usercenter/usercenter.module').then(m => m.UserCenterModule)
      }
    ],
    canActivate: [UcAuthentication]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SystemRoutingModule {
}
