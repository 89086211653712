import {environment} from '../../../environments/environment';

export class UcEnvironmentService {

  get environment() {
    return environment;
  }

  set client(origin) {
    const clients = environment.client;
    // tslint:disable-next-line:forin
    for (const i in clients) {
      if (!clients[i].startsWith('http') && !clients[i].startsWith('https')) {
        clients[i] = origin + clients[i];
      }
    }

    environment.client = clients;
  }

  set auth(origin) {
    const auths = environment.auth;
    // tslint:disable-next-line:forin
    for (const i in auths) {
      if (!auths[i].startsWith('http') && !auths[i].startsWith('https')) {
        auths[i] = origin + auths[i];
      }
    }

    environment.auth = auths;
  }

  set api(origin) {
    const apis = environment.api;
    // tslint:disable-next-line:forin
    for (const i in apis) {
      if (!apis[i].startsWith('http') && !apis[i].startsWith('https')) {
        apis[i] = origin + apis[i];
      }
    }

    environment.api = apis;
  }

  get api() {
    return environment.api;
  }


  set ws(origin) {
    const wsUrl = environment.ws;
    // tslint:disable-next-line:forin
    for (const i in wsUrl) {
      if (!wsUrl[i].startsWith('ws') && !wsUrl[i].startsWith('wss')) {
        wsUrl[i] = origin + wsUrl[i];
      }
    }

    environment.ws = wsUrl;
  }

  get ws() {
    return environment.ws;
  }

  set host(host: string) {
    environment.host = host;
  }

  get host(): string {
    return environment.host;
  }

  set protocol(str: string) {
    environment.protocol = str;
  }

  get protocol(): string {
    return environment.protocol;
  }

  get production(): boolean {
    return environment.production;
  }

  /**
   * 所有配置的完整URL必须是完整的包含：协议+域名（http://test.datatowisdom.cn/...）
   */
  setOrigin() {
    const origin = window.location.origin;
    const host = window.location.host;
    const protocol = window.location.protocol;
    const wsProtocol = protocol === 'https:' ? 'wss://' : 'ws://';

    if (!this.host ||  this.host === '') {
      this.host = origin;
    }

    if (!this.protocol || this.protocol === '') {
      this.protocol = protocol + '//';
    }

    this.client = origin;
    this.auth = origin;
    this.api = origin;
    this.ws = wsProtocol + host;
  }
}
