import {Injectable} from '@angular/core';
import {UcStorageType, UcMemoryStorage, UcStorage, UcWebStorage, DEFAULT_CACHE_POOL_KEY} from './cacheStorage';
import {UcCacheStrategyFactory} from './cacheStrategy';


/**
 * 缓存服务
 */
@Injectable()
export class UcCacheService {
  private storages: Map<Object, UcStorage>;
  private defaultStorageType: UcStorageType = UcStorageType.Memory;

  constructor() {
    this.setup();
  }

  setDefaultStorageType(storageType: UcStorageType): void {
    this.defaultStorageType = storageType;
  }

  getAll({pool = DEFAULT_CACHE_POOL_KEY, storageType = this.defaultStorageType}: { pool?: string, storageType?: UcStorageType }): any {
    const storage: UcStorage = <UcStorage> this.storages.get(storageType);
    return storage.getAll(pool);
  }

  set({pool, key, storageType = this.defaultStorageType}: { pool?: string, key: string, storageType?: UcStorageType },
      value: Object): Object {
    const storage: UcStorage = <UcStorage> this.storages.get(storageType);
    return UcCacheStrategyFactory.getInstance().set({pool, key}, value, storage);
  }

  private _get({pool, key, storageType = this.defaultStorageType}: { pool?: string, key: string, storageType?: UcStorageType }): Object {
    const storage: UcStorage = <UcStorage> this.storages.get(storageType);
    return UcCacheStrategyFactory.getInstance().get({pool, key}, storage);
  }

  get<T>({pool, key, storageType = this.defaultStorageType}: { pool?: string, key: string, storageType?: UcStorageType }): T {
    const value = this._get({pool, key, storageType});
    return value as T;
  }

  remove({pool, key, storageType = this.defaultStorageType}: { pool?: string, key: string, storageType?: UcStorageType }) {
    const storage: UcStorage = <UcStorage> this.storages.get(storageType);
    return storage.remove({pool, key});
  }

  removeAll({pool = DEFAULT_CACHE_POOL_KEY, storageType = this.defaultStorageType}: { pool?: string, storageType?: UcStorageType }) {
    const storage: UcStorage = <UcStorage> this.storages.get(storageType);
    return storage.removeAll(pool);
  }

  destroy({storageType = this.defaultStorageType}: { storageType?: UcStorageType }) {
    return (<UcStorage> this.storages.get(storageType)).destroy();
  }

  private setup() {
    this.storages = new Map<String, UcStorage>();
    const memoryStorage = new UcMemoryStorage();
    this.storages.set(UcStorageType.Memory, memoryStorage)
        .set(UcStorageType.SessionStorage, window ? new UcWebStorage(window.sessionStorage) : memoryStorage)
        .set(UcStorageType.LocalStorage, window ? new UcWebStorage(window.localStorage) : memoryStorage);
  }
}

/**
 * 缓存服务工厂，实现服务单例模式
 */
export class UcCacheFactory {
  private static cacheService: UcCacheService = new UcCacheService();

  static getCacheService(): UcCacheService {
    return UcCacheFactory.cacheService;
  }
}
