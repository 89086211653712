import {DEFAULT_CACHE_POOL_KEY, UcStorageType} from './cacheStorage';
import {UcCacheFactory} from './cache.service';

/**
 * 缓存装饰器
 */
export function Cacheable(
    {pool = DEFAULT_CACHE_POOL_KEY, key, storageType = UcStorageType.Memory}:
        { pool?: string, key?: string, storageType?: UcStorageType } = {}) {

  const cacheService = UcCacheFactory.getCacheService();
  const getKey = (target: any, method: string, args: Object[]) => {
    const prefix = key || `${target.constructor.name}.${method}`;
    return `${prefix}:${args.join('-')}`;
  };

  return function (target: any, propertyKey: string, descriptor: any) {
    const method = descriptor.value;

    // 代理真实方法
    const proxy = function (...args) {
      const cacheKey = getKey(target, propertyKey, args || []);
      const data = cacheService.get({pool: pool, key: cacheKey, storageType: storageType});
      if (data) {
        return data;
      }
      const result = method.apply(this, args || []);
      return cacheService.set({pool: pool, key: cacheKey, storageType: storageType}, result);
    };

    // TODO: 无法清理缓存
    (<any>proxy).cacheEvict = function () {
      cacheService.remove({pool, key});
    };

    // 返回一个descriptor
    return {
      value: proxy
    };
  };
}
