import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import {NgModule} from '@angular/core';
import {SelectivePreloadingStrategy} from './core/common';

const routes: Routes = [
  {path: '', redirectTo: 'account', pathMatch: 'full'},
  {path: '**', redirectTo: 'system/index', pathMatch: 'full'}
];

const config: ExtraOptions = {
  useHash: false,
  preloadingStrategy: SelectivePreloadingStrategy,
  enableTracing: false
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

