import {Component, OnInit} from '@angular/core';
import {orderFrom, UcEnvironmentService} from '../../core/common';
import {setCookies} from '../../core/common';
import {AccountService} from '../account.service';

@Component({
  selector: 'app-client-valid',
  template: ''
})
export class UcClientValidComponent implements OnInit {
  order: string;

  constructor(private envService: UcEnvironmentService,
              private accountService: AccountService) {
  }

  ngOnInit() {
    this.order = orderFrom();
    setCookies('order', this.order);

    // 跳转到client登录，完成后前端登录
    window.location.href = this.envService.environment.client.login + '?original_uri=' + window.location.protocol + '//' +
      window.location.host + this.envService.environment.baseUrl + 'account/login&loginType=' + this.order;
  }
}
