export const DEFAULT_CACHE_POOL_KEY = 'defaultCachePool';

/**
 * 缓存类型
 */

export enum UcStorageType {
  Memory,
  LocalStorage,
  SessionStorage
}

/**
 * 缓存接口
 */
export interface UcStorage {
  getAll(pool: string): any;

  get(options: { pool?: string, key: string }): Object;

  set(options: { pool?: string, key: string }, value: Object): Object;

  remove(options: { pool?: string, key: string });

  removeAll(pool: string);

  destroy();
}

/**
 *
 * 缓存实现，基于WebStorage版本，json字符串
 */
export class UcWebStorage implements UcStorage {
  constructor(private webStorage: Storage) {
  }

  private saveAll(pool: string = DEFAULT_CACHE_POOL_KEY, storage) {
    this.webStorage.setItem(pool, JSON.stringify(storage));
  }

  getAll(pool: string = DEFAULT_CACHE_POOL_KEY) {
    const storage = this.webStorage.getItem(pool);
    return storage ? JSON.parse(storage) : {};
  }

  get({pool = DEFAULT_CACHE_POOL_KEY, key}: { pool?: string, key: string }): Object {
    const storage = this.getAll(pool);
    return storage[key];
  }

  set({pool = DEFAULT_CACHE_POOL_KEY, key}: { pool?: string, key: string }, value: Object): Object {
    const storage = this.getAll(pool);
    storage[key] = value;
    this.saveAll(pool, storage);
    return value;
  }

  remove({pool = DEFAULT_CACHE_POOL_KEY, key}: { pool?: string, key: string }) {
    this.set({pool, key}, null);
  }

  removeAll(pool: string = DEFAULT_CACHE_POOL_KEY) {
    this.webStorage.removeItem(pool);
  }

  destroy() {
    this.webStorage.clear();
  }
}

/**
 * 缓存实现，基于Map版本
 */
export class UcMemoryStorage implements UcStorage {
  private storage: Map<string, Map<string, Object>>;

  constructor() {
    this.storage = new Map<string, Map<string, Object>>();
  }

  getAll(pool: string = DEFAULT_CACHE_POOL_KEY): any {
    return this.storage.has(pool) ? this.storage.get(pool) : new Map<string, Object>();
  }

  get({pool = DEFAULT_CACHE_POOL_KEY, key}: { pool?: string, key: string }): Object {
    const storage = this.getAll(pool);
    return storage.has(key) ? storage.get(key) : null;
  }

  set({pool = DEFAULT_CACHE_POOL_KEY, key}: { pool?: string, key: string }, value: Object): Object {
    if (!this.storage.has(pool)) {
      this.storage.set(pool, new Map<string, Object>());
    }
    (this.storage.get(pool) as any).set(key, value);
    return value;
  }

  remove({pool = DEFAULT_CACHE_POOL_KEY, key}: { pool?: string, key: string }) {
    const poolStorage = this.storage.get(pool);
    if (poolStorage) {
      poolStorage.delete(key);
    }
  }

  removeAll(pool: string = DEFAULT_CACHE_POOL_KEY) {
    this.storage.delete(pool);
  }

  destroy() {
    this.storage = new Map<string, Map<string, Object>>();
  }
}
