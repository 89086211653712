import {ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {UcCacheModule} from '../cache/cache.module';
import {UcAuthConfig} from './authConfig';
import {UcAuthService} from './auth.service';
import {UcAuthentication} from './authAuthentication';
import {UcAuthorization} from './authAuthorization';
import {UcHttpModule} from '../http';

@NgModule({
  imports: [
    UcCacheModule,
    UcHttpModule,
  ],
  declarations: [],
  exports: [
    UcCacheModule
  ],
  providers: [
    UcAuthService,
    UcAuthentication,
    UcAuthorization,
    UcAuthConfig
  ]
})
export class UcAuthModule {

  constructor(@Optional() parentModule: UcCacheModule) {
    if (!parentModule) {
      throw Error('Please import UcCacheModule');
    }
  }
}
