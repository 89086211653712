import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, PRIMARY_OUTLET, ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {UcAuthService} from './auth.service';
import {NzMessageService} from 'ng-zorro-antd';
import {map} from 'rxjs/operators';
import { MenusListService } from 'src/app/system/menusList.service';

@Injectable()
export class UcAuthorization implements CanActivate {

  constructor(
    private authService: UcAuthService,
    private router: Router,
    private messageService: NzMessageService,
    private menusService: MenusListService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const roles = this.getItems(route.root, this.menusService.menuItems);
    const roleList = roles.map(r => {
      return r.roleCode;
    });
    return this.authService.isAuthorized(roleList).pipe(
        map(value => {
          if (value) {
            return true;
          } else {
            // 前端验证成功后，跳转到上一次未验证页面
            let firstPath = '/system/index';
            if (this.menusService.menuItems && this.menusService.menuItems.length > 0) {
              if (this.menusService.menuItems[0].children && this.menusService.menuItems[0].children.length > 0) {
                firstPath = '/system/' + this.menusService.menuItems[0].path + '/' + this.menusService.menuItems[0].children[0].path;
              } else {
                firstPath = '/system/' + this.menusService.menuItems[0].path;
              }
            }
            this.router.navigate([firstPath]).then();
            // this.messageService.warning('未授权访问！');
            // this.router.navigate(['system/index']).then();
            return false;
          }
        })
    );
  }

  private getItems(route: ActivatedRouteSnapshot, configArray: any[], roles: any[] = [], url: string = ''): any[] {
    const children: ActivatedRouteSnapshot[] = route.children;
    if (!(children && children.length > 0) || !(configArray && configArray.length > 0)) {
      return roles;
    }

    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      let config = null;
      const routeUrl: string = child.url.map(segment => segment.path).join('/');
      if (routeUrl) {
        url += `/${routeUrl}`;
        config = configArray.find(x => url.endsWith(x.path));
      }
      if (!config) {
        return this.getItems(child, configArray, roles, url);
      }

      if (config.roles) {
        roles = config.roles;
      }
      return this.getItems(child, config.children, roles, url);
    }
    return roles;
  }
}
