import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'myslice'})
export class MyslicePipe implements PipeTransform {
    transform(value: any): any {
        if (value === '华福证券') {
            return '华福证券-4.7%';
          } else if (value === '兴业证券') {
            return '兴业证券-5.0%';
          } else if (value === '浙商证券') {
            return '浙商证券-5.0%';
          } else if (value === '东方证券') {
            return '东方证券-5.5%';
          } else if (value === '中信建投') {
            return '中信建投-5.5%';
          } else if (value === '银河证券') {
            return '银河证券-6.5%';
          }
    }
}
