import {ModalButtonOptions} from 'ng-zorro-antd';

/**
 * 回调指示的类型
 */
export enum FrontCallbackResultType {
  success,
  refresh,
  cancel,
  error
}

/**
 * 回调处理函数
 */
export type FrontCallBackHandler = (res: FrontCallbackResultType) => void;

/**
 * 模态框配置对象的接口
 */
export interface FrontModalOptModel {
  titleText: string;
  width: number;
  content: any;
  iconOpt: ModalIconOpt;
  footer: ModalButtonOptions[];
  onClose?: FrontCallBackHandler;
  maskClosable?: boolean;
  nzClosable?: boolean;
}

/**
 * 审批状态：审核中 checking | 已拒绝 denied | 已接受 approved | 已出借 lent | 已分配 assigned | 已过期：expired
 */
export enum ApprovalStatus {
  checking,
  denied,
  approved,
  lent,
  assigned,
  expired,
}
/**
 * nz-icon 组件的属性接口，用于模态框标题渲染
 */
export interface ModalIconOpt {
  nzType: string;
  nzTheme: 'fill' | 'outline' | 'twotone';
  spin?: boolean;
  twoToneColor?: string;
  iconfont?: string;
}



// ApprovalStatus Models

export interface TableStatus {
  allChecked?: boolean;
  indeterminate?: boolean;
  isLoading?: boolean;
  pageIndex?: number;
  pageSize?: number;
  totalItems?: number;
  data: any[];
  fields?: any[];
  reload?: boolean;
}

export interface TabStatus {
  title: string;
}

export interface TabTableStatus extends TabStatus, TableStatus {
}


// Response Models

export interface MessageModel {
  status: number;
  code?: number;
  message?: string;
  description?: string;
}

export interface ViewModel<T> extends MessageModel {
  data: T;
}

export interface ViewModelList<T> extends MessageModel {
  data: T[];
}

export interface ViewModelPagination<T> extends ViewModelList<T> {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface Entity {
  id: string;
  enabled?: boolean;
  system?: boolean;
  createUser?: string;
  createTime?: number;
  updateUser?: string;
  updateTime?: number;
}

export interface SharedEntity {
  owner: string;
  global: boolean;
  shared: boolean;
  sharedScope: string;
  accessOrgPaths?: string;
  sharedTime?: string;
  sharedUser?: string;
  accessUserSet?: string[];
  accessRoleSet?: string[];
  accessOrgPathSet?: string[];
}

// Request Models
export enum FilterType {
  Eq,
  Gt,
  Ge,
  Lt,
  Le,
  Like,
  In,
  Or,
  And,
  Not,
  Ne,
  Exists,
  IsNull,
  IsNotNull
}

export interface Filter {
  key?: string;
  type?: string;
  value?: string | boolean | number | Filter | Filter[];
  filters?: Filter[];
  otherValue?: string;
}


export interface Order {
  key: string;
  ascending: boolean;
}

export interface Field {
  key: string;
  alias?: string;
}


export interface Group {
  keys: string[];
  fields: Field[]; // 分组一定会定义字段
}

/**
 * 内部属性是可选的，先解析filter和order，如果为空则解析filters和orders
 * 先解析group，如果为空则解析fields, 否则fields取group内的fields
 * 先过滤和排序，最后分组
 */
export interface QueryModelList {
  filter?: Filter;
  sort?: Order;
  filters?: Filter[]; // 只支持and
  sorts?: Order[];
  filtersType?: string; // Or、And、Not  默认：And
  fields?: Field[];
  group?: Group;
  size?: number; // 取前size条数据
  [name: string]: any;
}


export interface QueryModelPagination extends QueryModelList {
  pageIndex?: number;
  pageSize?: number;
}
