import {HttpHeaders, HttpParams, HttpClient} from '@angular/common/http';
import {isEmpty, isObject, isUndefined} from '../common';


/**
 * http请求类的父类，由子类提供httpClient
 */
export class UcHttp {
  constructor(protected http: HttpClient) {
  }

  protected baseUrl: string;
  protected defaultHeaders: Object;
}


/**
 * http请求类的装饰器，为类增加一个baseUrl属性，并赋值
 */
export function BaseUrl(baseUrl: string) {
  return function <TFunction extends Function>(target: TFunction): TFunction {
    target.prototype.baseUrl = baseUrl;
    return target;
  };
}

/**
 * http请求类的装饰器，为类增加一个defaultHeaders属性，并赋值
 */
export function DefaultHeaders(headers: any) {
  return function <TFunction extends Function>(target: TFunction): TFunction {
    target.prototype.defaultHeaders = headers;
    return target;
  };
}

/**
 * http请求类中方法参数装饰器工厂类型
 */
enum ParamType {
  Path = 'Path',
  QueryString = 'QueryString',
  Body = 'Body',
  Header = 'Header'
}

/**
 * http请求类中方法参数装饰器工厂的工厂，返回不同类型的工厂，再返回不同类型的装饰器
 */
function paramBuilder(paramType: ParamType, optional = false) {
  // key是准备保存的变量名称，optional为false则key值不能为空
  return function (key?: string) {
    if (!optional && !key) {
      throw new Error(`${paramType} Key is required!`);
    }
    return function (target: UcHttp, propertyKey: string, parameterIndex: number) {
      const metadataKey = `${propertyKey}_${paramType}`;
      const paramObj = {
        key: key,
        parameterIndex: parameterIndex
      };
      if (Array.isArray(target[metadataKey])) {
        target[metadataKey].push(paramObj);
      } else {
        target[metadataKey] = [paramObj];
      }
    };
  };
}


/**
 * http请求类中方法参数装饰器，将参数相关信息保存，用于加入请求url中
 */
export const Path = paramBuilder(ParamType.Path);
/**
 * http请求类中方法参数装饰器，将参数相关信息保存，用于加入请求QueryString中
 */
export const QueryString = paramBuilder(ParamType.QueryString, true);
/**
 * http请求类中方法参数装饰器，将参数相关信息保存，用于加入请求体中
 */
export const Body = paramBuilder(ParamType.Body)('Body');
/**
 * http请求类中方法参数装饰器，将参数相关信息保存，用于加入请求头
 */
export const Header = paramBuilder(ParamType.Header);

/**
 * http请求类中方法装饰器，用于加入请求头
 */
export function Headers(headers: any) {
  return function (target: UcHttp, propertyKey: string, descriptor: any) {
    descriptor.headers = headers;
    return descriptor;
  };
}

/**
 * http请求类中方法装饰器，用于加入请求参数
 */
export function RequestOptions(options: {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body' | 'response' | string,
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean,
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
  withCredentials?: boolean
}) {
  return function (target: UcHttp, propertyKey: string, descriptor: any) {
    options.observe = options.observe || 'body';
    options.reportProgress = isUndefined(options.reportProgress) ? false : options.reportProgress;
    options.responseType = options.responseType || 'json';
    options.withCredentials = isUndefined(options.withCredentials) ? false : options.withCredentials;

    descriptor.requestOptions = options;
    return descriptor;
  };
}

/**
 * http请求类中方法装饰器工厂类型
 */
enum MethodType {
  Get = 'Get',
  Post = 'Post',
  Options = 'Options',
  Delete = 'Delete',
  Put = 'Put',
  Patch = 'Patch',
  Head = 'head',
  Jsonp = 'Jsonp'
}

function handleBody(bodyParams, args) {
  if (bodyParams) {
    return args[bodyParams[0].parameterIndex];
  }
  return '';
}

function handlePath(pathParams, args, url) {
  if (pathParams) {
    for (const i in pathParams) {
      if (pathParams.hasOwnProperty(i)) {
        return url.replace(`:${pathParams[i].key}`, encodeURIComponent(args[pathParams[i].parameterIndex]));
      }
    }
  }
  return url;
}

function handleQueryString(queryParams, args) {
  let params = new HttpParams();
  if (queryParams) {
    params = queryParams
        .filter(queryParam => !isUndefined(args[queryParam.parameterIndex]))
        .reduce((httpParams, queryParam) => {
          const key = queryParam.key;
          const value = args[queryParam.parameterIndex];
          if (value instanceof Date) {
            httpParams = httpParams.set(key, (<Date>value).getTime().toString());
          } else if (Array.isArray(value)) {
            httpParams = httpParams.set(key, value.map((item) => item).join(','));
          } else if (isObject(value)) {
            for (const i in value) {
              if (value.hasOwnProperty(i) && !isUndefined(value[i])) {
                httpParams = httpParams.set(i, value[i]);
              }
            }
          } else if (!isEmpty(value)) {
            httpParams = httpParams.set(key, value.toString());
          } else {
            httpParams = httpParams.set(key, '');
          }
          return httpParams;
        }, params);
  }
  return params;
}


function handleHeaders(headerParams, args, defaultHeaders, methodHeaders) {
  // 设置类装饰器配置的默认请求头
  let headers = new HttpHeaders(defaultHeaders);
  // 设置方法装饰器配置的请求头
  for (const i in methodHeaders) {
    if (methodHeaders.hasOwnProperty(i)) {
      headers = headers.append(i, methodHeaders[i]);
    }
  }

  if (headerParams) {
    for (const i in headerParams) {
      if (headerParams.hasOwnProperty(i)) {
        headers = headers.append(headerParams[i].key, args[headerParams[i].parameterIndex]);
      }
    }
  }
  return headers;
}

/**
 * http请求类中方法装饰器工厂的工厂，返回不同类型工厂，再返回不同类型装饰器
 */
function methodBuilder(methodType: MethodType) {
  return function (url: string) {
    return function (target: UcHttp, propertyKey: string, descriptor: any) {
      const bodyParams = target[`${propertyKey}_${ParamType.Body}`];
      const pathParams = target[`${propertyKey}_${ParamType.Path}`];
      const queryParams = target[`${propertyKey}_${ParamType.QueryString}`];
      const headerParams = target[`${propertyKey}_${ParamType.Header}`];

      const oldDescriptor = descriptor.value;

      descriptor.value = function (...args: any[]) {
        // 为测试覆盖率调用原方法
        try {
          oldDescriptor.apply(this, args);
        } catch (e) {
        }

        const body = handleBody(bodyParams, args);
        const resourceUrl = handlePath(pathParams, args, url);
        let params = handleQueryString(queryParams, args);
        let headers = handleHeaders(headerParams, args, this.defaultHeaders, descriptor.headers);
        const baseUrl = this.baseUrl ? this.baseUrl.replace(/\/$/, '') + '/' : '';
        const requestUrl = `${baseUrl}${resourceUrl.replace(/^\//, '')}`;
        const defaultOptions = descriptor.requestOptions;

        if (defaultOptions) {
          const headerKeys = defaultOptions.headers ? defaultOptions.headers.keys() : [];
          for (let i = 0; i < headerKeys.length; i++) {
            const k = headerKeys[i];
            headers = headers.append(k, defaultOptions.headers.get(k));
          }

          const paramKeys = defaultOptions.params ? defaultOptions.params.keys() : [];
          for (let i = 0; i < paramKeys.length; i++) {
            const k = paramKeys[i];
            params = params.append(k, defaultOptions.params.get(k));
          }
        }

        const options = {
          body,
          headers,
          params,
          observe: defaultOptions ? defaultOptions.observe : 'body',
          reportProgress: defaultOptions ? defaultOptions.reportProgress : false,
          responseType: defaultOptions ? defaultOptions.responseType : 'json',
          withCredentials: defaultOptions ? defaultOptions.withCredentials : false
        };

        return this.http.request(methodType, requestUrl, options);
      };
      return descriptor;
    };
  };
}


/**
 * http请求类中方法装饰器，发起http请求
 */
export const Get = methodBuilder(MethodType.Get);

export const Jsonp = methodBuilder(MethodType.Jsonp);

export const Post = methodBuilder(MethodType.Post);

export const Put = methodBuilder(MethodType.Put);

export const Delete = methodBuilder(MethodType.Delete);

export const Head = methodBuilder(MethodType.Head);

export const Patch = methodBuilder(MethodType.Patch);

export const Options = methodBuilder(MethodType.Options);
