import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {UcProxyHttpInterceptor, UcHttpService} from './http.service';


@NgModule({
  imports: [
    HttpClientModule,
    HttpClientJsonpModule,
  ],
  providers: [
    UcHttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UcProxyHttpInterceptor,
      multi: true
    }
  ]
})
export class UcHttpModule {
}
