import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class BaseService {
  public setting = {
    i10n: {
      'themes': [{'val': 'skies', 'name': '天空'}, {'val': 'sand-signika', 'name': '纯底'}, {
        'val': 'grid',
        'name': '相框'
      }, {'val': 'grid-light', 'name': '网格'}, {'val': 'gray', 'name': '淡灰'}, {
        'val': 'dark-unica',
        'name': '深黑'
      }, {'val': 'dark-green', 'name': '深绿'}, {'val': 'dark-blue', 'name': '深紫'}, {'val': 'Qblue', 'name': '深蓝'}],
      'points': [{'val': 'circle', 'name': '圆圈'}, {'val': 'square', 'name': '方块'}, {
        'val': 'diamond',
        'name': '菱形'
      }, {'val': 'triangle', 'name': '三角形'}, {'val': 'triangle-down', 'name': '倒三角'}]
      ,
      'lineTypes': [
        {'val': 'line', 'name': '折线图'},
        {'val': 'area', 'name': '面积图'},
        {'val': 'column', 'name': '柱形图'},
        {'val': 'bar', 'name': '条形图'},
        {'val': 'scatter', 'name': '散点图'}
      ],
      'candleLineTypes': [
        {'val': 'line', 'name': '折线图'},
        {'val': 'area', 'name': '面积图'},
        {'val': 'column', 'name': '柱形图'},
        {'val': 'scatter', 'name': '散点图'}
      ],
      'dashStyle': ['Solid', 'ShortDash', 'ShortDot', 'ShortDashDot', 'ShortDashDotDot', 'Dot', 'Dash', 'LongDash', 'DashDot', 'LongDashDot', 'LongDashDotDot'],
      'colors': ['#ed808c', '#1cb1c7', '#43733e', '#f7905c', '#a04c4e', '#b486af', '#6bcbb8', '#d6b29a', '#164d88', '#7e8242', '#90c6d9', '#888689'],
      'compare': [{name: '不比较', val: null}, {name: '百分数比较', val: 'percent'}, {name: '差值比较', val: 'value'}]
    }
  };

  public symbols = [
    {symbols: 'add', translate: '＋', disable: false},
    {symbols: 'subtract', translate: '－', disable: false},
    {symbols: 'multiply', translate: '×', disable: false},
    {symbols: 'divide', translate: '÷', disable: false},
    {symbols: 'remainder', translate: '%', disable: false},
    {symbols: 'pow', translate: '^ⁿ', disable: false},
    {symbols: 'square-root', translate: '√', disable: false},
    {symbols: 'abs', translate: '||', disable: false},
    {symbols: 'ceil', translate: '⌈⌉', disable: false},
    {symbols: 'floor', translate: '⌊⌋', disable: false},
    {symbols: 'round', translate: '[x+0.5]', disable: false},
    {symbols: 'max', translate: 'max', disable: false},
    {symbols: 'min', translate: 'min', disable: false},
    {symbols: 'sin', translate: 'sin', disable: false},
    {symbols: 'cos', translate: 'cos', disable: false},
    {symbols: 'tan', translate: 'tan', disable: false},
    {symbols: 'asin', translate: 'asin', disable: false},
    {symbols: 'acos', translate: 'acos', disable: false},
    {symbols: 'atan', translate: 'atan', disable: false}
  ];

  public lineAreaParser = {
    line: 'spline',
    area: 'areaspline'
  };
  public lineAreaParser1 = {
    spline: 'line',
    areaspline: 'area'
  };
  public specialChartTypes = ['pie', 'bubble', 'candle'];

  // 时间字段
  public isT(t) {
    return /^(日期|时间|date|time|trade_time|trade_date|datetime|timestamp|create_time|TCdfeaae|TCd7e6d5|TC4ff1e7|TCd08496|TC6bee9b|TC5fc732|TC07cc69|TC19fcb9})$/.test(t);
  }

  // 标题转化为字段
  public QID(s) {
    return 'TC' + MD5(s.toString()).substr(0, 6);
  }

  // 时间格式化
  public Format(time, fmt) {
    const o = {
      'M+': time.getMonth() + 1, // 月份
      'd+': time.getDate(), // 日
      'h+': time.getHours(), // 小时
      'm+': time.getMinutes(), // 分
      's+': time.getSeconds(), // 秒
      'q+': Math.floor((time.getMonth() + 3) / 3), // 季度
      'S': time.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length)); }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
      } }
    return fmt;
  }
  // x须为时间戳格式
  public xyVal(x) {
    x = x || '';
    if (this.isNum(x)) {
      return parseFloat(x);
    }
    return this.dateParser(x.toString());
  }
  public dateParser(dateStr) {
    return +new Date(Date.parse(moment(dateStr).format('YYYY-MM-DD HH:mm:ss').replace(/-/g, '/')));
  }
  // 数字
  public isNum(v) {
    return /^-?(0\.|[1-9]{1}\d*(\.\d+)?)\d*(e\+\d+)?$/.test(v);
  }
  // 有效数字
  public isNum_(v, f = 2) {
    try {
      const dotp = v.toString().indexOf('.');
      if (~dotp && this.isNum(v) && dotp + 5 < Math.abs(v).toString().length) {
        return parseFloat(parseFloat(v).toFixed(f));
      }
    } catch (e) {
    }
    return v;
  }

  // 是否包含字段
  public isIncludeField(arr: any[], value: any) {
    return arr.includes(value);
  }

  // 返回范围
  public range(max, val, min = 0, step = 1) {
    const input = [];
    for (let i = min; i < max; i += step) {
      if (val !== 'INC') { input.push(val); } else { input.push(i); }
    }
    return input;
  }


  public en(str) {
    str = (str || '').toString();
    const character = {
      '<': '&lt;',
      '>': '&gt;',
      '&': '&amp;',
      '"': '&quot;',
      '\'': '&#039;'
    };
    return function () {
      return str.replace(/[<>&"']/g,
        function (c) {
          return character[c];
        }
      );
    }();
  }

  public filterHtml(s, whiteTags = ['var', 'p', 'div', 'span', 'img', 'a', 'strong', 'em', 'ol', 'li', 'i', 'pre', 'br', 'sub', 'sup', 'ul', 'section', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'b', 'u', 's', 'del', 'strong', 'big', 'small', 'strike', 'kbd', 'code', 'address', 'cite', 'dl', 'dd', 'dt', 'caption', 'font', 'hr', 'nobr', 'center', 'table', 'thead', 'tfoot', 'tr', 'td', 'tbody', 'th', 'blockquote']) {
    const $$ = window['jQuery'];
    const vDom = $$('<section>' + s + '</section>');
    const onEvent = /on(a(bort|fterprint)|b(eforeprint|eforeunload|lur)|c(anplay|anplaythrough|hange|lick|ontextmenu)|d(blclick|rag|ragend|ragenter|ragleave|ragover|ragstart|rop|urationchange)|e(mptied|nded|rror|rror)|f(ocus|ormchange|orminput)|h(aschange)|i(nput|nvalid)|k(eydown|eypress|eyup)|l(oad|oadeddata|oadedmetadata|oadstart)|m(essage|ousedown|ousemove|ouseout|ouseover|ouseup|ousewheel)|o(ffline|nline)|p(agehide|ageshow|ause|lay|laying|opstate|rogress)|r(atechange|eadystatechange|edo|eset|esize)|s(croll|eeked|eeking|elect|talled|torage|ubmit|uspend)|t(imeupdate)|u(ndo|nload)|v(olumechange)|w(aiting))/g;
    whiteTags = whiteTags.map(tag => tag.toLowerCase());
    vDom.find('*').each((index, node) => {
      if (whiteTags.indexOf(node.tagName.toLowerCase()) > -1) {
        $$(node.attributes).each((index, attr) => {
          if (['src', 'href'].indexOf(attr.nodeName) > -1) {
            if (!/^((https?|ftp|javascript|vbscript|jscript|ecmascript|data):\/\/|\/\/)/.test(attr.nodeValue)) {
              attr.nodeValue = '';
            }
          }
          if (onEvent.test(attr.nodeName)) {
            attr.nodeValue = '';
          }
        });
      } else {
        node.outerHTML = this.en(node.outerHTML);
      }
    });
    return vDom.html();
  }

  // 是否手机
  public isMobile() {
    return ['Android', 'SymbianOS', 'Windows Phone', 'iPod', 'iPhone'].some(function (item) {
      return navigator.userAgent.indexOf(item) > -1;
    });
  }

  // 返回getData()有效值的范围
  public dataChanged(a) {
    for (var b, c, d = 1; d < a[0].length; d++) if (b = d, '' === a[0][d] || null === a[0][d]) {
      b = d - 1;
      break;
    }
    for (var d = 1; d < a.length; d++) if (c = d, '' === a[d][0] || null === a[d][0]) {
      for (var e = !0,
             f = 1; b >= f; f++) if ('' !== a[d][f] && null !== a[d][f]) {
        e = !1;
        break;
      }
      if (e) {
        c = d - 1;
        break;
      }
    }
    return [c, b];
  }

  /**
   * 计算公式
   */
  public process(symbol: string, a: any, b?: any): void {
    const simples = {
      'add': () => {
        let res = a + b;
        if (a == null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      }, // +
      'subtract': () => {
        let res = a - b;
        if (a === null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      }, // -
      'multiply': () => {
        let res = a * b;
        if (a == null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      }, // *
      'divide': () => {
        let res = a / b;
        if (a == null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      }, // /
      'remainder': () => {
        let res = a % b;
        if (a == null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      }, // %
      'raise': () => {
        let res = 1;
        for (let i = 0; i < b; i++) {
          res = res * a;
        }

        if (a == null || a === '' || a === NaN) {
          res = null;
        }

        return result;
      },  // ^
      'square-root': () => {
        let res = Math.sqrt(a);
        if (a == null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      }, // 开根号
      'abs': () => {
        let res =  Math.abs(a);
        if (a == null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
      'ceil': () => {
        let res = Math.ceil(a);
        if (a == null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
      'floor': () => {
        let res = Math.floor(a);
        if (a === null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
      'round': () => {
        let res = Math.round(a);
        if (a == null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
      'max': () => {
        let res = Math.max(a, b);
        if (a == null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
      'min': () => {
        let res = Math.min(a, b);
        if (a == null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
      'pow': () => {
        let res = Math.pow(a, b);
        if (a === null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },

      'sin': () => {
        let res = Math.sin(a);
        if (a === null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
      'cos': () => {
        let res = Math.cos(a);
        if (a === null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
      'tan': () => {
        let res = Math.tan(a);
        if (a === null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
      'acos': () => {
        let res = Math.acos(a);
        if (a === null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
      'asin': () => {
        let res = Math.asin(a);
        if (a === null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
      'atan': () => {
        let res = Math.atan(a);
        if (a === null || a === '' || a === NaN) {
          res = null;
        }

        return res;
      },
    };

    const result = simples[symbol]();
    return result;
  }

  /**
   * JSON列转行
   */
  public col2row(data, ccol, dcol) {
    ccol = ccol || 'field';
    dcol = dcol || 'data';
    if (data.length) {
      const rowLen = data[0][dcol].length;
      const rows = [];
      for (let i = 0; i < rowLen; i++) {
        const row = {};
        for (let j = 0; j < data.length; j++) {
          row[data[j][ccol]] = data[j][dcol][i];
        }
        rows.push(row);
      }
      return rows;
    } else {
      return [];
    }
  }
  /**
   * JSON行转列
   */
  public row2col(rows, ccol, dcol) {
    ccol = ccol || 'field';
    dcol = dcol || 'data';
    const data = {};
    const tdata = [];
    rows.forEach( function (row) {
      for (const i in row) {
        if (!data[i]) {
          data[i] = [];
          const tmp = {};
          tmp[ccol] = i;
          tmp[dcol] = data[i];
          tdata.push(tmp);
        }
        data[i].push(row[i]);
      }
    });
    return tdata;
  }
}

function MD5(string) {
  function RotateLeft(lValue, iShiftBits) {
    return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
  }
  function AddUnsigned(lX, lY) {
    let lX4, lY4, lX8, lY8, lResult;
    lX8 = (lX & 0x80000000);
    lY8 = (lY & 0x80000000);
    lX4 = (lX & 0x40000000);
    lY4 = (lY & 0x40000000);
    lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
    if (lX4 & lY4) {
      return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
    }
    if (lX4 | lY4) {
      if (lResult & 0x40000000) {
        return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
      } else {
        return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
      }
    } else {
      return (lResult ^ lX8 ^ lY8);
    }
  }
  function F(x, y, z) {
    return (x & y) | ((~x) & z);
  }
  function G(x, y, z) {
    return (x & z) | (y & (~z));
  }
  function H(x, y, z) {
    return (x ^ y ^ z);
  }
  function I(x, y, z) {
    return (y ^ (x | (~z)));
  }
  function FF(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  }

  function GG(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  }

  function HH(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  }

  function II(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  }

  function ConvertToWordArray(string) {
    let lWordCount;
    const lMessageLength = string.length;
    const lNumberOfWords_temp1 = lMessageLength + 8;
    const lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
    const lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
    const lWordArray = Array(lNumberOfWords - 1);
    let lBytePosition = 0;
    let lByteCount = 0;
    while (lByteCount < lMessageLength) {
      lWordCount = (lByteCount - (lByteCount % 4)) / 4;
      lBytePosition = (lByteCount % 4) * 8;
      lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition));
      lByteCount++;
    }
    lWordCount = (lByteCount - (lByteCount % 4)) / 4;
    lBytePosition = (lByteCount % 4) * 8;
    lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
    lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
    lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
    return lWordArray;
  }

  function WordToHex(lValue) {
    let WordToHexValue = '', WordToHexValue_temp = '', lByte, lCount;
    for (lCount = 0; lCount <= 3; lCount++) {
      lByte = (lValue >>> (lCount * 8)) & 255;
      WordToHexValue_temp = '0' + lByte.toString(16);
      WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
    }
    return WordToHexValue;
  }

  function Utf8Encode(string) {
    string = string.replace(/\r\n/g, '\n');
    let utftext = '';
    for (let n = 0; n < string.length; n++) {
      const c_1 = string.charCodeAt(n);
      if (c_1 < 128) {
        utftext += String.fromCharCode(c_1);
      } else if ((c_1 > 127) && (c_1 < 2048)) {
        utftext += String.fromCharCode((c_1 >> 6) | 192);
        utftext += String.fromCharCode((c_1 & 63) | 128);
      } else {
        utftext += String.fromCharCode((c_1 >> 12) | 224);
        utftext += String.fromCharCode(((c_1 >> 6) & 63) | 128);
        utftext += String.fromCharCode((c_1 & 63) | 128);
      }
    }
    return utftext;
  }

  let x = Array();
  let k, AA, BB, CC, DD, a, b, c, d;
  const S11 = 7, S12 = 12, S13 = 17, S14 = 22;
  const S21 = 5, S22 = 9, S23 = 14, S24 = 20;
  const S31 = 4, S32 = 11, S33 = 16, S34 = 23;
  const S41 = 6, S42 = 10, S43 = 15, S44 = 21;
  string = Utf8Encode(string);
  x = ConvertToWordArray(string);
  a = 0x67452301;
  b = 0xEFCDAB89;
  c = 0x98BADCFE;
  d = 0x10325476;
  for (k = 0; k < x.length; k += 16) {
    AA = a;
    BB = b;
    CC = c;
    DD = d;
    a = FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
    d = FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
    c = FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
    b = FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
    a = FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
    d = FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
    c = FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
    b = FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
    a = FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
    d = FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
    c = FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
    b = FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
    a = FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
    d = FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
    c = FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
    b = FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
    a = GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
    d = GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
    c = GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
    b = GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
    a = GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
    d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
    c = GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
    b = GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
    a = GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
    d = GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
    c = GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
    b = GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
    a = GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
    d = GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
    c = GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
    b = GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
    a = HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
    d = HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
    c = HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
    b = HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
    a = HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
    d = HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
    c = HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
    b = HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
    a = HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
    d = HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
    c = HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
    b = HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
    a = HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
    d = HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
    c = HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
    b = HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
    a = II(a, b, c, d, x[k + 0], S41, 0xF4292244);
    d = II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
    c = II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
    b = II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
    a = II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
    d = II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
    c = II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
    b = II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
    a = II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
    d = II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
    c = II(c, d, a, b, x[k + 6], S43, 0xA3014314);
    b = II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
    a = II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
    d = II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
    c = II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
    b = II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
    a = AddUnsigned(a, AA);
    b = AddUnsigned(b, BB);
    c = AddUnsigned(c, CC);
    d = AddUnsigned(d, DD);
  }
  const temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);
  return temp.toLowerCase();
}
