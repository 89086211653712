import {Component} from '@angular/core';


@Component({
  selector: 'app-common-no-implement',
  template: `
    <div>No implementation found</div>`
})
export class NoImplementComponent {

  constructor() {
  }
}
