import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './passwordStrength.html',
  styles: [`
    .password-strength {
      line-height: 12px;
      overflow: hidden;
    }
    .strength {
      float: left;
      width: 80px;
      height: 8px;
      margin-right: 5px;
      background: #ddd;
    }
    .strength-strong {
      background: green;
    }
    .strength-medium {
      background: #e6e600;
    }
    .strength-weak {
      background: red;
    }
    .strengthText {
      float: left;
      margin-left: 15px;
    }
  `]
})
export class PasswordStrengthComponent implements OnInit, OnDestroy {
  newPswdRank: string;
  newPswdRankText: string;
  @Input() newPswd: string;
  @Output() pswdRank = new EventEmitter();

  constructor() {}

  ngOnInit() {
    // this.passwordStrength(this.newPswd);
  }

  ngOnDestroy() {}

  passwordStrength (newPswd: string) {
    const len = this.GetStrLength(newPswd); // 获取字符串长度
    // const matchResult = this.SCharCount(newPswd); // 查找 '-' '.' 特殊字符的数量
    // 条件1 判断
    const newPswdValidOne = (len >= 6 && len <= 16);
    // 条件2 判断
    const newPswdValidTwo = this.isIncludeSChar(newPswd);
    // 包含 '-' '.' 特殊字符
    // const newPswdHasSChar = newPswdValidTwo && matchResult > 0;
    // 包含字母
    const newPswdHasLiter = this.isIncludeLiter(newPswd);
    // 包含数字
    const newPswdHasNumber = this.isIncludeNumber(newPswd);
    // 条件3 判断
    // const newPswdValidThree = (newPswdHasNumber && newPswdHasLiter) || // 数字和字母
    //   (newPswdHasNumber && newPswdHasSChar) || // 数字和特殊字符
    //   (newPswdHasLiter && newPswdHasSChar) || // 字母和特殊字符
    //   (newPswdHasLiter && newPswdHasNumber && newPswdHasSChar); // 数字、字母和特殊字符
    // 新密码是否合法
    const newPswdValid = newPswdValidOne;
    // 密码等级判断
    // 3.密码中包含数字、字母和多个特殊字符时，密码强度强
    if (newPswdValid && newPswdHasNumber && newPswdHasLiter && newPswdValidTwo) {
      this.newPswdRank = 'strong';
      this.newPswdRankText = '强';
    } else if ( // 2.密码中包含数字、字母和任一特殊字符时，密码强度中；
      newPswdValid && ((newPswdHasNumber && newPswdHasLiter) || (newPswdHasNumber && newPswdValidTwo) || (newPswdValidTwo && newPswdHasLiter))) {
      this.newPswdRank = 'medium';
      this.newPswdRankText = '中';
    } else if (  // 1.密码中仅包含数字、字母时,密码强度弱;
      newPswdValid && (newPswdHasNumber || newPswdHasLiter || newPswdValidTwo)) {
      this.newPswdRank = 'weak';
      this.newPswdRankText = '弱';
    } else { // 密码不合法
      this.newPswdRank = '';
      this.newPswdRankText = '';
    }

    this.pswdRank.emit({rank: this.newPswdRank, text: this.newPswdRankText});
  }

  // 获取字符串长度：中文也可正常获取
  GetStrLength(strData) {
    if (!strData) {
      return 0;
    }
    let length = 0;
    for (let i = 0; i < strData.length; i++) {
      const char = strData.charCodeAt(i);
      // 单字节加1
      if ((char >= 0x0001 && char <= 0x007e) || (0xff60 <= char && char <= 0xff9f)) {
        length++;
      } else {
        length += 2;
      }
    }
    return length;
  }
  // 检测是否包含字母
  isIncludeLiter(strData) {
    if (!strData) {
      return false;
    }
    const reg = /[a-z]/i;
    if (!reg.test(strData)) {
      return false;
    }
    return true;
  }
  // 检测是否包含数字
  isIncludeNumber(strData) {
    if (!strData) {
      return false;
    }
    const reg = /[0-9]/;
    if (!reg.test(strData)) {
      return false;
    }
    return true;
  }
  // 检测是否包含特殊字符
  isIncludeSChar(strData) {
    if (strData === '') {
      return false;
    }
    // 全部特殊字符
    const reg = new RegExp('[`~!@#$^&*%()_+=|{}\':;\',\\-\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“\'。，、？]');
    return reg.test(strData);
  }
  SCharCount(strData) {
    let count = 0;
    const reg = new RegExp('[`~!@#$^&*%()_+=|{}\':;\',\\-\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“\'。，、？]', 'g');
    const result = strData.match(reg);

    count = !result ? 0 : result.length;
    return count;
  }
  // 统计指定特殊字符的个数：这里只统计特殊字符 “-” “.”
  calcCharCount(strData) {
    const matchResult = {
      lineCounts: 0, // '-'个数
      dotCounts: 0 // '.'个数
    };
    if (!strData) {
      return JSON.stringify(matchResult);
    }
    const lineChar = '-', dotChar = '\\.'; // 要统计的字符
    const lineRegex = new RegExp(lineChar, 'g'), dotRegex = new RegExp(dotChar, 'g'); // 使用g表示整个字符串都要匹配
    const lineResult = strData.match(lineRegex);
    matchResult.lineCounts = !lineResult ? 0 : lineResult.length;
    const dotResult = strData.match(dotRegex);
    matchResult.dotCounts = !dotResult ? 0 : dotResult.length;
    return JSON.stringify(matchResult);
  }
}
