import {Component, Input, OnInit} from '@angular/core';
import {SharedDropdownOption, SharedScope, SharedStatus, SharedTreeOption} from '../common.model';
import {UcCommonService} from '../common.service';
import {of, Subject, forkJoin} from 'rxjs';
import {map, switchMap, debounceTime} from 'rxjs/operators';
import {NzFormatEmitEvent, NzTreeNode, NzTreeNodeOptions} from 'ng-zorro-antd';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SharedEntity} from '../../system.model';


@Component({
  selector: 'app-common-share-options',
  templateUrl: './shareOptions.component.html'
})
export class ShareComponent implements OnInit {

  // 父组件的FormGroup实例
  @Input() parentFormGroup: FormGroup;
  // 模型
  @Input() bindModel: SharedEntity;

  scope = SharedScope;
  currentSharedScope: string;

  sharedUserStatus: SharedStatus;
  sharedOrgStatus: SharedStatus;
  sharedRoleStatus: SharedStatus;

  // 初始化组件内变量
  constructor(private formBuilder: FormBuilder, private commonService: UcCommonService) {
    this.sharedUserStatus = {
      sharedScope: SharedScope[SharedScope.USER],
      isLoading: false,
      options: [],
      optionsChange$: new Subject<string>(),
      isInit: false
    };
    this.sharedRoleStatus = {
      sharedScope: SharedScope[SharedScope.ROLE],
      isLoading: false,
      options: [],
      optionsChange$: new Subject<string>(),
      isInit: false

    };
    this.sharedOrgStatus = {
      sharedScope: SharedScope[SharedScope.ORG],
      isLoading: false,
      options: [],
      isInit: false
    };
  }

  private mapOptions(data: SharedDropdownOption[]): SharedDropdownOption[] {
    return data.map(x => ({title: x.title + '(' + x.value + ')', value: x.value, id: x.id}));
  }

  private mapTreeOptions(data: SharedTreeOption[]): NzTreeNode[] {
    return data.map((item) => {
      if (!item.children) {
        item.children = [];
      }
      return new NzTreeNode(item);
    });
  }

  /**
   *  初始化
   */
  private initStatus() {
    // 共享给机构默认值
    if (this.currentSharedScope === this.sharedOrgStatus.sharedScope && !this.sharedOrgStatus.isInit) {
      this.sharedOrgStatus.isLoading = true;
      const firstLevelOptions = this.commonService.getTreeOptions({parentId: null}).pipe(map(x => x.data));
      let defaultOptions = of([]);
      if (this.bindModel && this.bindModel.accessOrgPathSet && this.bindModel.accessOrgPathSet.length) {
        defaultOptions = this.commonService.getDefaultTreeOptions(this.bindModel.accessOrgPathSet).pipe(map(x => x.data));
      }
      forkJoin(firstLevelOptions, defaultOptions).subscribe(data => {
        if (data[1] && data[1].length) {
          data[1].forEach(x => {
            const index = data[0].findIndex(y => y.key === x.key);
            if (index >= 0) {
              data[0][index] = x;
            }
          });
        }
        this.sharedOrgStatus.options = this.mapTreeOptions(data[0]);
        this.sharedOrgStatus.isLoading = false;
        this.sharedOrgStatus.isInit = true;
      });
    }

    // 共享给用户默认值
    if (!this.sharedUserStatus.isInit && this.currentSharedScope === this.sharedUserStatus.sharedScope
        && this.bindModel && this.bindModel.accessUserSet && this.bindModel.accessUserSet.length) {
      this.sharedUserStatus.isLoading = true;
      this.commonService.getDefaultOptions(this.sharedUserStatus.sharedScope, this.bindModel.accessUserSet).subscribe((viewModel) => {
        if (viewModel.status === 200) {
          this.sharedUserStatus.options = this.mapOptions(viewModel.data);
          this.sharedUserStatus.isLoading = false;
          this.sharedOrgStatus.isInit = true;
        }
      });
    }

    // 共享给角色默认值
    if (!this.sharedRoleStatus.isInit && this.currentSharedScope === this.sharedRoleStatus.sharedScope
        && this.bindModel && this.bindModel.accessRoleSet && this.bindModel.accessRoleSet.length) {
      this.sharedRoleStatus.isLoading = true;
      this.commonService.getDefaultOptions(this.sharedRoleStatus.sharedScope, this.bindModel.accessRoleSet).subscribe((viewModel) => {
        if (viewModel.status === 200) {
          this.sharedRoleStatus.options = this.mapOptions(viewModel.data);
          this.sharedRoleStatus.isLoading = false;
          this.sharedOrgStatus.isInit = true;
        }
      });
    }
  }

  // Input进来的变量在这里使用
  ngOnInit() {
    this.currentSharedScope = this.bindModel && this.bindModel.sharedScope ?
        this.bindModel.sharedScope : SharedScope[SharedScope.PRIVATE];

    // 初始化用户多选框，设置节流
    this.sharedUserStatus.optionsChange$.asObservable()
        .pipe(debounceTime(500))
        .pipe(switchMap((value) => this.commonService.getOptions(this.sharedUserStatus.sharedScope, {size: 10, value: value})
            .pipe(map((viewModel) => viewModel.data))
        )).subscribe((data) => {
      this.sharedUserStatus.options = this.mapOptions(data);
      this.sharedUserStatus.isLoading = false;
    });

    // 初始化角色多选框，设置节流
    this.sharedRoleStatus.optionsChange$.asObservable().pipe(
        debounceTime(500),
        switchMap((value) => this.commonService.getOptions(this.sharedRoleStatus.sharedScope, {size: 10, value: value}).pipe(
            map((viewModel) => viewModel.data))
        )
    ).subscribe((data) => {
      this.sharedRoleStatus.options = this.mapOptions(data);
      this.sharedRoleStatus.isLoading = false;
    });

    this.initStatus();

    this.parentFormGroup.addControl('shared',
        this.formBuilder.control(this.bindModel && this.bindModel.shared ? this.bindModel.shared : false, [Validators.required]));

    this.parentFormGroup.addControl('sharedScope', this.formBuilder.control(this.currentSharedScope, [Validators.required]));

    this.parentFormGroup.addControl(
        'accessUserSet',
        this.formBuilder.control(this.bindModel && this.bindModel.accessUserSet ? this.bindModel.accessUserSet : []));

    this.parentFormGroup.addControl(
        'accessRoleSet',
        this.formBuilder.control(this.bindModel && this.bindModel.accessRoleSet ? this.bindModel.accessRoleSet : []));

    this.parentFormGroup.addControl(
        'accessOrgPathSet',
        this.formBuilder.control(this.bindModel && this.bindModel.accessOrgPathSet ? this.bindModel.accessOrgPathSet : []));

  }

  selectSharedScope() {
    this.currentSharedScope = this.parentFormGroup.get('sharedScope').value;
    if (this.currentSharedScope !== SharedScope[SharedScope.PRIVATE]) {
      this.parentFormGroup.patchValue({shared: true});
    }
    this.initStatus();
  }

  onUserSearch(value: string) {
    if (value) {
      this.sharedUserStatus.isLoading = true;
      this.sharedUserStatus.optionsChange$.next(value);
    }
  }

  onRoleSearch(value: string) {
    if (value) {
      this.sharedRoleStatus.isLoading = true;
      this.sharedRoleStatus.optionsChange$.next(value);
    }
  }

  onOrgExpandChange(e: NzFormatEmitEvent) {
    if (e.node.getChildren().length === 0 && e.node.isExpanded) {
      const paths = e.node.key.split('/');
      const parentId = paths[paths.length - 1];
      this.commonService.getTreeOptions({parentId: parentId})
          .pipe(map((viewModel) => viewModel.data))
          .subscribe(data => {
            e.node.addChildren(this.mapTreeOptions(data));
          });
    }
  }
}
