import {Injectable} from '@angular/core';
import {mode, pad, enc, AES} from 'crypto-js';

@Injectable()
export class CryptoJSService {
  KEY: string;
  IV: string;
  constructor() {
    this.KEY = 'huidi+2019123456';
    this.IV = 'huidi+2019123456';
  }

  // 加密
  encryptByEnAES(data: string, key: string, iv: string): string {
    const KEY = enc.Utf8.parse(key);
    const IV = enc.Utf8.parse(iv);
    const tmpAES = AES.encrypt(data, KEY, {
      iv: IV,
      mode: mode.CBC,
      padding: pad.Pkcs7
    });
    return tmpAES.toString();
  }

  encryptByEnAESn(data: string): string {
    const key = this.KEY;
    const iv = this.IV;
    const KEY = enc.Utf8.parse(key);
    const IV = enc.Utf8.parse(iv);
    const tmpAES = AES.encrypt(data, KEY, {
      iv: IV,
      mode: mode.CBC,
      padding: pad.Pkcs7
    });
    return tmpAES.toString();
  }

  // 解密
  encryptByDeAES(data: string, key: string, iv: string): string {
    const KEY = enc.Utf8.parse(key);
    const IV = enc.Utf8.parse(iv);
    const tmpDeAES = AES.decrypt(data, KEY, {
      iv: IV,
      mode: mode.CBC,
      padding: pad.Pkcs7
    });
    return tmpDeAES.toString(enc.Utf8);
  }

  encryptByDeAESn(data: string): string {
    const key = this.KEY;
    const iv = this.IV;
    const KEY = enc.Utf8.parse(key);
    const IV = enc.Utf8.parse(iv);
    const tmpDeAES = AES.decrypt(data, KEY, {
      iv: IV,
      mode: mode.CBC,
      padding: pad.Pkcs7
    });
    return tmpDeAES.toString(enc.Utf8);
  }
}
