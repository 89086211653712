import {EventEmitter, Injectable, KeyValueDiffers} from '@angular/core';
import {UcStorageType} from '../cache';
import {UcCacheService} from '../cache';


/**
 * 状态服务
 */
@Injectable()
export class UcStateService {

  private static STATE_CACHE_POOL_KEY = '__stateCachePool__';
  private storageType: UcStorageType = UcStorageType.Memory;
  private subjects: Map<string, EventEmitter<any>> = new Map<string, EventEmitter<any>>();

  constructor(private cacheService: UcCacheService, private differs: KeyValueDiffers) {
  }

  private getValue<T>(key: string) {
    return this.cacheService.get<T>({
      pool: UcStateService.STATE_CACHE_POOL_KEY,
      key: key,
      storageType: this.storageType
    });
  }

  private setValue(key: string, value: any) {
    return this.cacheService.set({
      pool: UcStateService.STATE_CACHE_POOL_KEY,
      key: key,
      storageType: this.storageType
    }, value);
  }

  private removeValue(key: string) {
    return this.cacheService.remove({
      pool: UcStateService.STATE_CACHE_POOL_KEY,
      key: key,
      storageType: this.storageType
    });
  }

  // TODO: 使用KeyValueDiffers递归跟踪每个属性变化
  private isObjectValueChanged(value: Object, newValue: Object) {
    return !(JSON.stringify(value) === JSON.stringify(newValue));
  }

  getSubject(key: string): EventEmitter<any> {
    if (this.subjects.has(key)) {
      return this.subjects.get(key);
    } else {
      const subject = new EventEmitter<any>();
      this.subjects.set(key, subject);
      return subject;
    }
  }

  subscribe(key: string, next: (value: any) => void, error?: (error: any) => void, complete?: () => void) {
    return this.getSubject(key).subscribe((value: any) => {
      try {
        if (next) {
          next(value);
        }
      } catch (e) {
        console.log(e.message);
      }
    }, error, complete);
  }

  /**
   * set值到缓存中，并广播更新后的值，part为true更新Object的部分属性
   */
  set(key: string, value: any, part?: boolean): void {
    const cached = this.getValue(key) || {};
    if (value) {
      let change = false;
      let newValue: any;
      if (value instanceof Object) {
        if (part) {
          newValue = Object.assign({}, cached, value);
        } else {
          newValue = value;
        }
        change = this.isObjectValueChanged(cached, newValue);
      } else {
        newValue = value;
        change = !(cached === newValue);
      }
      if (change) {
        this.setValue(key, newValue);
        this.getSubject(key).emit(newValue);
      }
    }
  }

  get<T>(key: string): any {
    return this.getValue<T>(key);
  }

  remove(key: string) {
    this.removeValue(key);
    this.subjects.delete(key);
  }
}
